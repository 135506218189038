.chatbot {
  height: auto !important;

  .chatbot-arrow {
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 99;


    .mini-closed-notification {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: var(--error-red);
      color: var(--text-active-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      top: -4px;
      right: -4px;
    }

    .mini-notification-container {
      max-width: 400px;
      width: 300px;
      position: absolute;
      // display: flex;
      justify-content: center;
      align-items: center;
      bottom: 70px;
      right: 0px;
      gap: 10px;

      .all-notification-stack {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .mini-notification {
          // border: 1px solid var(--border-color);
          box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.05);
          background-color: var(--text-active-color);
          padding: 14px 20px;
          border-radius: 6px;
          text-align: left;
          font-size: 13px;
          color: var(--text-primary-color);
        }

      }

      .close {
        background-color: var(--text-primary-color);
        box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        position: absolute;
        top: -35px;
        right: 0;

        img {
          width: 16px;
        }
      }

    }
  }



  .chatbot-main-container {
    overflow-x: hidden;
    overflow-y: auto;
    cursor: default;
    position: fixed;
    bottom: 115px;
    right: 20px;
    z-index: 99;
    background: #ffffff;
    height: 74vh;
    max-height: 1000px;
    // min-height: ;
    // width: 30vw;
    max-width: 515px;
    width: 31%;
    min-width: 422px;
    border-radius: 10px;
    // box-shadow: 0px 5px 15px 25px rgba(0, 0, 0, 0.02);
    box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.1);

    .chatbot-header {
      text-align: left;
      background-image: url("../assests/images/chatbot-header.svg");
      padding: 10px 20px 20px 20px;
      border-radius: 6px 6px 0px 0px;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: cover;

      p {
        margin: 0px;
        font-weight: 500;
        font-size: 22px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.75);
      }

      .hello-wave {
        padding-top: 40px;

        img {
          padding-bottom: 8px;
        }
      }

      p.bolder-text {
        color: var(--text-active-color);
      }

      .chatbot-info {
        padding: 20px 15px 20px 20px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 20px;
        border: 2px solid #eef2f6;
        border-radius: 10px;

        .send-msg-head{
          padding-bottom: 5px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          color: var(--text-primary-color);
          margin: 0px;
        }

        p.helper-text {
          font-weight: 400;
          font-size: 12px;
          margin: 0px;
          white-space: nowrap;
          color: var(--text-tertiary-color);
        }
      }
    }

    .chatbot-body {
      border-radius: 6px;
      text-align: left;
      //   padding: 15px 14px;
      padding: 20px;
      iframe{
        width: 400ox;
        height: 200px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        margin: 0px;

        color: var(--text-primary-color);
      }

      p.helper-text {
        font-weight: 400;
        font-size: 14px;
        margin: 0px;

        color: #9ca0a9;
      }

      .chatbot-blocks {
        padding: 12px 6px;

        .chatbot-block {
          padding: 16px 10px;
          margin: 6px;
          border: 1px solid var(--border-color);
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 10px;

          .heading{
            padding-bottom: 0;
          }

          p {
            font-weight: normal;
            margin-top: 0;
            font-size: 16px;
            color: var(--text-primary-color);
          }

          p.helper-text {
            font-weight: 400;
            font-size: 12px;
            margin-top: 4px;
            color: #9ca0a9;
          }
        }
      }

      .chat-filters {
        margin: 20px 0px;
        display: flex;
        gap: 10px;

        button {
          // margin: 0px 4px;

          padding: 9px 27px;

          background: var(--background-color);
          border: 1px solid var(--border-color);
          border-radius: 10px;
          font-weight: 400;
          font-size: 14px;

          color: var(--text-primary-color);
        }

        button.active {
          border: 1px solid var(--active-blue-color) !important;
          background-color: var(--brand-light-color);
        }
      }

      .recent-messages {
        border: 1px solid var(--border-color);
        padding: 0px 8px;
        border-radius: 10px;

        .message {
          padding: 25px 10px;
          background-color: var(--text-active-color);
          display: flex;
          gap: 15px;
          align-items: center;

          img.who {
            width: 50px;
          }

          .intials {
            width: 49px;
            height: 42px;
            background: var(--active-blue-color);
            border-radius: 50%;
            // margin: 7px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            // padding: 10px;
            color: var(--text-active-color);
          }

          .count {
            width: 20px;
            height: 20px;
            background-color: var(--error-red);
            color: var(--text-active-color);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            top: -4px;
            right: -4px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            color: var(--text-primary-color);
            margin: 0px;
            white-space: break-spaces;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;

          }

          p.read-text {
            color: #5E5E5E;
            font-weight: 400;
          }

          p.last-message {
            padding-bottom: 2px;
          }

          p.helper-text {
            font-weight: 400;
            font-size: 12px;
            margin: 0px;

            color: var(--text-tertiary-color);
          }
        }

        border-radius: 10px;

        .message:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }

        .no-message {
          padding: 30px 50px;
          background-color: var(--text-active-color);
          // display: flex;
          // gap: 10px;
          // flex-direction: column;
          // align-items: center;

          img {
            width: 100%;
          }

          div {
            // padding: 0px 0px 0px 20px;
            margin-top: 20px;
            text-align: center;

            p {
              font-weight: 500;
              font-size: 16px;
              color: var(--text-primary-color);
            }

            p.helper-text {
              font-weight: 400;
              margin-top: 7px !important;
              font-size: 12px;

              color: var(--text-tertiary-color);
            }
          }
        }

        .load-more {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 5px;

          p {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: var(--active-blue-color);
          }
        }
      }
    }
  }
}

.new-chatbot {
  height: auto !important;
  display: -webkit-inline-box;

  .chatbot-arrow {
    cursor: pointer;
    // position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 99;


    .mini-notification-main-container {
      position: relative;
      .mini-closed-notification {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: var(--error-red);
        color: var(--text-active-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6px;
        top: -24px;
        right: -1px;;
        border: 1px solid #FFFFFF;
      }
    }

    .mini-notification-container {
      max-width: 400px;
      width: 300px;
      position: absolute;
      // display: flex;
      justify-content: center;
      align-items: center;
      bottom: 60px;
      right: 20px;
      gap: 10px;

      .all-notification-stack {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .mini-notification {
          // border: 1px solid var(--border-color);
          box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.05);
          background-color: var(--text-active-color);
          padding: 14px 20px;
          border-radius: 6px;
          text-align: left;
          font-size: 13px;
          color: var(--text-primary-color);
        }

      }

      .close {
        background-color: var(--text-primary-color);
        box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        position: absolute;
        top: -35px;
        right: 0;
        height: 24px;
        width: 24px;

        img {
          width: 24px;
          &:hover {
            background: initial !important;
          }
        }
      }
    }
  }



  .chatbot-main-container {
    overflow-x: hidden;
    overflow-y: auto;
    cursor: default;
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 99;
    background: #ffffff;
    height: 74vh;
    max-height: 1000px;
    // min-height: ;
    // width: 30vw;
    max-width: 515px;
    width: 31%;
    min-width: 422px;
    border-radius: 10px;
    // box-shadow: 0px 5px 15px 25px rgba(0, 0, 0, 0.02);
    box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.1);

    .chatbot-header {
      text-align: left;
      background-image: url("../assests/images/chatbot-header.svg");
      padding: 10px 20px 20px 20px;
      border-radius: 6px 6px 0px 0px;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: cover;

      p {
        margin: 0px;
        font-weight: 500;
        font-size: 22px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.75);
      }

      .hello-wave {
        padding-top: 40px;

        img {
          padding-bottom: 8px;
        }
      }

      p.bolder-text {
        color: var(--text-active-color);
      }

      .chatbot-info {
        padding: 20px 15px 20px 20px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 20px;
        border: 2px solid #eef2f6;
        border-radius: 10px;

        .send-msg-head{
          padding-bottom: 5px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          color: var(--text-primary-color);
          margin: 0px;
        }

        p.helper-text {
          font-weight: 400;
          font-size: 12px;
          margin: 0px;
          white-space: nowrap;
          color: var(--text-tertiary-color);
        }
      }
    }

    .chatbot-body {
      border-radius: 6px;
      text-align: left;
      //   padding: 15px 14px;
      padding: 20px;
      iframe{
        width: 400ox;
        height: 200px;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        margin: 0px;

        color: var(--text-primary-color);
      }

      p.helper-text {
        font-weight: 400;
        font-size: 14px;
        margin: 0px;

        color: #9ca0a9;
      }

      .chatbot-blocks {
        padding: 12px 6px;

        .chatbot-block {
          padding: 16px 10px;
          gap: 10px;
          display: flex;
          align-items: center;
          margin: 6px;
          border: 1px solid var(--border-color);
          border-radius: 10px;

          .heading{
            padding-bottom: 0;
          }

          p {
            font-weight: normal;
            margin-top: 0;
            font-size: 16px;
            color: var(--text-primary-color);
          }

          p.helper-text {
            font-weight: 400;
            font-size: 12px;
            margin-top: 4px;
            color: #9ca0a9;
          }
        }
      }

      .chat-filters {
        margin: 20px 0px;
        display: flex;
        gap: 10px;

        button {
          // margin: 0px 4px;

          padding: 9px 27px;

          background: var(--background-color);
          border: 1px solid var(--border-color);
          border-radius: 10px;
          font-weight: 400;
          font-size: 14px !important;

          color: var(--text-primary-color);
        }

        button.active {
          border: 1px solid var(--active-blue-color) !important;
          background-color: var(--brand-light-color);
        }
      }

      .recent-messages {
        border: 1px solid var(--border-color);
        padding: 0px 8px;
        border-radius: 10px;

        .message {
          padding: 25px 10px;
          background-color: var(--text-active-color);
          display: flex;
          gap: 15px;
          align-items: center;

          img.who {
            width: 50px;
          }

          .intials {
            width: 49px;
            height: 42px;
            background: var(--active-blue-color);
            border-radius: 50%;
            // margin: 7px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            // padding: 10px;
            color: var(--text-active-color);
          }

          .count {
            width: 20px;
            height: 20px;
            background-color: var(--error-red);
            color: var(--text-active-color);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            top: -4px;
            right: -4px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            color: var(--text-primary-color);
            margin: 0px;
            white-space: break-spaces;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;

          }

          p.read-text {
            color: #5E5E5E;
            font-weight: 400;
          }

          p.last-message {
            padding-bottom: 2px;
          }

          p.helper-text {
            font-weight: 400;
            font-size: 12px;
            margin: 0px;

            color: var(--text-tertiary-color);
          }
        }

        border-radius: 10px;

        .message:not(:last-child) {
          border-bottom: 1px solid var(--border-color);
        }

        .no-message {
          padding: 30px 50px;
          background-color: var(--text-active-color);
          // display: flex;
          // gap: 10px;
          // flex-direction: column;
          // align-items: center;

          img {
            width: 100%;
          }

          div {
            // padding: 0px 0px 0px 20px;
            margin-top: 20px;
            text-align: center;

            p {
              font-weight: 500;
              font-size: 16px;
              color: var(--text-primary-color);
            }

            p.helper-text {
              font-weight: 400;
              margin-top: 7px !important;
              font-size: 12px;

              color: var(--text-tertiary-color);
            }
          }
        }

        .load-more {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 5px;

          p {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: var(--active-blue-color);
          }
        }
      }
    }
  }
}

.scroll-chatbot::-webkit-scrollbar {
  display: none;
}
.chatscreen-body::-webkit-scrollbar {
  display: unset;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chatbot-main-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  // scrollbar-width: none;
  /* Firefox */
}

//   Lazy-Loader
.chatbot-lazy-container {
  padding: 50px 20px 0px;

  .title-1 {
    div:first-of-type {
      order: 1;
      width: 60% !important;
    }
  }

  .title-2 {
    div:first-of-type {
      order: 1;
      width: 80% !important;
    }
  }

  .two-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .shimmer-thumbnail {
      min-width: 150px;
      height: 120px !important;
    }

    >div:first-child {
      width: 48% !important;
    }

    >div:last-child {
      width: 48% !important;
    }
  }

  .btn-box {
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    .shimmer-thumbnail {
      margin-bottom: 15px;
    }
  }

  .last-box {
    margin-bottom: 0px;
  }
}

.recent-msg-list-lazy-container {
  padding: 20px;

  .load-more-lazy {
    width: 100%;
    margin-top: 15px;

    .last-box {
      margin: auto;
      display: block;
      border-radius: 6px;
    }
  }

  .recent-msg-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .msg-list {
      display: flex;
      gap: 10px;
      width: 100%;

      .text {
        width: 60%;
      }
    }

    .right-box {
      // height: 40px;
      min-width: 10px;
    }

  }

}

.chatscreen-body-lazy-container {
  width: 100%;

  .line {
    .shimmer-thumbnail {
      width: 50%;
      position: relative;
      top: -13px;
      border-radius: 6px;
    }
  }
  .bot-message-container{
    align-items: baseline !important;
    margin-top: 0px !important;
  }

  .user-msg-lazy {
    width: 100%;
    text-align: right;
  }

}

// ChatScreen

.chatscreen-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chatscreen-header {
    text-align: left;
    background-image: url("../assests/images/chatbot-header.svg");
    padding: 10px 20px 20px 20px;
    border-radius: 6px 6px 0px 0px;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: sticky;
    // top: 0px;
    // z-index: 99999;

    .back-arrow-icon {
      padding: 20px 5px 25px;
    }

    .the-bot {
      background-color: var(--text-active-color);
      border-radius: 50%;
      padding: 6px;
      margin-bottom: 20px;
    }

    .bolder-text {
      font-weight: 500;
      font-size: 26px;
      color: var(--text-active-color);
      margin-bottom: 0px;

      span {
        color: var(--active-blue-color);
      }
    }

    .secondary-text {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.75);
    }

    .tertiary-text {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .chatscreen-header-connection-states {
    position: sticky;
    top: 0px;
    z-index: 99;

    .chatscreen-header-small {
      text-align: left;
      background-image: url("../assests/images/chatbot-header.svg");
      padding: 10px 20px;
      border-radius: 6px 6px 0px 0px;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      gap: 15px;

      .head-online-status {
        display: flex;
        flex-direction: column;

        .bolder-text {
          font-weight: 500;
          font-size: 14px;
          color: var(--text-active-color);
          margin-bottom: 0px;
        }

        .tertiary-text {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 0px;
          white-space: nowrap;

          .dot {
            padding: 0px 4px;
          }
        }
      }

      .back-arrow-icon {
        padding: 0px 5px;
      }

      .bot-icon-active-status {
        position: relative;

        .the-bot {
          display: block;
          width: 36px;

          img {
            background-color: var(--text-active-color);
            border-radius: 50%;
            padding: 6px;
            margin-bottom: 0px;
          }
        }

        .status-indicator {
          border: 1px solid #060515;
          border-radius: 50%;
          display: inline-flex;
          width: 10px;
          height: 10px;
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }

    .connection-states {
      text-align: center;

      p {
        color: var(--text-active-color);
        margin: 0px;
        font-size: 12px;
        padding: 10px 0px;
        display: flex;
        align-items: baseline;
        justify-content: center;

        img {
          padding: 0px 5px;
          width: 25px;
          height: 12px;
        }

        span {
          font-weight: 500;
          padding-left: 2px;
        }
      }
    }
  }

  .msg-send-container {
    position: sticky;
    background-color: var(--text-active-color);
    bottom: -1px;
    border-top: 1px solid var(--border-color);

    .msg-send {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      cursor: pointer;
      gap: 15px;
      margin: 14px 0px 14px;
      position: sticky;
      bottom: 0px;
      min-height: 36px;

      .for-scrollbar{
      background-color: var(--background-color);
      width: 100%;
      padding: 4px 5px;
      border-radius: 6px;
      outline: 1px solid transparent !important;
      &:focus-within {
        outline: 1px solid var(--border-color) !important;
      }

      &:focus {
        outline: 1px solid var(--border-color) !important;
      }



      .msg {
        background-color: var(--background-color);
        padding:6px 8px;
        border-radius: 6px;
        // padding-left: 15px;
        width: 100%;
        display: flex;
        align-items: center;

        &:empty::before{
          content:attr(data-placeholder);
          color:var(--text-tertiary-color);

        }

        &:focus-visible {
          outline: 1px solid transparent !important;
        }

        &:focus {
          outline: 1px solid transparent !important;
        }


        // p {
        color: var(--text-primary-color);
        font-size: 12px;
        text-align: left;
        // margin-bottom: 0px;
        max-height: 100px;
        min-height: 32px;
        overflow-y: auto;

        white-space: pre-wrap;
        word-break: break-word;



        &::-webkit-scrollbar-thumb {
          border-radius: 3.5px;
          background: var(--border-color) !important;
        }

        &::-webkit-scrollbar-track {
          border-radius: 6px;
          background: var(--background-color) !important;
        }

        // }
      }
    }

    }
  }


  .chat-border-container{
    .msg-send{
      padding-left: 0px;
      padding-right: 0px;

      .chat-border {
        background-color: var(--border-color);
        height: 1px;
        width: 100%;
        margin: auto;
        margin-top: 10px;

        span {
          color: var(--text-primary-color);
          font-size: 12px;
          border-radius: 6px;
          background-color: var(--text-active-color);
          padding: 0px 3px;
          position: relative;
          font-size: 12px;
          top: -13px;
          border-radius: 6px;
          display: inline-block;
        }
      }
    }


  }




.chatscreen-body-container{
  overflow-y: auto;
  margin-right: 4px;

  &::-webkit-scrollbar-thumb {
    border-radius: 3.5px;
    background: var(--border-color) !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: var(--text-active-color) !important;
  }



}

  .chatscreen-body {
    // height: 100%;
    padding: 0px 0px 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;

    // .chat-border {
    //   background-color: var(--border-color);
    //   height: 1px;
    //   width: 100%;
    //   margin: 40px auto 20px;

    //   span {
    //     color: var(--text-primary-color);
    //     font-size: 12px;
    //     border-radius: 6px;
    //     background-color: var(--text-active-color);
    //     padding: 0px 3px;
    //     position: relative;
    //     font-size: 12px;
    //     top: -13px;
    //     border-radius: 6px;
    //     display: inline-block;
    //   }
    // }

    .options {
      padding: 0px 20px 0px 60px;
      margin-top: 20px;
      // margin-bottom: 30px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      flex-wrap: wrap;

      span {
        color: var(--text-primary-color);
        font-size: 12px;
        background-color: rgba(52, 43, 194, 0.05);
        padding: 15px 20px;
        border-radius: 10px;
        border: 1px solid var(--brand-color);
      }
    }

    .line {

      background-color: var(--border-color);
      height: 1px;
      width: 100%;
      margin: 50px auto 50px;

      span {
        position: relative;
        color: var(--text-secondary-color);
        font-size: 12px;
        top: -13px;
        border-radius: 6px;
        background-color: var(--background-color);
        padding: 8px 30px;
      }
    }

    .user-message-container {
      margin-left: auto;
      margin-top: 20px;
      max-width: 75%;
      padding: 0px 20px;

      div{
        background-color: var(--brand-color);
        border-radius: 10px;
        color: var(--text-active-color);
        font-size: 12px;
        padding: 15px 20px;
        word-wrap: break-word;
        user-select: text !important;
        text-align: left;

      }
      // display: inline;
      // text-align: left;

    }

    .bot-message-container{
      padding: 0px 20px;
      display: flex;
      align-items: flex-end;
      gap: 10px;
      margin-top: 20px;
      width: 100%;

      .align-message-bot{
        padding-bottom: 20px;
      }

      .bot-chating {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        // max-width: 70%;

        .loader-size {
          text-align: left;

          img {
            width: 20%;
            padding-bottom: 8px;
          }

        }

        .bot-messege-with-link {
          display: inline;
          text-align: left;
          background-color: var(--background-color);
          border-radius: 10px;
          color: var(--text-primary-color);
          font-size: 12px;

          max-width: 80%;

          .illustration {
            position: relative;

            img {
              border-radius: 10px 10px 0px 0px;
            }

            span.link {
              position: absolute;
              right: 8px;
              top: 8px;

              img {
                border-radius: 0;
              }
            }

          }

          .content {
            padding: 0px 15px 10px;

            h3 {
              font-size: 14px;
              color: var(--text-primary-color);
              white-space: nowrap;
              word-wrap: break-word;
              margin: 0;
              padding: 12px 0px 4px;

              span {
                padding: 0px 2px;
              }
            }

            p {
              font-size: 12px;
              margin: 0;
              color: var(--text-tertiary-color);
              word-wrap: break-word;
            }

          }

        }

        .bot-message {
          // display: inline;
          max-width: 80%;
          .bot-msg{
            text-align: left;
            user-select: text !important;
            background-color: var(--background-color);
            border-radius: 10px;
            color: var(--text-primary-color);
            font-size: 12px;
            padding: 15px 20px;
            word-wrap: break-word;
            white-space: break-spaces;
          }
          div{
            text-align: left;
            background-color: var(--background-color);
            border-radius: 10px;
            color: var(--text-primary-color);
            font-size: 12px;
            word-wrap: break-word;
            white-space: break-spaces;
          p{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
          }
          }

        }

        .bot-response-time {
          color: var(--text-tertiary-color);
          font-size: 10px;

          .dot {
            padding: 0px 4px 0px 3px;
          }
        }
      }

      .the-bot {
        background-color: rgba(52, 43, 194, 0.05);
        border-radius: 50%;
        padding: 5px;
        width: 30px;
        height: 30px;
      }
    }
  }
}

// chatbot-api-error
.chatbot-error-block-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .chatbot-error-block {
    text-align: center;
    width: 90%;
    margin: auto;

    img {
      padding: 40px 10px;
      width: 70%;
    }

    h3 {
      margin: 0px;
      font-size: 18px;
      color: var(--text-primary-color);
    }

    p {
      margin: 0px;
      color: var(--text-tertiary-color);
      font-size: 12px;
      padding: 15px 0px 25px;
    }
  }
}

.feedback {
  position: sticky;
  bottom: 0;
  padding: 25px 50px;
  animation: feedback 1s;
  // animation: feedback 1.2s ease-out forwards;
  background: #ffffff;
  z-index: 1000;
  border-radius: 10px;
  box-shadow: 0px 5px 15px 25px rgba(0, 0, 0, 0.02);

  .feedback-load {
    margin-top: -32px;
    width: 100px;
  }

  .feedback-sucess{
    img.tick{
      margin-bottom: 15px;
    }
  }

  p.heading {
    font-weight: 500;
    font-size: 20px;
    opacity: 0.75;
    margin-bottom: 7px;

    color: var(--text-primary-color);
  }

  p.helper-text {
    font-weight: 400;
    font-size: 14px;

    color: var(--text-tertiary-color);
    margin-bottom: 4px;

  }

  p.emoji-text {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 5px;

  }

  img.cross-icon {
    position: absolute;
    right: 10px;
    top: -35px
  }

  img.emojis {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .emoji-section .emoji-icons-div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 35px;
  }

  img.inactive {
    width: 20px !important;
    height: 20px I !important;

  }

  img.active {
    border: 1px solid var(--active-blue-color);
    border-radius: 50%;
    background-color: var(--active-blue-color);
  }

  // img.emojis:not(:hover) {
  //   width: 20px;
  // }
  // img.emojis:hover {
  //   border: 1px solid var(--active-blue-color);
  //   border-radius: 50%;
  //   background-color: var(--active-blue-color);
  // }
  button.submit-feedback {
    border-radius: 10px !important;
    font-weight: 400;
    font-size: 14px;

    color: var(--text-active-color);
  }

  button.submit-feedback:active {
    background: #282196 !important;
  }

  button.submit-feedback:disabled {
    opacity: 0.5;
  }

}

.overlay {
  width: 100%;
  opacity: 0.75;
  // animation: 1.2s;
  padding: 2000px;
  bottom: 201px;
  position: sticky;

  // position: absolute;
  background: #404040;
  z-index: 100;
}

// @keyframes feedback {
//   from {
//     bottom: -300px;
//     opacity: 0;
//   }

//   to {
//     bottom: 0;
//     opacity: 1;
//   }
// }
@keyframes feedback {
  0% {

    transform: translateY(100%);
    visibility: visible;
  }

  100% {
    transform: translateY(0%);
    visibility: visible;
  }

}
.create-new-container {
  padding: 0px 70px 40px 70px;

  .create-new {
    cursor: pointer;
    background-color: var(--brand-color);
    border-radius: 10px;
    color: var(--text-active-color);
    font-size: 14px;
    font-weight: 400;
    padding: 15px 20px;
    word-wrap: break-word;
    -webkit-user-select: text !important;
    user-select: text !important;
    text-align: center;

    img {
      padding-bottom: 2px;
    }
  }
}