@media screen and (max-height: 600px) {
  .upgrade-modal .upgrade-body .content-upgrade {
    padding: 30px 40px;
  }
}

@media screen and (max-height: 700px) {
  .chatbot .chatbot-main-container .chatbot-body .recent-messages .message .intials {
    padding: 16px;
  }

  .chatbot .chatbot-main-container .chatbot-body .recent-messages .message p.helper-text {
    font-size: 10px;
  }

  .chatbot .chatbot-main-container .chatbot-body .recent-messages .message p {
    font-size: 12px;
  }

  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block {
    padding: 16px 10px;
  }

  .new-chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block {
    padding: 16px 10px;
  }

  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p.helper-text {
    font-size: 10px;
    line-height: 1.4;
  }

  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 14px;
  }

  .chatbot .chatbot-main-container .chatbot-body p.helper-text {
    font-size: 12px;
    margin-top: 0px !important;
  }

  .chatbot .chatbot-main-container .chatbot-body p {
    font-size: 16px;
  }

  .chatbot .chatbot-main-container .chatbot-body {
    padding: 16px 20px;
  }

  .chatbot .chatbot-main-container .chatbot-header .chatbot-info p.helper-text {
    font-size: 10px;
  }

  .chatbot .chatbot-main-container .chatbot-header .chatbot-info p {
    font-size: 14px;
    // padding-bottom: 2px;
  }

  .chatbot-info .send-image {
    width: 10%;
  }

  .chatbot-info .send-image-right-arrow {
    width: 7%;
  }

  .message .send-image-right-arrow {
    width: 9%;
  }

  .chatbot .chatbot-main-container .chatbot-header .chatbot-info {
    padding: 15px 10px 15px 15px;
    gap: 16px;
  }

  .chatbot .chatbot-main-container .chatbot-header p {
    font-size: 20px;
  }

  .sidebar .layout-sidebar.overflow-block {
    text-align: left;
    max-width: 270px;
    flex: 0 0 270px;
    order: -1;
    height: 100%;
    position: fixed;
    overflow-y: overlay !important;
    background: #fbfbfb !important;
    scrollbar-gutter: stable;
    padding: 10px 20px 10px 20px !important;
    border-right: 1px solid #e6e7e9;

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      &::-webkit-scrollbar {
        display: block;
      }
    }
  }

  .bottom-footer {
    position: static !important;
  }

  .feedback {
    padding: 30px;

    p.heading {
      font-size: 16px;
    }
  }

  .feedback p.helper-text {
    font-size: 12px;
  }

  .feedback-text {
    margin-bottom: 16px;
  }

  .emoji-section .emoji-icons-div {
    margin-bottom: 12px;
  }

  .payment-wrapper .review-wrapper {
    position: static !important;
    height: fit-content;
  }

  .invitees-block {
    height: 115px !important;
  }

  .illustration-image-invite {
    margin-bottom: 10px !important;
  }

  .empty-comment-state-container {
    margin-top: 25% !important;
  }

  .chatbot .chatbot-main-container {
    width: 35% !important;
  }

  .new-chatbot .chatbot-main-container {
    width: 35% !important;
  }
}

@media screen and (max-height: 800px) {
  .chatbot .chatbot-main-container {
    width: 34% !important;
  }

  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 15px;
  }

  .new-chatbot .chatbot-main-container {
    width: 34% !important;
  }

  .new-chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1281px) {

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .sidebar-wrapper .tools-tabs.main-tabs {
    padding-bottom: 16.5px !important;
  }

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list span,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list span {
    width: 42% !important;
    padding-right: 30px !important;
    font-size: 12px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing .blue-tick img {
    width: 12px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro {
    margin-bottom: 24px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans {
    padding-bottom: 20px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .amount h5 {
    font-size: 19px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul img {
    width: 27px !important;
    height: 27px !important;
  }

  .upgrade-wrapper .upgrade-blocks .info .billing .cycle-switch .period-billing {
    padding-right: 30px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .skip {
    margin: 15px auto 0;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 0px !important;
    font-size: 17px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 0px !important;
    margin-bottom: 0rem !important;
    font-size: 11px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans h4 {
    font-size: 11px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 11px !important;
    margin-bottom: 10px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 10px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing {
    padding: 10px 66px 0px 20px !important;
  }

  .project-limit-tag-upgrade-popup,
  .pro-tag-upgrade-popup,
  .screen-limit-tag-upgrade-popup,
  .exports-limit-tag-upgrade-popup {
    font-size: 10px !important;
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 220px !important;
        overflow: auto;
      }
    }
  }

  .status-wrapper .graphics-text {
    img {
      width: 121px;
    }

    h2 {
      font-size: 1.2em !important;
    }
  }

  .payment-wrapper {
    .payment-header {
      padding: 3px 0px 5px 0px !important;

      hr {
        margin: 0.5rem 0 !important;
      }

      .content {
        span img {
          // width: 51.6% !important;
          width: 45% !important;
        }

        button.payment-close {
          height: 45px !important;
        }
      }
    }

    .review-wrapper {
      padding: 25px 30px 10px !important;
      width: 495px;

      .initials-block {
        width: 45px !important;
        height: 45px !important;
        font-size: 10px;
      }

      .agreement {
        padding: 15px 0 !important;
      }

      .summary {
        padding: 10px !important;

        .plan-duration {
          h3 {
            font-size: 16px;
          }

          h5 {
            font-size: 16px;
          }
        }
      }

      .discount {
        padding: 15px 0 0px;
      }

      .checkout {
        .stripe-info {
          p {
            margin-bottom: 10px !important;
          }
        }
      }
    }
  }

  .chatbot .chatbot-main-container {
    width: 34% !important;
  }

  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 14px;
  }

  .new-chatbot .chatbot-main-container {
    width: 34% !important;
  }

  .new-chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 14px;
  }
  .plans-wrapper {
    max-height: 90vh !important;
  }
}

@media screen and (max-width: 1277px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 25px 5px 40px;
        width: 65% !important;
      }
    }
  }
}

@media screen and (min-width: 1279px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 25px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 0.5px;
            }
          }
        }
      }
    }
  }

  .password-check-wrapper {
    .password-checker {
      padding-bottom: 25px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      li {
        padding-bottom: 7px;
        text-align: left;
        width: 44%;
      }
    }
  }

  .feedback-container .themes-popup .ratings-section .ratings-text {
    font-size: 12px;
  }

  .feedback-container .themes-popup .form .text-area label {
    font-size: 14px !important;
  }

  .feedback-container .themes-popup .form .text-area textarea {
    font-size: 12px;
  }

  .payment-wrapper .info-wrapper .choose-plan .billings .period-billing {
    padding: 20px 50px 6px 30px;
  }

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list span,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list span {
    width: 42% !important;
    padding-right: 30px !important;
    font-size: 12px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing .blue-tick img {
    width: 12px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro {
    margin-bottom: 26px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans {
    padding-bottom: 20px !important;
  }

  .plans-wrapper .more-features-banner .icon-plus-text .crown-icon {
    width: 50px !important;
  }

  .payment-wrapper .info-wrapper .billing-form .apply-button {
    top: 39px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .amount h5 {
    font-size: 19px !important;
  }

  .upgrade-wrapper .upgrade-blocks .info .billing .cycle-switch .period-billing {
    padding-right: 30px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 0px !important;
    font-size: 17px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 0px !important;
    margin-bottom: 0rem !important;
    font-size: 11px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans h4 {
    font-size: 11px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 11px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 10px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing {
    padding: 10px 66px 0px 20px !important;
  }

  .billing-wrapper .info-details-wrapper .billing-form .billing-address .apply-button {
    top: 45px !important;
  }

  .project-limit-tag-upgrade-popup,
  .pro-tag-upgrade-popup,
  .screen-limit-tag-upgrade-popup,
  .exports-limit-tag-upgrade-popup {
    font-size: 10px !important;
  }

  .plans-modal {
    min-width: 1000px;
  }

  // .items-list-section{
  //     overflow: auto;
  //     height: 140px;
  // }
  .status-wrapper .graphics-text .status-text p {
    font-size: 0.8em !important;
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .mod {
    padding: 0 160px;
  }

  .layout-wrapper {
    margin-left: 305px !important;
  }

  .component {
    margin-left: 275px !important;
  }

  .main-margin {
    margin-left: 280px !important;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .hideScroll {
    max-width: 55vw !important;
  }

  .profile-wrapper .verify-success .verify-icon {
    top: 11px;
    right: 20px;
    width: 22px;
  }

  .or-option {
    text-align: center;
    padding: 10px 0 0;
    color: #d3d3d3;
    letter-spacing: -2px;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
  }

  .form-wrapper .form-block-wrapper .form-group {
    margin-bottom: 15px !important;
  }

  .checkbox {
    position: relative;
    margin-top: -15px !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .signup-header-block {
    padding-bottom: 0px !important;
  }

  .form-wrapper .header-block p {
    padding-bottom: 0px !important;
  }

  .form-wrapper .password-format {
    padding-bottom: 25px !important;
  }

  .form-wrapper.reset-password .onboarding-steps {
    min-height: auto;
  }

  .login-side-image .onboarding-slider {
    // width: 118%;
  }

  .onboarding-slider {
    h3 {
      font-size: 24px;
    }
  }

  .label-input {
    font-size: 14px !important;
  }

  .input-style,
  .PhoneInputInput {
    height: 45px !important;
    font-size: 14px !important;
  }

  .password-input img {
    top: 45px !important;
  }

  .login-div {
    .form-wrapper {
      padding: 10px 33px 5px 40px;

      .header-block {
        h3 {
          font-size: 20px;
          padding-bottom: 3px;
        }

        p {
          font-size: 14px;
        }

        .login-btn-google {
          height: 40px !important;
        }
      }

      .login-button {
        height: 45px !important;
      }

      .footer-text {
        padding: 10px 0 0;
      }

      .password-format {
        padding-bottom: 20px;

        label {
          font-size: 12px;
        }
      }
    }
  }

  .details-wrapper button.notification-btn.btn.btn-secondary {
    height: 40px !important;
  }

  button.logout.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.remove-acc.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.prime-btn.btn.btn-primary {
    height: 45px !important;
    font-size: 14px !important;
  }

  button.small-btn.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.medium-btn.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.notification-btn.btn.btn-secondary {
    height: 45px !important;
    font-size: 14px;
  }

  button.small-skip-btn.btn.btn-primary {
    height: 45px;
    font-size: 15px;
  }

  .template-info-block .template-info-header .info-btn-block .live-demo {
    height: 45px !important;
  }

  .search-close {
    width: 45px !important;
    top: 4px !important;
  }

  .billing-wrapper .load-image {
    height: 45px !important;
  }

  .layout-size-inputs .submit-buttons button.custom-size {
    height: 40px !important;
    min-width: 80px !important;
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 220px !important;
        overflow: auto;
      }
    }
  }

  .readBlog-main {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1365px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 16px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 0.5px;
            }
          }
        }
      }
    }
  }

  .password-check-wrapper {
    .password-checker {
      padding-bottom: 30px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      li {
        padding-bottom: 7px;
        text-align: left;
        width: 42%;
      }
    }
  }

  .feedback-container .themes-popup .ratings-section .ratings-text {
    font-size: 12px;
  }

  .feedback-container .themes-popup .form .text-area label {
    font-size: 14px !important;
  }

  .feedback-container .themes-popup .form .text-area textarea {
    font-size: 12px;
  }

  // .template-request-form-popup{
  //     .themes-popup{
  //         .header{
  //             h4{
  //                 font-size: 18px;
  //             }
  //         }
  //         .content{
  //             h5{
  //                 font-size: 14px;
  //             }
  //         }
  //         .fields{
  //             .form-group{
  //                 .css-14el2xx-placeholder{
  //                     font-size: 13px;
  //                 }
  //                 .css-16841bg-control{
  //                     .css-319lph-ValueContainer{
  //                         .css-qc6sy-singleValue{
  //                             font-size: 13px;
  //                         }
  //                     }
  //                 }
  //                 input{
  //                     font-size: 13px !important ;
  //                     &::placeholder{
  //                         font-size: 13px;
  //                     }
  //                 }
  //                 textarea{
  //                     font-size: 13px;
  //                     &::placeholder{
  //                         font-size: 13px;
  //                     }
  //                 }
  //                 label{
  //                     font-size: 13px;
  //                 }
  //             }
  //         }
  //     }
  // }

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .payment-wrapper .info-wrapper .choose-plan .billings .period-billing {
    padding: 20px 50px 6px 30px;
  }

  .billing-wrapper .cancellation-wrapper .cancel-block .reason .list span,
  .billing-wrapper .cancellation-wrapper .cancel-block .agreement .list span {
    width: 42% !important;
    padding-right: 30px !important;
    font-size: 12px !important;
  }

  .upgrade-wrapper .upgrade-blocks .info .billing .cycle-switch .period-billing {
    padding-right: 30px !important;
  }

  .project-limit-tag-upgrade-popup,
  .pro-tag-upgrade-popup,
  .screen-limit-tag-upgrade-popup,
  .exports-limit-tag-upgrade-popup {
    font-size: 12px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade {
    padding: 35px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 2px !important;
    font-size: 20px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 0px !important;
    margin-bottom: 0.3rem !important;
    font-size: 13px !important;
  }

  .payment-wrapper .info-wrapper .billing-form .apply-button {
    top: 39px !important;
  }

  .plans-wrapper .more-features-banner .icon-plus-text .crown-icon {
    width: 50px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 13px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 11px !important;
  }

  .plans-modal {
    min-width: 1000px;
  }

  .items-list-section {
    overflow: auto;
    height: auto;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing {
    padding: 10px 64px 0px 20px !important;
  }

  .status-wrapper .graphics-text .status-graphics {
    img {
      width: 60% !important;
    }
  }

  .billing-wrapper .info-details-wrapper .billing-form .billing-address .apply-button {
    top: 45px !important;
  }

  .status-wrapper .graphics-text .status-text h2 {
    font-size: 1.8em !important;
  }

  .status-wrapper .graphics-text .status-text p {
    font-size: 0.9em !important;
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .mod {
    padding: 0 200px;
  }

  .layout-wrapper {
    margin-left: 315px !important;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .hideScroll {
    max-width: 57.9vw !important;
  }

  .component {
    margin-left: 285px !important;
  }

  .main-margin {
    margin-left: 296px !important;
  }

  .or-option {
    text-align: center;
    padding: 10px 0 0;
    color: #d3d3d3;
    letter-spacing: -2px;
    font-size: 0.9rem;
    margin-bottom: 0px !important;
  }

  .profile-wrapper .verify-success .verify-icon {
    top: 11px;
    right: 20px;
    width: 22px;
  }

  .form-wrapper .form-block-wrapper .form-group {
    margin-bottom: 18px !important;
  }

  .checkbox {
    position: relative;
    margin-top: -15px !important;
  }

  .signup-header-block {
    padding-bottom: 0px !important;
  }

  .form-wrapper .header-block p {
    padding-bottom: 0px !important;
  }

  .form-wrapper .password-format {
    padding-bottom: 25px !important;
  }

  .form-wrapper.reset-password .onboarding-steps {
    min-height: auto;
  }

  .login-side-image .onboarding-slider {
    // width: 118%;
  }

  .onboarding-slider {
    h3 {
      font-size: 28px;
    }
  }

  .label-input {
    font-size: 16px !important;
  }

  .PhoneInputInput,
  .input-style {
    height: 45px !important;
    font-size: 14px !important;
  }

  .password-input img {
    top: 42px !important;
  }

  .details-wrapper button.notification-btn.btn.btn-secondary {
    height: 40px !important;
  }

  .billing-wrapper .load-image {
    height: 45px !important;
  }

  .login-div {
    .form-wrapper {
      padding: 15px 33px 5px 40px !important;

      .header-block {
        h3 {
          font-size: 20px !important;
          padding-bottom: 3px;
        }

        p {
          font-size: 16px !important;
        }

        .login-btn-google {
          height: 45px !important;
        }
      }

      .login-button {
        height: 48px !important;
      }

      .footer-text {
        padding: 20px 0 0 !important;
      }

      .password-format {
        padding-bottom: 20px !important;

        label {
          font-size: 12px !important;
        }
      }
    }
  }

  button.logout.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.remove-acc.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.prime-btn.btn.btn-primary {
    height: 45px;
    font-size: 14px;
  }

  button.small-btn.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.medium-btn.btn.btn-primary {
    height: 45px !important;
    font-size: 14px;
  }

  button.notification-btn.btn.btn-secondary {
    height: 45px !important;
    font-size: 14px;
  }

  button.small-skip-btn.btn.btn-primary {
    height: 45px;
    font-size: 14px;
  }

  .template-info-block .template-info-header .info-btn-block .live-demo {
    height: 45px !important;
  }

  .search-close {
    width: 45px !important;
    top: 4px !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .category-respo {
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .layout-size-inputs .submit-buttons button.custom-size {
    height: 40px !important;
    min-width: 80px !important;
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 290px !important;
        overflow: auto;
      }
    }
  }

  .readBlog-main {
    padding: 1.5rem;
  }

  // .chatbot .chatbot-main-container {
  //   width: 34% !important;
  // }
  .chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 15px;
  }

  // .new-chatbot .chatbot-main-container {
  //   width: 34% !important;
  // }
  .new-chatbot .chatbot-main-container .chatbot-body .chatbot-blocks .chatbot-block p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1439px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 16px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 1.325px;
            }
          }
        }
      }
    }
  }

  .password-check-wrapper {
    .password-checker {
      padding-bottom: 25px;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;

      li {
        padding-bottom: 7px;
        text-align: left;
      }
    }
  }

  .shared-with-me-label-container p {
    font-size: 16px !important;
    max-width: 145px !important;
  }

  .shared-dropdown-container {
    height: 260px !important;
    width: 271px !important;

    .shared-profile-tab {
      padding: 20px 0px 20px 14px !important;
      height: 52px !important;

      .shared-profile-person-details {
        gap: 10px !important;

        .person-details {
          p:nth-child(1) {
            font-size: 14px !important;
            max-width: 152px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }

          p:nth-child(2) {
            font-size: 12px !important;
            max-width: 152px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;
          }
        }
      }

      .shared-spherical-initials {
        width: 32px !important;
        height: 32px !important;
        font-size: 12px !important;
      }

      .shared-spherical-initials-p {
        width: 32px !important;
        height: 32px !important;
        font-size: 12px !important;
      }

      .shared-spherical-initials-a {
        width: 32px !important;
        height: 32px !important;
        font-size: 12px !important;
      }
    }
  }

  .members-label {
    font-size: 14px !important;
  }

  .shared-members-block {
    height: 156px !important;
  }

  .chatscreen-container .chatscreen-body .options span {
    font-size: 13px;
  }

  .chatscreen-container .chatscreen-body .user-message-container {
    font-size: 13px;
  }

  .chatscreen-container .chatscreen-body .bot-message-container .bot-chating .bot-message div {
    font-size: 13px;
  }

  .upgrade-wrapper .upgrade-blocks .info .billing .cycle-switch .period-billing {
    padding-right: 30px !important;
  }

  .live-demo {
    font-size: 16px !important;

    img {
      width: 40px !important;
    }
  }

  .project-limit-tag-upgrade-popup,
  .pro-tag-upgrade-popup,
  .screen-limit-tag-upgrade-popup,
  .exports-limit-tag-upgrade-popup {
    font-size: 13px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade {
    padding: 40px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 4px !important;
    font-size: 22px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .plans .period-billing {
    padding: 15px 64px 0px 20px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 2px !important;
    margin-bottom: 0.3rem !important;
    font-size: 14px !important;
  }

  .payment-wrapper .info-wrapper .choose-plan .billings .period-billing {
    padding: 20px 60px 6px 30px;
  }

  .plans-wrapper .more-features-banner .icon-plus-text .crown-icon {
    width: 35px !important;
  }

  .payment-wrapper .info-wrapper .billing-form .apply-button {
    top: 42px !important;
  }

  .billing-wrapper .info-details-wrapper .billing-form .billing-address .apply-button {
    top: 49px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 14px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 12px !important;
  }

  .plans-modal {
    min-width: 1000px;
  }

  .items-list-section {
    overflow: auto;
    height: auto;
  }

  .status-wrapper .graphics-text .status-graphics {
    width: 48% !important;
  }

  .status-wrapper .graphics-text .status-text h2 {
    font-size: 2em !important;
  }

  .status-wrapper .graphics-text .status-text p {
    font-size: 1em !important;
  }

  .login-div {
    .form-wrapper {
      padding: 40px !important;
    }
  }

  .login-div {
    .form-wrapper {
      .header-block {
        .login-btn-google {
          height: 50px !important;
        }
      }

      .login-button {
        height: 48px !important;
        font-size: 16px !important;
      }
    }
  }

  .password-input img {
    top: 48px !important;
  }

  button.logout.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  button.remove-acc.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  .profile-wrapper .verify-success .verify-icon {
    top: 12px;
    right: 20px;
    width: 25px;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .hideScroll {
    max-width: 60vw !important;
  }

  button.prime-btn.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  button.small-btn.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  button.medium-btn.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  button.notification-btn.btn.btn-secondary {
    height: 50px !important;
    font-size: 16px !important;
  }

  button.small-skip-btn.btn.btn-primary {
    height: 50px !important;
    font-size: 16px !important;
  }

  .onboarding-steps {
    button {
      height: 50px !important;
      font-size: 16px !important;
    }
  }

  .designs-section .designs-header-section h2 {
    padding-top: 10px;
  }

  .templates-section .templates-header h2 {
    padding-top: 10px;
  }

  .details-wrapper button.notification-btn.btn.btn-secondary {
    height: 40px !important;
  }

  .billing-wrapper .load-image {
    height: 50px !important;
  }

  .template-info-block .template-info-header .info-btn-block .live-demo {
    height: 50px !important;
  }

  .search-close {
    width: 50px !important;
    top: 7px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 150px 40px 150px !important;
  }

  .templates-section {
    padding: 0px 150px 0px 150px !important;
  }

  .label-input {
    font-size: 16px !important;
  }

  .text-size-14 {
    font-size: 14px !important;
  }

  .PhoneInputInput,
  .input-style {
    height: 50px !important;
    font-size: 16px !important;
  }

  .template-info-block {
    padding: 0px 150px 0px 150px !important;
  }

  .template-info-lazy-container {
    padding: 0px 150px 0px 150px !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .learn-section {
    padding: 0px 150px 40px 150px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 150px 6px 150px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 150px 10px 150px !important;
  }

  .dashboard-section {
    padding: 0px 150px 0px 150px !important;
  }

  .promotion {
    padding: 40px 150px 0px 150px;
  }

  .designs-section {
    padding: 0px 150px 0px 150px !important;
  }

  hr.pagination-hr {
    margin: 0px 150px 20px 150px;
  }

  .pagination-block {
    padding: 0 150px 40px 150px;
  }

  .layout-wrapper {
    margin-left: 315px !important;
  }

  .category-respo {
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .component {
    margin-left: 315px !important;
  }

  .main-margin {
    margin-left: 297px !important;
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 375px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 1535px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 16px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .chatscreen-container .chatscreen-body .options span {
    font-size: 14px;
  }

  .chatscreen-container .chatscreen-body .user-message-container {
    font-size: 14px;
  }

  .chatscreen-container .chatscreen-body .bot-message-container .bot-chating .bot-message div {
    font-size: 14px;
  }

  .upgrade-wrapper .upgrade-blocks .info .billing .cycle-switch .period-billing {
    padding-right: 40px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 4px !important;
    font-size: 22px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 2px !important;
    margin-bottom: 0.3rem !important;
    font-size: 14px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 14px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 12px !important;
  }

  .status-wrapper .graphics-text .status-graphics {
    width: 50% !important;
  }

  .status-wrapper .graphics-text .status-text h2 {
    font-size: 2em !important;
  }

  .status-wrapper .graphics-text .status-text p {
    font-size: 1em !important;
  }

  .login-div {
    .form-wrapper {
      padding: 40px !important;
    }
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .layout-wrapper {
    margin-left: 330px !important;
  }

  .component {
    margin-left: 310px !important;
  }

  .category-respo {
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .main-margin {
    margin-left: 315px !important;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .hideScroll {
    max-width: 62.5vw !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .onboarding-slider {
    h3 {
      font-size: 32px;
    }
  }

  // .blog-info {
  //     width: 80%;
  // }
}

@media screen and (min-width: 1599px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .upgrade-modal .upgrade-body .content-upgrade .header h3 {
    padding-bottom: 4px !important;
    font-size: 24px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .header p {
    padding-bottom: 2px !important;
    margin-bottom: 0.3rem !important;
    font-size: 14px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro p {
    font-size: 14px !important;
  }

  .upgrade-modal .upgrade-body .content-upgrade .items-included-in-pro .items-list-section ul li {
    font-size: 12px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 250px 40px 250px !important;
  }

  .templates-section {
    padding: 0px 250px 0px 250px !important;
  }

  .hideScroll {
    max-width: 64vw !important;
  }

  // .previewScroll {
  //     max-width: 64vw !important;
  // }

  .template-info-block {
    padding: 0px 250px 0px 250px !important;
  }

  .template-info-lazy-container {
    padding: 0px 250px 0px 250px !important;
  }

  .learn-section {
    padding: 0px 250px 40px 250px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 250px 6px 250px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 250px 10px 250px !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .category-respo {
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .dashboard-section {
    padding: 0px 250px 0px 250px !important;
  }

  .promotion {
    padding: 40px 250px 0px 250px;
  }

  .designs-section {
    padding: 0px 250px 0px 250px !important;
  }

  hr.pagination-hr {
    margin: 0px 250px 20px 250px;
  }

  .pagination-block {
    padding: 0 250px 40px 250px;
  }

  .layout-wrapper {
    margin-left: 333px !important;
  }

  .component {
    margin-left: 315px !important;
  }

  .main-margin {
    margin-left: 318px !important;
  }

  .login-side-image .onboarding-slider {
    // width: 118%;
  }

  .onboarding-slider {
    h3 {
      font-size: 32px;
    }
  }
}

@media screen and (min-width: 1679px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .blog-overlay-lazy-container {
    max-width: 825px !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 275px 40px 275px !important;
  }

  .templates-section {
    padding: 0px 275px 0px 275px !important;
  }

  .hideScroll {
    max-width: 65.9vw !important;
  }

  .previewScroll {
    max-width: 65.9vw !important;
  }

  .template-info-block {
    padding: 0px 275px 0px 275px !important;
  }

  .template-info-lazy-container {
    padding: 0px 275px 0px 275px !important;
  }

  .design-image.grid-view-respo {
    min-height: 260px !important;
    max-height: 260px !important;
  }

  .category-respo {
    min-height: 170px !important;
    max-height: 170px !important;
  }

  .learn-section {
    padding: 0px 275px 40px 275px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 275px 6px 275px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 275px 10px 275px !important;
  }

  .dashboard-section {
    padding: 0px 275px 0px 275px !important;
  }

  .promotion {
    padding: 40px 275px 0px 275px;
  }

  .designs-section {
    padding: 0px 275px 0px 275px !important;
  }

  hr.pagination-hr {
    margin: 0px 275px 20px 275px;
  }

  .pagination-block {
    padding: 0 275px 40px 275px;
  }

  .layout-wrapper {
    margin-left: 347px !important;
  }

  .component {
    margin-left: 325px !important;
  }

  .main-margin {
    margin-left: 323px !important;
  }
}

@media screen and (min-width: 1791px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .heading-section,
  .heading-content-separateLine,
  .blog-content {
    max-width: 1000px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 1000px !important;
  }

  .layout-wrapper {
    margin-left: 380px !important;
  }

  .hideScroll {
    max-width: 67.9vw !important;
  }

  .previewScroll {
    max-width: 67.9vw !important;
  }

  .component {
    margin-left: 335px !important;
  }

  .main-margin {
    margin-left: 338px !important;
  }

  .design-image.grid-view-respo {
    min-height: 280px !important;
    max-height: 280px !important;
  }

  .category-respo {
    min-height: 180px !important;
    max-height: 180px !important;
  }

  .onboarding-slider {
    h3 {
      font-size: 34px;
    }
  }
}

@media screen and (min-width: 1919px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 0px;
              padding-bottom: 0.010px;
            }
          }
        }
      }
    }
  }

  .feedback-container .themes-popup .ratings-section .ratings-text {
    font-size: 12px;
  }

  .feedback-container .themes-popup .form .text-area label {
    font-size: 14px !important;
  }

  .feedback-container .themes-popup .form .text-area textarea {
    font-size: 14px;
  }

  .explore-templates-slider .create-new {
    height: 233px !important;
  }

  .explore-templates-slider .parent .child {
    max-height: 210px;
  }

  .heading-section,
  .heading-content-separateLine,
  .blog-content {
    max-width: 1200px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 1200px !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 275px 40px 275px !important;
  }

  .templates-section {
    padding: 0px 275px 0px 275px !important;
  }

  .template-info-block {
    padding: 0px 275px 0px 275px !important;
  }

  .hideScroll {
    max-width: 70vw !important;
  }

  .previewScroll {
    max-width: 70vw !important;
  }

  .template-info-lazy-container {
    padding: 0px 275px 0px 275px !important;
  }

  .design-image.grid-view-respo {
    min-height: 320px !important;
    max-height: 320px !important;
  }

  .category-respo {
    min-height: 215px !important;
    max-height: 215px !important;
  }

  .learn-section {
    padding: 0px 275px 40px 275px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 275px 6px 275px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 275px 10px 275px !important;
  }

  .dashboard-section {
    padding: 0px 275px 0px 275px !important;
  }

  .promotion {
    padding: 40px 275px 0px 275px;
  }

  .designs-section {
    padding: 0px 275px 0px 275px !important;
  }

  hr.pagination-hr {
    margin: 0px 275px 20px 275px;
  }

  .pagination-block {
    padding: 0 275px 40px 275px;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  .layout-wrapper {
    margin-left: 395px !important;
  }

  .component {
    margin-left: 297px !important;
  }

  .main-margin {
    margin-left: 303px !important;
  }

  .login-side-image .onboarding-slider {
    // width: 117%;
  }

  .onboarding-slider {
    h3 {
      font-size: 36px;
    }
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 395px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 2303px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 1px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .heading-section,
  .heading-content-separateLine,
  .blog-content {
    max-width: 1400px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 1400px !important;
  }

  .hideScroll {
    max-width: 75vw !important;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 450px 40px 450px !important;
  }

  .templates-section {
    padding: 0px 450px 0px 450px !important;
  }

  .template-info-block {
    padding: 0px 450px 0px 450px !important;
  }

  .template-info-lazy-container {
    padding: 0px 450px 0px 450px !important;
  }

  .design-image.grid-view-respo {
    min-height: 330px !important;
    max-height: 330px !important;
  }

  .learn-section {
    padding: 0px 450px 40px 450px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 450px 6px 450px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 450px 10px 450px !important;
  }

  .dashboard-section {
    padding: 0px 450px 0px 450px !important;
  }

  .promotion {
    padding: 40px 450px 0px 450px;
  }

  .designs-section {
    padding: 0px 450px 0px 450px !important;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  hr.pagination-hr {
    margin: 0px 450px 20px 450px;
  }

  .pagination-block {
    padding: 0 450px 40px 450px;
  }

  .layout-wrapper {
    margin-left: 445px !important;
  }

  .category-respo {
    min-height: 222px !important;
    max-height: 222px !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 510px;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 40px;
    }
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 395px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 2559px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .heading-section,
  .heading-content-separateLine,
  .blog-content {
    max-width: 1600px !important;
  }

  .blog-overlay-lazy-container {
    max-width: 1600px !important;
  }

  .hideScroll {
    max-width: 77.5vw !important;
  }

  .previewScroll {
    max-width: 80vw !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 500px 40px 500px !important;
  }

  .templates-section {
    padding: 0px 500px 0px 500px !important;
  }

  .template-info-block {
    padding: 0px 500px 0px 500px !important;
  }

  .template-info-lazy-container {
    padding: 0px 500px 0px 500px !important;
  }

  .learn-section {
    padding: 0px 500px 40px 500px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 500px 6px 500px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 500px 10px 500px !important;
  }

  .dashboard-section {
    padding: 0px 500px 0px 500px !important;
  }

  .promotion {
    padding: 40px 500px 0px 500px;
  }

  .design-image.grid-view-respo {
    min-height: 370px !important;
    max-height: 370px !important;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  .designs-section {
    padding: 0px 500px 0px 500px !important;
  }

  hr.pagination-hr {
    margin: 0px 500px 20px 500px;
  }

  .pagination-block {
    padding: 0 500px 40px 500px;
  }

  .layout-wrapper {
    margin-left: 475px !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 510px;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 44px;
    }
  }

  .category-respo {
    min-height: 251px !important;
    max-height: 251px !important;
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 395px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 3022px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .or-option {
          padding: 20px 0 20px 0 !important;
        }

        .email-input {
          padding: 0 0 2.5px 0 !important;
        }

        .password-input {
          padding: 0 0 1px 0 !important;
        }

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
          padding-bottom: 20px;
        }

        .footer-text {
          padding: 10px 0 0;

          .hide-br {
            display: none;
          }
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .explore-templates-slider .create-new {
    height: 273px !important;
  }

  .explore-templates-slider .parent .child {
    max-height: 250px;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 650px 40px 650px !important;
  }

  .template-info-block {
    padding: 0px 650px 0px 650px !important;
  }

  .template-info-lazy-container {
    padding: 0px 650px 0px 650px !important;
  }

  .templates-section {
    padding: 0px 650px 0px 650px !important;
  }

  .learn-section {
    padding: 0px 650px 40px 650px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 650px 6px 650px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 650px 10px 650px !important;
  }

  .dashboard-section {
    padding: 0px 650px 0px 650px !important;
  }

  .promotion {
    padding: 40px 650px 0px 650px;
  }

  .design-image.grid-view-respo {
    min-height: 420px !important;
    max-height: 420px !important;
  }

  .category-respo {
    min-height: 280px !important;
    max-height: 280px !important;
  }

  .designs-section {
    padding: 0px 650px 0px 650px !important;
  }

  .hideScroll {
    max-width: 80.9vw !important;
  }

  .previewScroll {
    max-width: 80.9vw !important;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  hr.pagination-hr {
    margin: 0px 650px 20px 650px;
  }

  .pagination-block {
    padding: 0 650px 40px 650px;
  }

  .layout-wrapper {
    margin-left: 535px !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 50vw;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 48px;
    }
  }
}

@media screen and (min-width: 3071px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .or-option {
          padding: 20px 0 20px 0 !important;
        }

        .email-input {
          padding: 0 0 2.5px 0 !important;
        }

        .password-input {
          padding: 0 0 1px 0 !important;
        }

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
          padding-bottom: 20px;
        }

        .footer-text {
          padding: 10px 0 0;

          .hide-br {
            display: none;
          }
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 700px 40px 700px !important;
  }

  .templates-section {
    padding: 0px 700px 0px 700px !important;
  }

  .template-info-block {
    padding: 0px 700px 0px 700px !important;
  }

  .hideScroll {
    max-width: 81.3vw !important;
  }

  .previewScroll {
    max-width: 81.3vw !important;
  }

  .template-info-lazy-container {
    padding: 0px 700px 0px 700px !important;
  }

  .design-image.grid-view-respo {
    min-height: 410px !important;
    max-height: 410px !important;
  }

  .category-respo {
    min-height: 272px !important;
    max-height: 272px !important;
  }

  .learn-section {
    padding: 0px 700px 40px 700px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 700px 6px 700px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 700px 10px 700px !important;
  }

  .dashboard-section {
    padding: 0px 700px 0px 700px !important;
  }

  .promotion {
    padding: 40px 700px 0px 700px;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  hr.pagination-hr {
    margin: 0px 700px 20px 700px;
  }

  .pagination-block {
    padding: 0 700px 40px 700px;
  }

  .designs-section {
    padding: 0px 700px 0px 700px !important;
  }

  .layout-wrapper {
    margin-left: 545px !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 510px;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 52px;
    }
  }
}

@media screen and (min-width: 3452px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 65% !important;

        .or-option {
          padding: 20px 0 20px 0 !important;
        }

        .email-input {
          padding: 0 0 2.5px 0 !important;
        }

        .password-input {
          padding: 0 0 1px 0 !important;
        }

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
          padding-bottom: 20px;
        }

        .footer-text {
          padding: 10px 0 0;

          .hide-br {
            display: none;
          }
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 850px 40px 850px !important;
  }

  .templates-section {
    padding: 0px 850px 0px 850px !important;
  }

  .template-info-block {
    padding: 0px 850px 0px 850px !important;
  }

  .template-info-lazy-container {
    padding: 0px 850px 0px 850px !important;
  }

  .hideScroll {
    max-width: 83.3vw !important;
  }

  .previewScroll {
    max-width: 83.3vw !important;
  }

  .learn-section {
    padding: 0px 850px 40px 850px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 850px 6px 850px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 850px 10px 850px !important;
  }

  .design-image.grid-view-respo {
    min-height: 430px !important;
    max-height: 430px !important;
  }

  .category-respo {
    min-height: 285px !important;
    max-height: 285px !important;
  }

  .dashboard-section {
    padding: 0px 850px 0px 850px !important;
  }

  .promotion {
    padding: 40px 850px 0px 850px;
  }

  .designs-section {
    padding: 0px 850px 0px 850px !important;
  }

  .info-block .image-block {
    width: 10% !important;
  }

  hr.pagination-hr {
    margin: 0px 850px 20px 850px;
  }

  .pagination-block {
    padding: 0 850px 40px 850px;
  }

  .layout-wrapper {
    margin-left: 595px !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 510px;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 56px;
    }
  }


  .themes-popup {
    .content {
      .theme-list {
        max-height: 400px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 3839px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .or-option {
          padding: 20px 0 20px 0 !important;
        }

        .email-input {
          padding: 0 0 2.5px 0 !important;
        }

        .password-input {
          padding: 0 0 1px 0 !important;
        }

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
          padding-bottom: 20px;
        }

        .footer-text {
          padding: 10px 0 0;

          .hide-br {
            display: none;
          }
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 1.3px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .explore-templates-slider .create-new {
    height: 423px !important;
  }

  .explore-templates-slider .parent .child {
    max-height: 400px;
  }

  .design-image.grid-view-respo {
    min-height: 598px !important;
    max-height: 598px !important;
  }

  .category-respo {
    min-height: 397px !important;
    max-height: 397px !important;
  }

  .profile-wrapper,
  .billing-wrapper {
    padding: 0px 750px 40px 750px !important;
  }

  .templates-section {
    padding: 0px 750px 0px 750px !important;
  }

  .template-info-block {
    padding: 0px 750px 0px 750px !important;
  }

  .template-info-lazy-container {
    padding: 0px 750px 0px 750px !important;
  }

  .learn-section {
    padding: 0px 750px 40px 750px !important;
  }

  .form-header.heading-section-dashboard {
    padding: 5px 750px 6px 750px !important;
  }

  .fixed-project-limit-banner {
    padding: 10px 750px 10px 750px !important;
  }

  .dashboard-section {
    padding: 0px 750px 0px 750px !important;
  }

  .promotion {
    padding: 40px 750px 0px 750px;
  }

  .designs-section {
    padding: 0px 750px 0px 750px !important;
  }

  .info-block .image-block {
    width: 5% !important;
  }

  hr.pagination-hr {
    margin: 0px 750px 20px 750px;
  }

  .pagination-block {
    padding: 0 750px 40px 750px;
  }

  .layout-wrapper {
    margin-left: 595px !important;
  }

  .hideScroll {
    max-width: 85vw !important;
  }

  .previewScroll {
    max-width: 85vw !important;
  }

  .component {
    margin-left: 120px !important;
  }

  .main-margin {
    margin-left: 150px !important;
  }

  .login-container-width {
    // max-width: 510px;
    background: #ffffff !important;
  }

  .login-side-image .onboarding-slider {
    // width: 119%;
  }

  .onboarding-slider {
    h3 {
      font-size: 62px;
    }
  }

  .themes-popup {
    .content {
      .theme-list {
        max-height: 400px !important;
        overflow: auto;
      }
    }
  }
}

@media screen and (min-width: 4095px) {
  .new-login {
    &.login-div {
      .form-wrapper {
        padding: 10px 33px 5px 40px;
        width: 60% !important;

        .or-option {
          padding: 20px 0 20px 0 !important;
        }

        .email-input {
          padding: 0 0 5px 0 !important;
        }

        .password-input {
          padding: 0 0 1px 0 !important;
        }

        .header-block {
          p {
            font-size: 14px;
          }

          .login-btn-google {
            height: 40px !important;
          }
        }

        .login-button {
          height: 45px !important;
          padding-bottom: 20px;
        }

        .footer-text {
          padding: 10px 0 0;
        }

        .password-format {
          padding-bottom: 20px;

          label {
            font-size: 12px;
          }
        }
      }
    }
  }

  .project-header {
    .tabs {
      .nav-tabs {
        .nav-item {
          .nav-link {
            .count {
              padding-top: 1.3px;
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }

  .onboarding-slider {
    h3 {
      font-size: 68px;
    }
  }
}

@media screen and (max-width: 1290px) and (max-height: 768px) {
  .no-design-parent-container {
    height: calc(100vh - 15.25rem);
  }

  .no-design-image {
    width: 80%;
    height: auto;
  }
}

@media screen and (width: 1920px) and (height: 1080px),
screen and (width: 1600px) and (height: 900px),
screen and (width: 1440px) and (height: 900px),
screen and (width: 1536px) and (height: 864px),
screen and (width: 2560px) and (height: 1440px),
screen and (width: 1680px) and (height: 1050px),
screen and (width: 1792px) and (height: 828px),
screen and (width: 2304px) and (height: 1296px) {
  .no-design-parent-container {
    height: calc(100vh - 19rem);
  }
}

@media screen and (width: 3072px) and (height: 1920px),
screen and (width: 3840px) and (height: 2160px),
screen and (width: 4096px) and (height: 2160px),
screen and (width: 3024px) and (height: 1964px),
screen and (width: 3456px) and (height: 2160px) {
  .no-design-parent-container {
    height: calc(100vh - 21rem);
  }
}

@media screen and (width: 1366px) and (height: 768px),
screen and (width: 1280px) and (height: 768px) {
  .no-design-parent-container {
    height: calc(100vh - 18rem);
  }

  .no-design-image {
    width: 80%;
    height: auto;
  }
}