.screen-bar-share{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background-color: var(--text-active-color);

    .footer-wrapper .screen-tabs .nav-tabs .nav-item .nav-link {
        padding: 0.6rem 1rem;

        p{
            padding-left: 0px;
        }
    }

    .footer-wrapper .footer-block {
       justify-content: space-between;
    }

    .hideScroll{
        max-width: calc(100vw - 313px) !important;
        justify-content: space-between;
        // width: 100%;
    }
    .screen-names{
        flex-basis: calc(100% - 311px) !important;

        .nav-tabs{
            width: 100%;
            justify-content: space-between;
        }
    }
}