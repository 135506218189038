.profile-wrapper {
    padding: 0px 100px 0 100px;
    text-align: left;

    .css-tj5bde-Svg {
        fill: #323232;
    }

    .profile-header {
        position: sticky;
        z-index: 999;
        top: 0;
        background: #ffffff;
        padding: 50px 0 40px 0;

        .heading {
            padding-bottom: 25px;

            h2 {
                color: #000000;
                font-size: 24px;
            }
        }

        .tabs {
            .nav-tabs {
                border-bottom: 1px solid #e6e7e9;

                .nav-item {
                    padding-bottom: 5px;
                    .nav-link {
                        color: #5E5E5E;
                        border: none;
                        cursor: pointer;
                        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !important;
                        position: relative;
                        .bar{
                            display: none;

                          }

                        &.active {
                            // font-weight: 500;
                            color: #000000;
                            border: none;
                            // border-bottom: 4px solid #342bc2;

                            .bar{
                                display: flex !important;
                                background: #342bc2;
                                position: absolute;
                                height: 4px;
                                // left: 0px;
                                bottom: -4px;
                                border: none;
                                border-radius:10px;
                              }

                        }

                        &:not(.active):hover {
                            .bar{
                              display: flex !important;
                              background: #e9ecef;
                              position: absolute;
                              height: 4px;
                            //   left: 0px;
                              bottom: -4px;
                              border: none;
                              border-radius:10px;

                            }
                        }
                    }
                }
            }
        }
    }

    .tab-header {
        padding-bottom: 15px;

        h4 {
            font-size: 18px;
            color: #000000;
        }

        p {
            font-size: 14px;
            color: #5E5E5E;
        }
    }

    .general-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;

        .form-group {
            margin-bottom: 25px;
        }

        .submit-btn-block {
            margin-top: 60px;

            button {
                min-width: 160px !important;
            }
        }

        .profile-picture {
            min-height: 175px;
            max-height: 186px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 27px;
            margin-right: 27px;

            img {
                max-width: 175px;
                max-height: 186px;
            }
        }

        .update-profile-image {
            padding-top: 20px;
            text-align: center;
            max-width: 260px;
            margin: 0 auto;

            .upload {
                border-radius: 2px;
                border: solid 1px #e6e7e9;
                background-color: #f6f6f6;
                color: #000;
                width: 35%;
                padding: 8px;
                font-size: 14px;
                height: 40px;
                cursor: pointer;
            }

            span {
                padding-left: 15px;

                .remove {
                    border-radius: 2px;
                    border: solid 1px #e6e7e9;
                    background-color: #ffffff;
                    color: rgb(92, 92, 92);
                    font-size: 14px;
                    height: 40px;
                    width: 35%;
                }
            }

        }
    }

    .logout-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;
        margin: 40px 0;

        .logout {
            border-radius: 2px;
            border: solid 1px #342bc2;
            background-color: rgba(52, 43, 194, 0.08);
            color: #342bc2;
            min-width: 160px !important;
            height: 50px;

            &:hover {
                border: 1px solid #342bc2;
                background-color: #e3e2ea;
            }
        }
    }

    .account-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;
        margin: 0 0 40px 0;

        .forgotP {
            color: #342BC2;
        }

        .form-group {
            margin-bottom: 25px;
        }

        .remove-acc {
            border-radius: 2px;
            border: solid 1px #be1931;
            background-color: #be1931;
            color: #ffffff;
            min-width: 160px !important;
            height: 50px;
            &:hover{
                border: 1px solid #ac1128;
                background-color: #ac1128;
                color: #ffffff;
            }
        }
    }

    .email-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;

        .verify-email {
            max-width: 475px;
            position: relative;
        }

        .input-style.email {
            width: 100%;
            padding: 5px 15px;
        }

        .verify-btn {
            position: absolute;
            top: 0;
            right: 0;

            .user-icon {
                padding-right: 5px;
                margin-top: -2px;
            }

            ~p {
                color: #000;
                font-size: 13px;
                padding: 20px 0 0;
                font-weight: 500;
                margin-bottom: 0px;
            }
        }

        .enterOtp {
            .input-wrap.otpInput input {
                border: 1px solid #efeefb;
                margin: 0 10px 0 0;
                width: 50px;
                height: 50px;
                text-align: center;
                outline: none !important;
                background: #f6f6f6;

                &:focus {
                    border: 1px solid #342bc2 !important;
                }
            }

            .otpInput {
                padding-bottom: 10px;
                padding-top: 10px;

                ~p {
                    text-align: right;
                    color: #000;
                    font-size: 13px;
                    font-weight: 400;
                    max-width: 350px;
                    margin-bottom: 45px;

                    .resend-code {
                        color: rgb(52, 43, 194);
                        text-decoration: none;
                        cursor: pointer;
                    }

                    ~button {
                        min-width: 160px !important;
                    }
                }
            }
        }

    }

    .verify-success {
        input {
            position: relative;
        }

        .verify-icon {
            position: absolute;
            right: 18px;
            top: 12px;
        }

        p {
            color: #9CA0A9;
            font-size: 13px;
            padding: 20px 0 0;
            font-weight: 400;
            margin-bottom: 0px;
        }
    }

    .password-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;
        margin: 40px 0;

        .change-pwd {
            button {
                min-width: 160px !important;
                font-size: 14px;
            }
        }

        .form-group {
            margin-bottom: 20px;
        }

        .update-pwd {
            .change-pwd {
                padding-top: 30px;
            }

            .btn-forgot-pwd {
                color: #342bc2;
                border: none;
                background-color: #ffffff;
                font-size: 14px;
                height: 50px;
            }
        }

        .enterOtp {
            .input-wrap.otpInput input {
                border: 1px solid #efeefb;
                margin: 0 10px 0 0;
                width: 50px;
                height: 50px;
                text-align: center;
                outline: none !important;
                background: #ffffff;

                &:not(:placeholder-shown) {
                    background-color: rgba(52, 43, 194, 0.05);
                }

                &::placeholder {
                    color: #ffffff;
                    opacity: 1;
                }

                &:focus {
                    border: 1px solid #342bc2 !important;
                }
            }

            .otpInput {
                padding-bottom: 10px;
                padding-top: 10px;

                ~p {
                    text-align: right;
                    color: #5E5E5E;
                    font-size: 13px;
                    font-weight: 400;
                    max-width: 350px;
                    margin-bottom: 45px;

                    .resend-code {
                        color: rgb(52, 43, 194);
                        text-decoration: none;
                        cursor: pointer;
                    }

                    ~button {
                        min-width: 160px !important;
                    }
                }
            }
        }
    }

    .security-block {
        border: 1px solid #e6e7e9;
        background: #ffffff;
        padding: 40px 40px;
        margin: 0 0 40px 0;

        .enable-mfa {
                min-width: 160px;
                height: 50px;
                background-color: #342bc2;
                border-radius: 2px;
                border: 1px solid #342bc2;
        
                &:hover {
                    background-color: #272092;
                    border: 1px solid #272092;
                }
        }

      .mfa-disable-header {
        
         .mfa-disable-header-icon {
            
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            column-gap: 10px;
            margin:0;
                h4 {
                    font-size: 18px;
                    color: #000000;
                    margin-top: 10px;
                }
            
        }
        
        p {
            font-size: 14px;
            color: #5E5E5E;
        }
      }
       

        .disable-mfa {
            min-width: 160px;
            height: 50px;
            background-color: #BE1931;
            border-radius: 2px;
            border: 1px solid #BE1931;
            margin-top: 3%;

            &:hover {
                background-color: #BE1931;
                border: 1px solid #BE1931;
            }
        }

        .submit-btn-mfa {
            margin-top: 2%;

            button {
                min-width: 160px;
            }
        }
    }

    @media screen and (min-width : 1279px) {
        .verify-btn {
            button {
                height: 45px !important;
            }
        }
    }

    @media screen and (min-width : 1365px) {
        .verify-btn {
            button {
                height: 45px !important;
            }
        }
    }
}

//mfa style from login page

.form-wrapper {

    #mfa-code-box {
        font-size: 14px;
        margin-top: 5px;
    }
}

.mfa-recovery-link {

    font-size: 14px;
    padding: 5px 0 10px 0;
    p {
        margin:0 0 5px 0;
    }

    span {
        list-style: none;
        color: #342BC2;
        text-decoration:none;
        line-height: 10px;
    }
}

.mfa-error-state {
    p {
        font-size: 14px;
        color: #EF4444;
    }
}

.mfa-login-verify-btn {
    margin-top: 8%;
}

.mfa-container {
    // margin-bottom:12%;
    .mfa-popup {
        padding:0;
        margin: 0;

        .header {
            // display: block !important;
            display: flex;
            align-items: flex-end;
            margin-top:20px;
            justify-content: space-between;
            padding-left:25px;
            padding-right:25px;

            h4 {
                font-size: 16px;
                margin-bottom: 0px;
            }

            .closes-button {
                text-align: right;
            }
        }

        .info-blocks {
            padding-left:25px;
            padding-right:25px;

            h4{
              font-size: 16px;
              font-weight: 500;  
            }

            .auth-para {
                font-size: 14px;
                color: #9CA0A9;
                margin:0;
            }

            .auth-tooltip {
                display: flex;
                align-items: center;
                gap: 10px;
            
                .tooltip-content {
                    background-color: #9CA0A9;
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    color: rgba(255, 255, 255, 1);
                    
                    font-size: 12px;
                    line-height: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
             #dashboard-tooltip{
                margin-top:8px;
             }
            }

            .qr-container {
                border: 1px solid #E6E7E9;
                max-width:100%;
                height: auto;
                margin-top: 5%;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                padding:20px 30px 20px 30px;
                row-gap: 20px;

                .qr-code {
                    background-position: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .qr-img {
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 50%;
                        height:50%;
                    }
                    
                }
                .qr-key {
                    cursor: pointer;
                    p{
                        text-align: center;
                        font-size: 14px;
                        color: #342BC2;
                        font-weight: 500;
                    }
                }
            }

            .qr-verification {
                
                    background-color: white;
                    margin-top: 3%;
                
                
                h2 {
                    margin-bottom: 20px;
                    font-size: 1.5em;
                    color: #333;
                }

                .qr-input {
                    font-size: 14px;
                    padding-bottom: 5px;
                }
                
                .verification-input {
                    width: 100%;
                    padding: 10px;
                    font-size: 14px;
                    border: 1px solid #E6E7E9;
                    outline: none;
                }

                .disable-qr-wrap {

                    #qrhideicon {
                        position: absolute;
                        right:8%;
                        margin-top: 2%;
                    }

                    .disable-qr-input {
                        width:100%;
                    }

                }
                
                .verification-input::placeholder {
                    color: #aaa;
                }

                .qr-verify-btn-wrapper {
                    float: right;
                    padding:20px 0 20px 0;

                    .qr-verify-btn {
                            min-width: 98px;
                            height: 35px;
                            border-radius: 0;
                            background-color: #342bc2;
                            border: 1px solid #342bc2;
                            font-size: 12px;
                    
                            &:hover {
                                background-color: #272092;
                                border: 1px solid #272092;
                            }
                }
             }
                
            }
            
        }

        .recovery-blocks {
            padding-left:30px;
            padding-right:30px;

            .recovery-header {
                
                h4 {
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            .recovery-para {
                font-size: 14px;
                color: #9CA0A9;
                margin:0;
            }

            .recovery-code {
                font-size: 14px;
                padding: 15px 0 15px 0;

                .code-link {
                    color: #342BC2; 
                }
            }

            .generate-code {
                display: flex;
                flex-wrap: wrap;
                width:100%;
                height:auto;
                background-color: #342BC20D;
                padding: 20px;
                justify-content: center;
                row-gap: 10px;
                column-gap: 50px;
                padding:20px 50px 20px 50px;

                .code-para {
                    font-size: 14px;
                    color: #000;
                }
            }

            .copy-clipboard-wrap {
                float: right;
                padding:15px 0 15px 0;
                margin-top: 10px;
                .copy-clip-btn {
                        min-width: 150px;
                        height: 35px;
                        border-radius: 0;
                        background-color: #342bc2;
                        border: 1px solid #342bc2;
                        font-size: 12px;
                
                        &:hover {
                            background-color: #272092;
                            border: 1px solid #272092;
                        }
            }
         }


        }
    }
}

.component-tooltip-container {
    .tooltip-wrapper {
        position: relative;
        text-align: center;
        cursor: default;
    }

    .tooltip-wrapper .tooltip {
        background: #222;
        bottom: 100%;
        color: #fff;
        display: block;
        margin-bottom: 15px;
        opacity: 0;
        padding: 10px;
        pointer-events: none;
        position: absolute;
        width: 300px;
        border-radius: 2px;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
        -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
        -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    }

    .tooltip-wrapper .tooltip:before {
        bottom: -20px;
        content: " ";
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .tooltip-wrapper .tooltip:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid #222 10px;
        bottom: -10px;
        content: " ";
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    .tooltip-wrapper:hover .tooltip {
        opacity: 1;
        pointer-events: auto;
    }

    /* IE can just show/hide with no transition */
    .lte8 .tooltip-wrapper .tooltip {
        display: none;
    }

    .lte8 .tooltip-wrapper:hover .tooltip {
        display: block;
    }
}

.close-button {
    position: absolute;
    right: 15px;
    top: 10px;

    button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;

        &:hover {
            color: #000;
            background-color: #e9ecef;
            border: 1px solid #e9ecef;
        }
    }
}

@media screen and (max-width : 1120px) {
    .profile-wrapper .general-block .submit-btn-block button {
        width: 50% !important;
    }

    .profile-wrapper .general-block .update-profile-image .upload {
        width: 80px;
    }

    .profile-wrapper .general-block .update-profile-image span .remove {
        width: 80px;
    }

    .profile-wrapper .security-block .submit-btn-mfa button{
        width: 50% !important;
    }
}

.deactivate-account {
    .forgot-pwd {
        margin-top: -10px;

        .btn-forgot-pwd {
            color: #342bc2;
            border: none;
            background-color: #ffffff;
            font-size: 14px;
            height: 50px;
        }
    }

    .footer-deactivate {
        padding: 15px 0;

        p.tab-header {
            font-size: 13px;
            color: #9ca0a9;
        }
    }
}

.deactivate-success {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    h4 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    img {
        width: 40%;
    }
}

.reactivate {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    h4 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    img {
        width: 20%;
    }
}

.account-tip-note {
    &.tip-note {
      color: var(--text-secondary-color);
      text-align: left !important;
      font-size: 12px;
      padding: 10px 15px 10px 48px;
      position: relative;
      line-height: 19.8px;
      margin: 0 0 25px 0 !important;
  
      strong {
        color: var(--text-primary-color);
        white-space: nowrap;
      }
  
      &.note {
        border: 1px solid var(--warning-orange);
        background-color: var(--warning-light-orange);
  
        .learn-more {
          text-decoration: underline;
          color: var(--text-primary-color);
          text-decoration-color: var(--text-primary-color);
        }
  
        &::before {
          content: "";
          background-image: url('../assests/icons/note.svg');
          background-size: 16px 16px;
          background-repeat: no-repeat;
          min-width: 16px;
          min-height: 16px;
          position: absolute;
          top: 37%;
          left: 3.3%;
        }
      }
    }
  }