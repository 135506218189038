.darkmode-activated {
    background: #000000;

    .sidebar .layout-sidebar {
        background: #000000 !important;
    }

    .dashboard-section .form-header {
        background: #000000;
    }

    h2,
    h1,
    h3,
    h4,
    h5,
    span,
    p,
    td,
    a,
    li,
    th,
    label {
        color: #ffffff !important;
    }

    .container-image {
        background: #000000 !important;
    }

    .login-container-width {
        background: #000000 !important;
    }

    .fixed-sidebar {
        background: #000000 !important;
    }

    .blank-layout {
        background: #000000 !important;
    }

    .builder-wrapper {
        background: #000000 !important;
    }

    .sticky-header {
        background: #000000 !important;
    }

    .sidebar-wrapper .tools-tabs {
        background: #000000 !important;
    }

    .no-results {
        background: #000000 !important;
    }

    .modal-content {
        background: #000000 !important;
    }

    .search-form-block {
        background: #000000 !important;
    }

    .templates-header {
        background-color: #000000 !important;
    }

    .general-bloc {
        background: #000000 !important;
    }

    .profle-header {
        background: #000000 !important;
    }

    ::-webkit-input-placeholder {
        color: #ffffff;
    }

    :-moz-placeholder {
        color: #ffffff;
        opacity: 1;
    }

    ::-moz-placeholder {
        color: #ffffff;
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: #ffffff;
    }

    ::-ms-input-placeholder {
        color: #ffffff;
    }

    ::placeholder {
        color: #ffffff;
    }
}