.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: -4px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border: 1.5px solid #342bc2;
}

.react-flow {
  z-index: 1 !important;
}

.layout-wrapper {
  background: #f6f6f6;
  padding: 0 0 0 50px;
  overflow: auto;
}

.overflowElement {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  justify-content: center;

  .title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.date-format-dropdown {
  p {
    padding: 0 0 0 8;
    margin: 0;
  }

  .dropdown-item:hover::after {
    display: none;
    content: "" !important;
  }
}

.popup-barchart-range {
  canvas {
    width: 100% !important;
    height: 20px !important;
  }
}

.popup-heirarchical-chart {
  canvas {
    width: 100px !important;
    height: 20px !important;
  }
}

.fixed {
  position: fixed !important;
}

.canvas-wrapper {
  display: flex;
  overflow: hidden;
  background-color: #f2f2f2;

  .builder-sidebar {
    max-width: 312px;
    flex: 0 0 auto;
    z-index: 999;
    order: -1;
  }

  .builder-canvas {
    overflow: auto;

    .layout-wrappers {
      margin: 0px 20px 50px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .side-wrapper {
    flex-basis: 312px;
    background-color: #ffffff;
  }

  .canvas-space {
    position: relative;
    margin: 0px 20px 60px 20px;
    flex-basis: calc(100% - 312px);
    display: flex;
    align-items: center;
    overflow: auto;

    .layout-wrappers {
      margin: auto;
      display: inline-block;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

.chart-div canvas {
  width: 100% !important;
  height: 100% !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;

  .undoRedo {
    button {
      margin-right: 10px !important;
      height: 35px !important;
      background-color: transparent !important;
      border: 1px solid #d7d7d7 !important;
      padding-top: 4px !important;
    }
  }

  .zoom {
    button {
      height: 35px !important;
      padding-top: 4px;
      border: none !important;
      font-size: 18px;
      font-weight: 500;
      border-radius: 0px !important;
    }

    .input-style {
      height: 35px !important;
      text-align: center;
      border: 1px solid #ffffff !important;
      background-color: #ffffff !important;
      width: 30% !important;
      font-size: 14px;

      &:focus {
        outline: none !important;
      }
    }
  }

  .guide {
    button {
      border: solid 1px #342bc2 !important;
      background-color: rgba(52, 43, 194, 0.08) !important;
      height: 35px !important;
      font-size: 14px !important;
    }
  }
}

.kpis {
  &.variant-one {
    text-align: left;
    font-family: "Roboto";
    text-align: center;

    h6 {
      font-size: 12px;
      font-weight: 500;
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
      padding: 0px 0 0px 0;
      color: #007db7;
    }

    p {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 2px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding-left: 10px;
      font-weight: 800;
    }

    h5 {
      padding: 30px 0;
      font-size: 12px;
    }

    span.prev {
      font-size: 0.9rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
    }

    span.prev-value {
      font-size: 0.9rem;
      color: #9ca0a9;
      letter-spacing: 1px;
    }
  }

  &.variant-three {
    text-align: center;

    h3 {
      font-size: 1rem;
      padding-bottom: 7px;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  &.variant-two {
    h3 {
      font-size: 1rem;
      padding-bottom: 20px;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 1.5rem;
      font-weight: 700;
      letter-spacing: 1px;
      color: #ffffff;
      padding: 5px;
      background-color: #93bf35;
    }
  }

  &.variant-four {
    text-align: left;

    h3 {
      font-size: 0.8rem;
      font-weight: 500;
      padding: 3px 5px 3px;
      margin: 0 auto 5px 0;
      line-height: 1.4;
    }

    p {
      font-size: 1.15rem;
      font-weight: 700;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding: 0px 5px 0;
      line-height: 23px;
      color: #060606;
    }

    .previous-div {
      padding: 0 5px 0 5px;
      margin-top: -2px;
    }

    span.prev {
      font-size: 0.7rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
      padding: 0px 2px 0 0;
    }

    span.prev-value {
      font-size: 0.7rem;
      color: #9ca0a9;
      letter-spacing: 1px;
      padding: 0px 1px 0;
    }
  }

  &.kpiBar {
    padding: 7px 5px 0;

    .row-bar {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      line-height: 0px !important;

      .label {
        font-size: 0.6rem;
        font-family: "Roboto";
        width: 50px;
        font-weight: 400;
        color: #9ca0a9;
        margin: 0 0px 10px 0;
        text-align: left;
      }

      .two-bars {
        display: flex;
        justify-content: flex-start;
      }

      .bars {
        width: 65%;
        // margin: 0px 12px 0 15px;
        height: 8px;
        background-color: inherit;
      }

      .values {
        font-size: 0.6rem;
        font-family: "Roboto";
        font-weight: 500;
        color: #060606;
        margin: 0;
        padding: 0 10px;
      }

      &.second {
        .bars {
          // margin: 0px 10px 0 10px;
          width: 96%;
          height: 8px;
          background-color: inherit;
        }
      }
    }
  }

  &.kpi-area {
    margin-top: -2px;

    canvas {
      height: 100% !important;
    }
  }

  &.variant-five {
    text-align: left;
    padding: 2px 6px;
    // display: flex;
    align-items: center;
    position: relative;

    .vertical-line {
      border-left: 4px solid;
      border-color: green;
      height: 100%;
      margin-right: 5px;
      position: absolute;
      top: 0;
    }

    h3 {
      font-size: 0.75rem;
      font-weight: 500;
      padding: 2px 2px 0 10px;
      margin-bottom: 4px;
    }

    p {
      font-size: 1.15rem;
      font-weight: 700;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding: 0px 2px 0 10px;
      line-height: 23px;
      color: #060606;
    }

    .previous-div {
      margin-top: -4px;
      padding: 0 0 0 10px;
    }

    span.prev {
      font-size: 0.7rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
      padding: 0px 0px 0;
    }

    span.prev-value {
      font-size: 0.7rem;
      color: #9ca0a9;
      letter-spacing: 1px;
      padding: 0px 2px 0;
    }
  }

  &.variant-six {
    text-align: center;
    align-items: center;

    .horizontal-line {
      border-bottom: 3px solid;
      border-color: green;
      width: 100%;
      position: absolute;
      bottom: 0px;
    }

    h3 {
      font-size: 0.8rem;
      font-weight: 500;
      padding: 0px 2px 0;
      margin: 0 auto 6px;
    }

    p {
      font-size: 1.15rem;
      font-weight: 700;
      margin-bottom: -5px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding: 0px 2px 0;
      line-height: 23px;
    }

    .previous-div {
      padding: 0 0 0 0px;
      margin-top: -2px;
    }

    span.prev {
      font-size: 0.7rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
      padding: 0px 2px 0;
    }

    span.prev-value {
      font-size: 0.7rem;
      color: #9ca0a9;
      letter-spacing: 1px;
      padding: 0px 2px 0;
    }
  }

  &.variant-seven {
    text-align: left;

    h3 {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      font-weight: 500;
      padding: 3px 0px 0px;
      margin: 0;

      &.dollar::before {
        content: "$";
        width: 14px;
        height: 14px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 9px;
        margin-right: 10px;
        font-weight: 500;
        color: #060606;
        border: 1px solid inherit;
        background-color: #ffffff;
      }
    }

    .icon-img {
      max-height: 15px !important;
      max-width: 15px !important;
    }

    .title-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 10px 0px 5px;

      img {
        margin: 0 3px;
      }
    }

    p {
      font-size: 1.15rem;
      font-weight: 700;
      margin-bottom: -5px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding: 0px 7px 0 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        margin-left: 10px;
        margin-top: -2px;
      }
    }

    .previous-div {
      padding: 3px 10px 0 10px;
    }

    span.prev {
      font-size: 0.7rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
      padding: 0px 2px 0 0px;
    }

    span.prev-value {
      font-size: 0.7rem;
      color: #9ca0a9;
      letter-spacing: 1px;
      padding: 0px 2px 0;
    }
  }

  &.variant-eight {
    text-align: center;

    h3 {
      font-size: 0.8rem;
      font-weight: 500;
      padding: 2px 10px 2px;
      margin-bottom: 0px;
      border-bottom: 1px solid;
      border-color: var(--pseudo-backgroundcolor);
    }

    p {
      font-size: 1.15rem;
      font-weight: 700;
      margin-bottom: -5px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 1px;
      padding: 0px 10px 0;
      line-height: 20px;
      margin-top: 5px;

      span {
        margin-left: 10px;
      }
    }

    .previous-div {
      padding: 0 7px 0 0px;
    }

    span.prev {
      font-size: 0.7rem;
      color: #9ca0a9;
      margin-top: -4px;
      margin-bottom: -3px;
      padding: 0px 2px 0 10px;
    }

    span.prev-value {
      font-size: 0.7rem;
      color: #9ca0a9;
      letter-spacing: 1px;
      padding: 0px 2px 0;
    }

    .overlappedbar {
      display: flex;
      height: 0.75rem;
      align-items: center;
      width: 100%;
      position: relative;
      height: 23px;
      padding: 0rem 1rem;
    }

    .outer {
      background: #e3e3e3;
      height: 0.85rem;
    }

    .inner-bar {
      height: 0.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow-x: hidden;
    }
  }

  &.variant-nine {
    padding: 10px;

    .value-block {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0px 0;

      .value {
        display: flex;
        align-items: center;
        padding: 0px;
        // flex-direction: column;
        gap: 15px;

        .data-values {
          color: var(--text-primary-color);
          font-weight: 500;
          font-size: 1.15rem;
        }
      }

      .separator {
        width: 1px;
        height: 73px;
        background-color: var(--border-color);
      }
    }
  }
}

.datatable {
  padding: 10px 10px 10px;
  height: 100%;
  margin: 0;
  flex-shrink: 0;
  display: flex;
  flex-flow: column;

  .header-chart {
    padding: 0px 0px 10px 0px;
  }

  h5 {
    padding: 0px 0 15px;
    color: #5d5d5d;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  tbody,
  td,
  tfoot,
  th,
  tr {
    border-width: 1px !important;
    border-color: #d7d7d7 !important;
  }

  .resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 7px;
    right: 0;
    top: 0;
    z-index: 1;
    border-right: 2px solid transparent;
  }

  .resize-handle:hover {
    border-color: #ccc;
  }

  .resize-handle.active {
    border-color: #517ea5;
  }

  #resizeMe {
    min-height: 1.2em;
  }

  .table th {
    position: relative;
  }

  .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
  }

  .resizer:hover,
  .resizing {
    border-right: 2px solid rgb(52, 43, 194);
  }

  .resizable {
    border: 1px solid gray;
    width: 50px;
    position: relative;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;
  }

  .table-responsive-y {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overflow-y: none;
    cursor: pointer;
  }

  .table {
    border: 1px solid #d7d7d7;
    padding: 2px;
    border-collapse: collapse;
    border-spacing: 0px;
    margin-bottom: 1px;
  }

  thead {
    position: sticky;
    top: -2px;
    z-index: 99;
    padding: 10px 0 10px;
    background-color: white;

    th {
      background-color: #ffffff;
      color: #eb1717;
      font-size: 16px;
      font-weight: 600;
      padding: 0 !important;
      outline: 0 !important;
    }
  }

  tbody {
    td.barChart {
      .same-td-block {
        justify-content: flex-start;

        &.positive {
          flex-direction: row-reverse;
        }
      }

      input {
        width: 25%;
      }
    }

    td.range-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: none;

      &.positive {
        flex-direction: row-reverse;
      }
    }

    td {
      font-size: 13px;
      color: #8b9097;
      padding: 0 !important;
      overflow: hidden;
      margin: 0px;
      vertical-align: middle;
      outline: 0 !important;
    }

    td {
      canvas {
        height: 75px !important;
        width: 50% !important;
        display: inline-table !important;
      }
      .table-line-style {
        width: 90% !important;
      }
    }

    td.barChart {
      canvas {
        height: 35px !important;
        width: 50% !important;
        display: inline-table !important;
      }
    }

    td.barChartRange {
      canvas {
        width: 100% !important;
      }
    }
  }

  .table-input {
    height: 33px !important;
    border: none;
    // width: 100%;
    outline: none;
    color: #060606;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: transparent;
    // width: 100% !important;

    &:hover {
      border: none;
      outline: none !important;
      color: black;
    }

    &:focus {
      outline: none !important;
      border: none !important;
      background: #efeffb !important;
      border: 1px solid #3238c7 !important;
      outline: 0 !important;
      color: #060606;
    }
  }

  .table-input-data {
    height: 33px;
    width: 100%;
    border: none;
    text-align: center;
    outline: none;
    color: #060606;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;

    &:hover {
      border: none;
      outline: none !important;
    }

    &:focus {
      outline: none !important;
      border: none !important;
      background: #efeffb !important;
      border: 1px solid #3238c7 !important;
      outline: 0 !important;
      color: #060606;
    }
  }

  .row-banding {
    tr:nth-child(odd) {
      background-color: #ffffff;

      td input.table-input-data {
        background-color: #ffffff;
        background: #ffffff;
      }
    }

    tr:nth-child(even) {
      background-color: #f3f3f3;

      td input.table-input-data {
        background-color: #f3f3f3;
        background: #f3f3f3;
      }
    }
  }

  .parent-banding {
    tbody:nth-child(even) {
      tr:nth-child(odd) {
        background-color: #ffffff;

        td input.table-input-data {
          background-color: #ffffff;
          background: #ffffff;
        }
      }

      tr:nth-child(even) {
        background-color: #f3f3f3;

        td input.table-input-data {
          background-color: #f3f3f3;
          background: #f3f3f3;
        }
      }
    }

    tbody:nth-child(odd) {
      tr:nth-child(odd) {
        background-color: #ffffff;

        td input.table-input-data {
          background-color: #ffffff;
          background: #ffffff;
        }
      }

      tr:nth-child(even) {
        background-color: #f3f3f3;

        td input.table-input-data {
          background-color: #f3f3f3;
          background: #f3f3f3;
        }
      }
    }
  }

  .parent i {
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin: -0.5rem;
    padding: 0.5rem;
    font-size: 10px;
  }

  .open .parent i {
    transform: rotate(180deg);
  }

  .parent ~ .cchild {
    display: none;
  }

  .open .parent ~ .cchild {
    display: table-row;
  }

  .parent {
    cursor: pointer;
  }

  .parent i {
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin: -0.5rem;
    padding: 0.5rem;
  }

  .open .parent i {
    transform: rotate(180deg);
  }

  .collapse-table {
    tr.parent {
      td {
        position: relative;

        .table-input-data {
          font-weight: 500;
        }

        .indicator {
          display: flex;
          // justify-content: center;
          align-items: center;
          // position: fixed;
          padding-left: 8px;
          // top: 0%;
          // left: 7%;

          input {
            text-align: left;
          }

          i {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.layout-grid {
  font-family: "Roboto";
  z-index: -1 !important;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  .custom-div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;
  }

  &:hover {
    border: 1px solid #342bc2 !important;

    .more-options-div {
      display: block;
      z-index: 9999 !important;

      &:hover {
        display: block;
      }
    }
  }
}

.noBorder {
  &:hover {
    border: 1px solid #e8e8e8 !important;
  }
}

.more-options-div {
  font-family: "Rubik", sans-serif;
  display: none;
  position: absolute;
  right: -24px;
  z-index: 999995 !important;
  top: -1px;

  .dropdown-btn {
    background-color: #ffffff;
    border-left: none;
    border-bottom: 1px solid #342bc2;
    border-radius: 2px;
    border-right: 1px solid #342bc2;
    border-left: 1px solid #342bc2;
    border-top: 1px solid #342bc2;
    padding: 0 2px 0 2px;

    img {
      width: 90%;
    }

    &:hover {
      background-color: #ffffff;
      border-left: none;
      border-bottom: 1px solid #342bc2;
      border-radius: 2px;
      border-left: 1px solid #342bc2;
      border-right: 1px solid #342bc2;
      border-top: 1px solid #342bc2;
      display: block;
      z-index: 9999 !important;
    }
  }

  .dropdown-menu {
    z-index: 999 !important;
    transform: translate3d(-143px, 28px, 10px) !important;
  }

  .dropdown-btn[aria-expanded="true"] {
    background-color: #ffffff;
    border-left: none;
    border-bottom: 1px solid #342bc2;
    border-radius: 2px;
    border-right: 1px solid #342bc2;
    border-top: 1px solid #342bc2;
  }
}

.dropdowns {
  text-align: left;
  padding: 5px;

  h4 {
    font-size: 0.85rem;
    line-height: 20px;
    font-weight: 400;
    // padding-right: 10px;
    // margin-bottom: 0.35rem;
    margin: 0 auto 0.35rem 0;
  }

  .div-enclose {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dropdown-btn {
    font-family: "Roboto";
    background-color: #ffffff;
    border: 1px solid #d7d7d7;
    color: #060606;
    padding: 2px 8px;
    border-radius: 2px;
    height: 25px;
    text-align: left;
    width: 100%;
    font-size: 0.8rem;

    p.item-name-selected {
      // width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      margin-right: 10px;
    }

    &::after {
      color: #9ca0a9;
      vertical-align: middle;
      margin: -11px 0;
      float: right;
    }
  }

  .dropdown-btn[aria-expanded="true"] {
    background-color: #ffffff;
    border: 1px solid #d7d7d7;
    color: #060606;
    font-size: 0.8rem;
  }

  .dropdown-item {
    margin: 0 10px 0 2px !important;
    padding: 0.25rem 0.5rem;

    &.only-values:hover {
      background-color: #ffffff;
      color: #060606;
      border: 1px solid transparent;

      &::after {
        content: "" !important;
      }
    }
  }

  .dropdown-menu {
    box-shadow: 2px 0px 10px 0px #e6e7e9 !important;
    width: 100%;
    font-size: 0.8rem;
    padding: 10px 5px 10px 0 !important;
    transform: translate3d(0px, 25px, 0px) !important;

    p.item-name {
      margin: 0 !important;
      display: contents;
      width: 90%;
      text-overflow: clip;
      overflow: hidden;
      white-space: break-spaces;
      display: contents;
      word-break: break-all;
    }
  }

  &.variant-two {
    text-align: left;
    justify-content: space-between;
    align-items: center;
    padding: 5px;

    .dropdown-btn {
      margin-top: 10px;
      font-size: 0.95rem;
      text-align: center;
    }

    h4 {
      font-size: 0.85rem;
    }

    &.checkboxs {
      align-items: flex-start;
    }
  }

  &.alignment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    flex-direction: row;
    gap: 10px;
  }

  .dropdown-btn.dropdown-toggle {
    span {
      display: contents;
    }

    p.bullet-circle {
      margin: 5px 7px 5px 2px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    p.bullet-square {
      margin: 5px 7px 5px 2px;
      width: 10px;
      height: 10px;
      // border-radius: 50%;
    }

    span.bullet-circle::before {
      content: "";
      background-color: inherit;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    span.bullet-square::before {
      content: "";
      background-color: inherit;
      width: 10px;
      height: 10px;
      border-radius: 0%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    &.bullet-circle-no-background::before {
      content: "";
      background-color: #ffffff;
      border: 1px solid #9ca0a9;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    &.bullet-square-no-background::before {
      content: "";
      background-color: #ffffff;
      border: 1px solid #9ca0a9;
      width: 10px;
      height: 10px;
      border-radius: 0%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }
  }

  .dropdown-item {
    span {
      display: contents;
    }

    span.bullet-circle::before {
      content: "";
      background-color: inherit;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    span.bullet-square::before {
      content: "";
      background-color: inherit;
      width: 10px;
      height: 10px;
      border-radius: 0%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    &.bullet-circle-no-background::before {
      content: "";
      background-color: #ffffff;
      border: 1px solid #9ca0a9;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }

    &.bullet-square-no-background::before {
      content: "";
      background-color: #ffffff;
      border: 1px solid #9ca0a9;
      width: 10px;
      height: 10px;
      border-radius: 0%;
      display: inline-block;
      vertical-align: middle;
      margin: -1px 7px 0 0px;
    }
  }

  ul {
    padding-inline-start: 4px;
    width: 100%;

    li {
      color: #5c677d;
      font-size: 0.85rem;
      list-style-type: none;

      span {
        display: contents;
      }

      span.bullet-circle::before {
        content: "";
        background-color: inherit;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
      }

      span.bullet-square::before {
        content: "";
        background-color: inherit;
        width: 10px;
        height: 10px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
      }

      &.bullet-circle-background::before {
        content: "";
        background-color: #9ca0a9;
        border: 1px solid #9ca0a9;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
        cursor: pointer;
      }

      &.bullet-square-background::before {
        content: "";
        background-color: #9ca0a9;
        border: 1px solid #9ca0a9;
        width: 10px;
        height: 10px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
        cursor: pointer;
      }

      &.bullet-circle-no-background::before {
        content: "";
        background-color: #ffffff;
        border: 1px solid #9ca0a9;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
        cursor: pointer;
      }

      &.bullet-square-no-background::before {
        content: "";
        background-color: #ffffff;
        border: 1px solid #9ca0a9;
        width: 10px;
        height: 10px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -1px 7px 0 0px;
        cursor: pointer;
      }
    }
  }
}

.filters {
  text-align: center;
  padding: 10px;
  font-family: "Roboto";
  padding: 2px;

  .heading-filter {
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0 10px;
  }

  h4 {
    font-size: 0.85rem;
    color: #ffffff;
    padding-bottom: 0px;
    margin: 10px 0 5px 0;
    font-weight: 400;
    letter-spacing: 0.5px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &.date {
      margin: 0 auto 3px auto;
    }
  }

  .range-inputs {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;

    .text-input {
      text-align: left;
      margin: 0;
      padding: 0 10px;
    }
  }

  .text-input {
    text-align: left;
    margin-bottom: 5px;
    padding: 0px 10px;
    margin-top: 10px;
  }

  input[type="text"] {
    width: 100%;
    height: 25px !important;
    text-align: center;
    border: 1px solid #d7d7d7 !important;
    background-color: #ffffff !important;
    font-size: 14px;
    font-weight: 500;
    caret-color: transparent;
    color: #4b4b4b;
    font-weight: normal;

    &:focus {
      outline: none !important;
      border: 1px solid #d7d7d7 !important;
    }
  }

  input[type="range"] {
    //   text-align: left;
    height: 18px;
    margin: 0 0 10px 0;
    width: 90%;
  }

  input[type="range"]:focus {
    outline: none !important;
    border: none !important;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: #f6f6f6;
    border-radius: 5px;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;
    margin-top: -4px;
  }

  input:not(:placeholder-shown) {
    background-color: #ffffff !important;
    background: #ffffff !important;
  }

  .range-btns {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span {
      border: 1px solid #d7d7d7 !important;
      padding: 0 2px;
      font-size: 12px;
    }
  }

  .date {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 0 7px;

    div {
      padding: 3px 5px;

      h4 {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        margin: 0px 0 5px;
      }

      input[type="text"] {
        width: 100%;
        height: 28px !important;
        text-align: center;
        border: 1px solid #d7d7d7 !important;
        background-color: #ffffff !important;
        font-size: 12px;
        color: #4b4b4b;
        font-weight: 400;

        &:focus {
          outline: none !important;
          border: 1px solid #d7d7d7 !important;
        }
      }
    }
  }
}

.styled-icons {
  padding-left: 24px;
}

.blank-layout {
  background-color: #ffffff;
  padding: 45px 55px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.active {
    border: 1px solid #342bc2 !important;
  }

  h5 {
    font-size: 24px;
    color: rgba(24, 24, 25, 0.9);
  }

  p {
    max-width: 550px;
    margin: 0 auto;
    font-size: 20px;
    color: #5e5e5e;
    padding: 16px 0;
  }

  .gif-div {
    width: 25%;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 20px;
  }

  button {
    min-width: 20% !important;
    margin-bottom: 40px;
  }
}

.filter-details {
  .dropdown-toggle {
    background-color: #ffffff;
    border-color: #e6e7e9;
    color: #5e5e5e;
  }

  .dropdown-item {
    display: flex !important;
  }
}

.main-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .button-container {
    width: 100%;
    padding: 7px;
    gap: 7px;
    height: 100%;

    .button-element {
      border: 1px solid;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow-y: hidden;

      .button-name {
        text-align: center;
        font-size: 13px;
      }
    }
  }
}

// .overflowHidden {
//   overflow: hidden;
//   justify-content: center;
//   align-items: center;
//   height: 100%;

//   .navbtndiv {
//     display: flex;
//     padding: 10px;
//     border-spacing: 10px;

//     .btninside {
//       margin: 6px;
//       overflow: hidden;
//       vertical-align: middle;
//       text-align: center;
//       font-size: 13px;
//       font-weight: 400;
//       line-height: 15px;
//       border: 1px solid;
//       min-width: 80px;
//       max-width: 160px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       padding: 10px 9px;
//       white-space: pre-wrap;
//       word-wrap: break-word;
//       // flex: 1;
//       p {
//         text-align: center;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: -webkit-box;
//         -webkit-box-orient: vertical;
//         max-height: fit-content;
//       }
//     }
//   }
// }

// .navbtndiv {
//   display: flex;
//   padding: 10px;
//   border-spacing: 10px;

//   .btninside {
//     margin: 6px;
//     word-break: break-all;
//     overflow: hidden;
//     vertical-align: middle;
//     text-align: center;
//     font-size: 13px;
//     font-weight: 400;
//     line-height: 12px;
//     border: 1px solid;
//     min-width: 60px;
//     flex: 1;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 10px 9px;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     p {
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       max-width: 100%;
//       max-height: calc(100% - 20px);
//     }
//   }
// }

.navBtns {
  padding: 7px;

  button {
    font-family: "Roboto";
    background: #ffffff;
    border-radius: 2px;
    height: 30px !important;
    color: #5d5d5d;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    line-height: 12px;
    opacity: 1 !important;
    pointer-events: none;
    white-space: normal;
    text-overflow: ellipsis;
  }

  .active-btn {
    color: #007db7;
    border: 1px solid #007db7;
    border-color: #007db7;
  }

  .inactive-btn {
    color: #9d9d9d;
    border: 1px solid #797979;
    border-color: #797979;
  }

  &.variant-two {
    background: #ffffff !important;
    border: none !important;
    text-align: right;

    button {
      font-size: 1rem;
      border-color: transparent !important;
      text-align: right;
    }

    .active-btn {
      color: #007db7;
      border: none !important;
      border-color: transparent !important;
    }

    .inactive-btn {
      color: #9d9d9d;
      border: none !important;
      border-color: transparent !important;
    }

    &.three {
      button {
        font-size: 1.1rem;
        border-color: transparent !important;
      }
    }
  }
}

.title-logo {
  &.header-1 {
    padding: 5px 5px;
    text-align: left;
    height: 100%;
    display: flex;
    font-weight: 400;
    flex-direction: column;
    color: #060606;
    background-color: #ffffff;

    h4 {
      font-size: 1.25rem;
      text-align: left;
      display: flex;
      align-items: center;
      font-weight: 600;
      margin: 0;

      &.dollor::before {
        content: "$";
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-right: 10px;
        font-weight: 500;
        color: #060606 !important;
        border: 1px solid #060606 !important;
        background-color: #ffffff;
      }
    }

    .logo {
      text-align: left;
    }

    .icons {
      margin: 0 48px 0 0;
      text-align: left;
    }

    button.nav-btn-header {
      font-family: "Roboto";
      background: #ffffff;
      border-radius: 2px;
      height: 33px !important;
      color: #5d5d5d;
      font-size: 0.8rem;
      font-weight: 500;
      opacity: 1 !important;
      margin: 0 5px;
      pointer-events: none;
      line-height: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 120px;
      margin-left: 5px;
      margin-right: 0;
      margin-top: -1px;
      min-width: 120px;
    }

    .active-btn {
      color: #007db7 !important;
      border: 1px #007db7;
      border-color: #007db7;
    }

    .nav-menu-block {
      text-align: right;
      flex: none;

      &.dropdowns-block {
        display: flex;

        .header-chart.dropdowns .dropdown-btn::after {
          margin: -10px 2px !important;
        }
      }
    }

    .title-logo-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      max-height: 60px;
      max-width: 120px;

      &.logo-svg {
        width: 120px;
      }
    }

    .info {
      font-size: 14px;
      color: #ffffff;
      // width: 240px;
      max-width: max-content;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;
        background-color: var(--pseudo-backgroundcolor) !important;
        color: #ffffff !important;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }

    p.info {
      font-size: 0.8rem;
      padding: 3.5px 10px;
      margin: 0;
      // margin-top: -3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-inline-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-word;
      line-height: 18.5px;
      color: #ffffff;
      background-color: var(--pseudo-backgroundcolor);

      &.backgrounds {
        opacity: 0.5;
      }
    }

    p.description {
      font-size: 0.8rem;
      padding: 0px 0px;
      margin: 0;
      line-height: 1.2;
    }

    .icon-img {
      max-height: 18px !important;
      max-width: 18px !important;
    }

    .icon-div {
      margin-right: 5px;
      margin-top: -5px;
    }

    .description {
      font-size: 0.7rem;
      padding: 0px 0px;
      margin: 0;

      hr {
        background-color: var(--pseudo-backgroundcolor);
        margin: 0.4rem 0 0.3rem;
      }

      color: #060606;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &.header-2 {
    padding: 5px 5px;
    text-align: left;
    height: 100%;
    display: flex;
    font-weight: 400;
    flex-direction: column;
    color: #060606;
    background-color: #ffffff;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      display: flex;
      align-items: center;

      &.dollor::before {
        content: "$";
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        margin-right: 10px;
        font-weight: 500;
        color: #060606 !important;
        border: 1px solid #060606 !important;
        background-color: #ffffff;
      }
    }

    .logo {
      text-align: left;
    }

    .icons {
      margin: 0 48px 0 0;
      text-align: left;
    }

    .title-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h4 {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      &.borders {
        border-left: 2px solid;
        border-right: 2px solid;
        border-color: #060606;
        padding: 0 7px;
      }
    }

    .icon-div {
      margin-right: 5px;
      margin-top: -4px;
    }

    .icon-img {
      max-height: 18px !important;
      max-width: 18px !important;
    }

    button {
      font-family: "Roboto";
      background: #ffffff;
      border-radius: 0px;
      height: 20px !important;
      color: #5d5d5d;
      font-size: 0.73rem;
      font-weight: 500;
      opacity: 1 !important;
      pointer-events: none;
      white-space: normal;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 !important;
      padding: 0 !important;
      transition: none;
    }

    .active-btn {
      color: #007db7 !important;
      border: 1px solid #007db7;
      border-color: #007db7;
    }

    .nav-menu-block {
      text-align: center;
      flex: none;
      margin-top: 13px;

      button {
        margin-right: 15px !important;
      }
    }

    .title-logo-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .right-block {
      width: 120px;
      text-align: center;
    }

    img {
      max-height: 60px;
      max-width: 120px;

      &.logo-svg {
        width: 120px;
      }
    }

    hr {
      margin: 0.5rem 0;
    }

    .horizontal-line {
      margin-bottom: -10px;
      margin-top: 2px;

      hr {
        margin: 0.3rem 0;
        height: 2px;
      }
    }

    .info {
      font-size: 14px;
      color: #ffffff;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;
        background-color: var(--pseudo-backgroundcolor) !important;
        color: #ffffff !important;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }

    p.info {
      font-size: 0.7rem;
      padding: 6.5px 10px;
      margin: 0;
      margin-top: 1px;

      &.backgrounds {
        opacity: 0.5;
      }
    }

    .description {
      font-size: 0.7rem;
      padding: 0px 0px;
      margin: 0;

      hr {
        background-color: var(--pseudo-backgroundcolor);
        margin: 0.65rem 0 0.45rem;
      }

      color: #060606;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }

  &.header-3 {
    padding: 5px 5px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #060606;
    background-color: #ffffff;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      padding-top: 3px;

      &.borders {
        border-left: 2px solid var(--pseudo-backgroundcolor);
        border-right: 2px solid var(--pseudo-backgroundcolor);
        padding: 0 7px;
      }

      display: flex;
      align-items: center;

      &.dollor::before {
        content: "$";
        width: 20px;
        height: 20px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        margin-right: 10px;
        font-weight: 500;
        color: #060606 !important;
        border: 1px solid #060606 !important;
        background-color: #ffffff;
      }
    }

    .logo {
      text-align: left;
    }

    .icons {
      margin: 0 48px 0 0;
      text-align: left;
    }

    .icon-img {
      max-height: 18px !important;
      max-width: 18px !important;
    }

    button {
      font-family: "Roboto";
      background: #ffffff;
      border-radius: 0px;
      height: 28px !important;
      color: #5d5d5d;
      font-size: 0.73rem;
      font-weight: 500;
      opacity: 1 !important;
      margin: 0 5px;
      pointer-events: none;
      width: 100px;
      border: none !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 5px;
      margin-right: 0;

      &.arrow::after {
        content: " >";
        margin-top: -1px;
      }
    }

    .active-btn {
      color: #007db7 !important;
      border: 1px solid #007db7;
      border-color: #007db7;
    }

    .nav-menu-block {
      text-align: center;
      flex: none;
      margin-top: -1px;
    }

    .title-logo-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    img {
      max-height: 60px;
      max-width: 110px;

      &.logo-svg {
        width: 110px;
      }
    }

    .right-block {
      text-align: center;
    }

    .two-logo-block {
      border-bottom: 1px dashed;
      padding-bottom: 10px;
      border-color: #060606;

      &.background {
        border-bottom: 1px dashed #ffffffab;
      }
    }

    .title-nav-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px dashed var(--pseudo-backgroundcolor);
      padding: 5px 0px 6px;
      border-color: #060606;

      &.background {
        border-bottom: 1px dashed #ffffffab;
      }

      .title-block {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h4 {
          margin: 0;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
    }

    hr {
      margin: 0.5rem 0;
    }

    .info-desc-block {
      // display: flex;
      // align-items: flex-start;
      // flex-direction: column;
      padding: 8px 0px 0;
    }

    .horizontal-line {
      margin-top: 10px;

      hr {
        margin: 0.3rem 0;
      }
    }

    .info {
      font-size: 0.7rem;
      color: #ffffff;
      margin-bottom: 5px;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;
        background-color: var(--pseudo-backgroundcolor) !important;
        color: #ffffff !important;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }

    p.info {
      font-size: 0.85rem;
      color: var(--pseudo-backgroundcolor);
      margin: 0;

      &.backgrounds {
        opacity: 0.5;
      }
    }

    .description {
      font-size: 0.7rem;
      padding: 0px 0px;
      margin: 0;

      hr {
        background-color: var(--pseudo-backgroundcolor);
        margin: 0.2rem 0 0.5rem;
      }

      color: #060606;

      input {
        border: none !important;
        outline: none !important;
        height: 40px;
        padding: 5px;
        width: 100%;

        &:focus-within {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
}

.blocks {
  z-index: auto !important;
  // overflow: hidden;
  background-color: transparent;
}

.blocks:hover {
  // z-index: 999 !important;
}

.groups {
  position: absolute;
  top: 38px;
  left: 111px;
  font-size: 12px;
  font-weight: 100;

  &::after {
    content: "2019";
    color: #3238c7;
  }

  &.third {
    left: 41%;

    &::after {
      content: "2020";
      color: #3238c7;
    }
  }

  &.second {
    left: 80%;

    &::after {
      content: "2021";
      color: #3238c7;
    }
  }
}

.vertical {
  border-left: 1px solid #d9d9d9;
  height: 58px;
  position: absolute;
  left: 177px;
  bottom: 58px;

  &.third {
    left: 65.3%;
  }

  &.second {
    left: 95%;
  }
}

.nivo-title {
  font-family: "Roboto";
  text-align: left;
  display: block;
  padding: 1px 10px 0px;
  font-size: 16px;
  font-weight: 500;
  color: #007db7;

  ~ div {
    padding-bottom: 17px;
  }

  &.geomap {
    padding: 0;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-top: 5px;

    ~ div {
      padding-bottom: 38px;
    }
  }
}

.recharts-title {
  font-family: "Roboto";
  text-align: left;
  display: block;
  padding-left: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #007db7;
  margin-bottom: -17px;
}

.thumbnail {
  margin-bottom: 15px;
}

@media screen and (max-height: 910px) {
  .layout-wrapper {
    margin-left: 335px !important;
  }
}

@media screen and (max-height: 870px) and (min-height: 749px) {
  .layout-wrapper {
    padding: 0 0 0 30px;
    margin-left: 315px !important;
  }
}

@media screen and (max-height: 749px) and (min-height: 0px) {
  .layout-wrapper {
    padding: 0 0 0 30px;
    margin-left: 315px !important;
  }

  .blank-layout {
    .gif-div {
      width: 15% !important;
      text-align: center;
      margin: 0 auto;
      padding: 0 0 20px;
    }

    button {
      min-width: 20% !important;
      margin-bottom: 30px;
      height: 45px !important;
    }

    h5 {
      padding-top: 15px;
    }
  }
}

@media screen and (min-height: 1080px) {
  .layout-wrapper {
    margin-left: 395px;
  }
}

:root {
  --pseudo-backgroundcolor: #007db7;
  --pseudo-secondcolor: #99eaff;
  --pseudo-width: 325px;
  --pseudo-color: #ffffff;
  --pseudo-selection: #0d6efd;
}

.bar-table {
  &::before {
    content: "";
    margin-right: 40px;
    width: 20px;
    height: 15px;
    padding: 3px 85px;
    background-color: var(--pseudo-backgroundcolor);
  }
}

.graph-kpi {
  display: block;
  text-align: left;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-top: 5px solid;
    border-top-color: var(--pseudo-backgroundcolor);
    margin: 0px 0 0 -10px;
    background-color: var(--pseudo-backgroundcolor);
    box-shadow: 0px 0px 0px 0px #007db7;
    border-radius: 50%/100px 100px 0 0;
  }
}

.subtitle {
  h4 {
    font-size: 16px;
    text-align: left;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .active-title {
    color: #007db7;
  }

  .inactive-title {
    color: #5c677d;
  }
}

.active-title {
  color: #007db7;
}

.inactive-title {
  color: #000000;
}

text.recharts-text.recharts-cartesian-axis-tick-value {
  fill: #000000 !important;
}

.recharts-text.recharts-label tspan {
  fill: #ffffff;
}

.recharts-tooltip-wrapper:empty {
  display: "none";
}

.mod {
  position: relative;
  padding-top: 20px !important;

  .customClose {
    position: absolute;
    right: -33px;
    top: -31px;
  }
}

.popupResize {
  max-height: 350px;
  overflow-y: auto;
  text-align: center;

  canvas {
    height: 60px;
    width: 100px !important;
    margin: auto;
  }
}

.popup-table {
  padding: 20px;
  height: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
  overflow: auto;

  .parent-banding {
    tbody:nth-child(even) {
      tr {
        background-color: #ffffff;

        td input.table-input-data {
          background-color: #ffffff;
          background: #ffffff;
        }
      }
    }

    tbody:nth-child(odd) {
      tr {
        background-color: #f3f3f3;

        td input.table-input-data {
          background-color: #f3f3f3;
          background: #f3f3f3;
        }
      }
    }
  }

  .parent i {
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin: -0.5rem;
  }

  .open .parent i {
    transform: rotate(180deg);
  }

  .parent ~ .cchild {
    display: none;
  }

  .open .parent ~ .cchild {
    display: table-row;
  }

  .parent {
    cursor: pointer;
  }

  .parent i {
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin: -0.5rem;
    padding: 0.5rem;
  }

  .open .parent i {
    transform: rotate(180deg);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .chart-dropdowns {
      display: flex;
      justify-content: space-between;
      column-gap: 15px;
    }

    h5 {
      padding: 0px 0 15px;
      color: #5d5d5d;
      text-align: left;
      font-size: 16px;
      font-weight: 700;
    }

    .closes-button {
      button {
        height: 30px !important;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
        color: #ffffff;
        background-color: #000000;
        border: 1px solid #000000;

        &:hover {
          color: #000000;
          background-color: #e9ecef;
          border: 1px solid #e9ecef;
          font-weight: 900;
        }
      }
    }
  }

  h5 {
    padding: 0px 0 15px;
    color: #5d5d5d;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  tbody,
  td,
  tfoot,
  thead,
  th,
  tr {
    border-width: 1px !important;
    border-color: #d7d7d7 !important;
  }

  .table {
    border: 1px solid #d7d7d7;
    padding: 2px;
  }

  thead {
    position: sticky;
    top: -2px;
    z-index: 99;
    padding: 10px 0 10px;
    background-color: white;
    border-bottom: 1.15px solid #007db7 !important;

    th {
      background-color: #ffffff;
      color: #007db7;
      font-size: 16px;
      font-weight: 500;
      vertical-align: middle;
      text-align: start;
      padding: 5px;
    }
  }

  tbody {
    td {
      font-size: 13px;
      color: #000000;
      padding: 10px;
      text-align: start;

      vertical-align: middle;
    }
  }

  .row-banding {
    tr:nth-child(odd) {
      background-color: #ffffff;

      td input.table-input-data {
        background-color: #ffffff !important;
        background: #ffffff !important;
      }
    }

    tr:nth-child(even) {
      background-color: #f3f3f3;

      td input.table-input-data {
        background-color: #f3f3f3 !important;
        background: #f3f3f3 !important;
      }
    }
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: #241e85 !important;
}

.react-resizable-handle {
  display: none;
  cursor: none;
}

.logo-block {
  padding: 0px 20px;
  margin-bottom: 25px;
}

.react-datepicker-popper {
  z-index: 99999 !important;
}

.img-wraapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  .logo-header {
    display: flex;
    justify-content: space-between;
    width: 58%;
    align-items: center;
    gap: 15px;
  }
}

.header-chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 5px 10px;
  flex-wrap: wrap;
  // gap: 5px;

  // .chart-title, .extra-div-experiment, .chart-dropdowns{
  //   width: 33.3%;
  // }

  &:empty {
    padding: 0;
  }

  &.flex-chart {
    flex-direction: row-reverse;
    justify-content: center;

    .chart-dropdowns {
      margin-right: 10px !important;
    }
  }

  &.center-chart {
    justify-content: center;
  }

  h4 {
    font-family: "Roboto";
    font-size: 0.85rem;
    font-weight: 500;
    margin: 0;
    white-space: pre-wrap;
    padding: 0;
    color: var(--pseudo-backgroundcolor);
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .chart-dropdowns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 15px;
  }

  &.dropdowns {
    text-align: left;

    h4 {
      font-size: 0.8rem;
      font-weight: 400;
    }

    .dropdown-btn {
      font-family: "Roboto";
      background-color: #ffffff;
      border: 1px solid #d7d7d7;
      color: #5c677d;
      padding: 2px 8px;
      border-radius: 2px;
      height: 23px;
      min-width: 80px;
      text-align: left;
      margin: 0;
      font-size: 0.7rem;

      &::after {
        color: #9ca0a9;
        vertical-align: middle;
        margin: 7px 2px;
        float: right;
      }
    }

    .dropdown-btn[aria-expanded="true"] {
      background-color: #ffffff;
      border: 1px solid #d7d7d7;
      color: #060606;
      font-size: 0.8rem;
    }

    .dropdown-item {
      margin: 0 10px 0 2px !important;
      padding: 0.25rem 0.5rem;

      &.only-values:hover {
        background-color: #ffffff !important;
        color: #060606 !important;

        &::after {
          content: "" !important;
        }
      }
    }

    .dropdown-menu {
      box-shadow: 2px 0px 10px 0px #e6e7e9 !important;
      width: 100%;
      font-size: 0.8rem;
    }

    &.variant-two {
      text-align: left;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      .dropdown-btn {
        font-size: 0.75rem;
        text-align: center;
      }

      h4 {
        font-size: 0.75rem;
        white-space: pre !important;
      }

      .dropdown-menu {
        width: inherit;
        min-width: inherit;
      }

      .dropdown-item {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
      }

      button.toggles {
        font-family: "Roboto";
        background-color: #ffffff;
        border: 1px solid #d7d7d7;
        color: #5c677d;
        padding: 2px 8px;
        border-radius: 2px;
        height: 23px;
        text-align: center;
        margin: 0;
        font-size: 0.75rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &.alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
      flex-wrap: nowrap;
      margin-right: 0 !important;
    }
  }
}

.chart-kpis {
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .kpis.variant-five {
    text-align: left;
    padding: 2px 9px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 0.75rem;
      font-weight: 500;
      padding: 2px 2px 0;
      margin-bottom: 2px;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      span {
        display: contents;
      }

      span.bullets::before {
        content: "";
        background-color: inherit;
        width: 5px;
        height: 5px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 5px 0 0px;
      }
    }

    p {
      font-size: 1.15rem;
      font-weight: 400;
      margin-bottom: -5px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 0px;
      padding: 7px 2px 0 10px;
      line-height: 20px;
      color: #3a3a3a;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      display: -webkit-inline-box;
    }

    &.separator-kpi::after {
      content: "|";
      color: #bababa;
      font-size: 27px;
      font-weight: 100;
      padding: 0 0 0 15px;
      margin: 0 0 0 0;
    }
  }
}

.chart-legends {
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .kpis.variant-five {
    text-align: left;
    padding: 2px 9px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 0.75rem;
      font-weight: 500;
      padding: 2px 10px 0px 2px;
      margin-bottom: 2px;

      span {
        display: contents;
      }

      span.bullets::before {
        content: "";
        background-color: inherit;
        width: 5px;
        height: 5px;
        border-radius: 0%;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 5px 0 0px;
      }
    }

    p {
      font-size: 1.15rem;
      font-weight: 400;
      margin-bottom: -5px;
      margin-block-start: 0em;
      margin-block-end: 0em;
      letter-spacing: 0px;
      padding: 0px 2px 0 10px;
      line-height: 11px;
      color: #3a3a3a;
      display: inline-block;
    }

    &.separator-kpi::after {
      content: "|";
      color: #bababa;
      font-size: 27px;
      font-weight: 100;
      padding: 0 0 0 15px;
      margin: 0 0 0 0;
    }
  }
}

.frameSize {
  width: max-content;
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  cursor: pointer;

  .frameSizeText {
    padding-left: 3px;
    font-weight: 400;
    font-family: Rubik;
    font-size: 12px;
    color: #979797;
    line-height: 16.8px;

    &.active {
      color: #342bc2 !important;
    }
  }

  .frameSizeSvg {
    line-height: 8px;

    svg {
      width: 16px;
      height: 11px;
      fill: #989898;

      &.active {
        fill: #342bc2 !important;
      }
    }
  }
}
.react-flow__pane {
  cursor: default !important;
}

.react-flow__resize-control {
  z-index: 20 !important;
}

// .react-flow__resize-control.nodrag.handle {
//   z-index: 9999 !important;
// }

.board-wrapper {
  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  [data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: 3.6em;
    left: 40%;
    font-size: 11px;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0, #f8f8f8),
      color-stop(1, #cccccc)
    );
    background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
  }

  [data-title] {
    position: relative;
  }
}

.datatable {
  .range-data {
    display: flex;
    justify-content: end;
  }
}

.table-data-bold {
  font-weight: 700 !important;
}

.arrows-subtext {
  display: inline-flex;
  flex-direction: column;

  .subtext-div {
    margin: 0 40px 5px;
  }
}

.subtext-div {
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapping-input {
    color: #5c677d !important;
    font-weight: 400;
    font-size: 12px;
  }
  span {
    padding: 0 5px;
  }

  .table-input-data.subtext {
    color: #5c677d !important;
    font-weight: 400;
    font-size: 12px;
    height: 15px !important;
    padding: 0.5rem 0.5rem !important;

    &.with-arrows {
      width: 75px;
      color: #ffffff !important;

      &:focus {
        color: #000000 !important;
      }
    }
  }
}

.inputs-with-arrows {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100px;

    &:focus {
      width: 100px;
    }
  }
}

.leftRightspace {
  padding: 1px 10px !important;
}

.arrowSize {
  .arrow-resize {
    padding: 3.3px !important;
    margin-right: 1px;
  }
}

.same-td-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    padding: 0px 5px;
  }

  img {
    padding: 0 5px;
  }
}

.col-input {
  input {
    text-align: center;
    width: 100px;
  }
}

.shape-highlight {
  input {
    width: "100px" !important;
    height: "25px !important";

    &:focus {
      color: #060606 !important;
    }
  }
}

.heatmap-legend {
  height: 15px;
  position: relative;

  span.initial {
    position: absolute;
    top: -20%;
    right: -21px;
    font-size: 13px;
  }

  span.final {
    position: absolute;
    top: -20%;
    left: -26px;
    font-size: 13px;
  }
}
.bubble-legend {
  height: 15px;
  position: relative;
  top: 20px;

  span.initial {
    position: absolute;
    top: -20%;
    right: -21px;
    font-size: 13px;
  }

  span.final {
    position: absolute;
    top: -20%;
    left: -26px;
    font-size: 13px;
  }
}

.bubble-div {
  position: relative;
  padding:0;
  margin:0;
}

.bubble-single-color {
  position: relative;
  padding:0;
  margin-top:-10px;
}

.geo-legend {
  .max-legend {
    right: -16px !important;
  }

  .min-legend {
    left: -12px !important;
  }
}

.kpi-legends {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bubblelegend-container {
  margin-top: 1rem;
  margin-left: 0.7rem;
  display: flex;
  align-items: flex-start;
  margin-right: -11px;
}

.bubble-legend {
  text-align: -webkit-left;
  font-size: 10px;

  .geobubble-legend {
    position: relative;
    height: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1.5rem !important;
    width: 35%;

    .largecircle-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1px !important;
      margin-right: -10px !important;

      .large-circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid;
        background-color: inherit;
        border-color: inherit;
      }

      .line-large {
        display: inline-block;
        border-left: 1px solid #ccc;
        margin: 0 10px;
        height: 12px;
      }
    }

    .mediumcircle-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4px !important;

      .medium-circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: inherit;
        border: 1px solid;
        border-color: inherit;
      }

      .line-medium {
        display: inline-block;
        border-left: 1px solid #ccc;
        margin: 0 10px;
        height: 12px;
      }
    }

    .smallcircle-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 11px !important;
      margin-left: -10px !important;

      .small-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 1px solid;
        background-color: inherit;
        border-color: inherit;
      }

      .line-small {
        display: inline-block;
        border-left: 1px solid #ccc;
        margin: 0 10px;
        height: 12px;
      }
    }
  }
}

.popUpHeadingTextWrap {
  display: block;
  margin: auto;
  width: 100%;
  word-wrap: break-word;
}

.tree-chart {
  span {
    white-space: pre !important;
  }
}

.text-color {
  color: #727272;
}

.circular-bullet::before {
  content: "";
  background-color: inherit;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: -3px 5px 0 0px;
}

.a {
  width: 1200%;
  height: 500px !important;
  background-color: #000000;

  span {
    padding: 0 !important;

    margin: 0 !important;
  }

  border: none !important;
}

.labels-treemap {
  text-align: left;
  display: block;

  .kpis.variant-five {
    display: inline-block;
    padding: 2px 2px 0px 10px;

    .wrapper-label {
      display: flex;
      align-items: center;

      .circular-bullet::before {
        content: "";
        background-color: inherit;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin: -3px 5px 0 0px;
      }
    }
  }
}

.react-datepicker__current-month {
  display: none;
}

.wrapping-input:focus {
  // width: 99% !important;
  // width: auto !important;
  // position: fixed;
  padding: 5px 8px;
  // border: 1px solid var(--border-color);
  outline: 0 !important;
  // top: 0px;
  max-width: -webkit-fill-available;
  // overflow: auto;
  // max-height: -webkit-fill-available;
  // margin-right: 15px;
  text-align: left !important;
  z-index: 1;
  // background: var(--background-color);

  background: #ffffff;
  // border: 1px solid #342BC2;
}

.wrapping-input {
  color: #000;
  text-align: left !important;
  font-size: 14px;
  padding: 5px 8px;
  cursor: pointer;
  padding-left: 10px;
  outline: 0 !important;
  // border: 1px solid transparent;
}

.noPointer {
  pointer-events: none;
}

//textbox-element

.text-box {
  padding: 10px;

  .textBox-font-weight {
    font-weight: 500;
  }

  .textBox-align-left {
    text-align: left;
  }

  .textBox-align-center {
    text-align: center;
  }

  .textBox-align-right {
    text-align: right;
  }

  .header {
    font-weight: 500;
    font-size: 0.85rem;
    padding-bottom: 5px;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 1.2;
  }

  .main-text {
    word-wrap: break-word;
    word-break: break-word;
    color: #060606;
    font-size: 0.8rem;
    background-color: var(--active-color);
    white-space: break-spaces;
  }
}

.image-element-block {
  .header-image {
    font-weight: 500;
    font-size: 0.85rem;
    word-wrap: break-word;
    word-break: break-all;
    padding: 7px 10px 0;
  }

  .image-element {
    height: 100%;
    padding: 10px;

    .img-element {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../assests/images/upload-image-element.png");
      // background-size: 100% 100%; //power-bi-fit
      // background-size: contain; //power-bi-normal
      // background-size: cover; //power-bi-fill
      width: 100%;
      height: 100%;

      &.image-height {
        height: 100%;
        width: 100%;
      }

      &.image-box {
        width: 100%;
        height: 95%;
      }
    }
  }
}

.search-dropdown {
  border-bottom: 1px solid #e6e7e9 !important;
  color: var(--text-secondary-color) !important;
  background-color: var(--active-color) !important;
  background-image: url("../assests/images/search.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right;
  padding: 5px 10px 5px 0px !important;
  background-size: 17px 17px;
  font-size: 12px;

  &:hover {
    color: var(--text-secondary-color) !important;
    border-bottom: 1px solid #e6e7e9 !important;
    background-color: var(--active-color) !important;
  }
}

.datatable {
  .chart-textbox {
    padding: 10px 0 !important ;
  }
}

.chart-textbox {
  padding: 10px;

  .main-text {
    word-wrap: break-word;
    word-break: break-word;
    color: #060606;
    font-size: 0.8rem;
    background-color: var(--active-color);
    white-space: break-spaces;
    line-height: 1.2;
  }
}

.funnel-chart-legend {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-items: center;
  padding: 5px;

  .labels-block {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0 5px 5px;
    align-items: flex-start;
    max-width: 20%;
    min-width: 100px;

    .label {
      margin-bottom: 0;
      color: var(--text-secondary-color);
      font-size: 14px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: inherit;
      word-break: break-all;
      // white-space: nowrap;
    }

    .circular-bullet-funnel {
      border-radius: 50%;
      display: contents;

      &::before {
        content: "";
        background-color: inherit;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .block {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }
  }
}

.treemap-legends-block {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .labels-treemap {
    text-align: left;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    &:empty {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
  }
}

.element-bar-actions {
  // left: 40%;
  position: absolute;
  top: -50px;
  z-index: 99999;
  background-color: #060515;
  display: flex;
  gap: 5px;
  padding-left: 9px;
  padding-right: 9px;
  height: 50px;
  padding: auto 21px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  .action-btn-container {
    // contain property used to handle tooltip fixed position
    contain: layout;
    // width: max-content;
    height: 100%;
    width: 100%;
    display: flex;
    padding-left: 9px;
    padding-right: 9px;
    align-items: center;
    cursor: pointer;
    .action-tooltip {
      padding-left: 0px;
    }
  }

  span {
    cursor: pointer !important;
    min-width: 24px;
    max-width: 24px;
  }
}
