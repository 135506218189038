.sidebar {
  .layout-sidebar {
    text-align: left;
    max-width: 270px;
    flex: 0 0 270px;
    order: -1;
    height: 100%;
    position: fixed;
    overflow-y: auto !important;
    background: #fbfbfb !important;
    scrollbar-gutter: stable;
    padding: 10px 20px 10px 23px;
    border-right: 1px solid #e6e7e9;

    .more-option {
      max-width: none;
    }

    .logo-header {
      padding: 10px 0 10px;
      width: 100%;

      hr {
        opacity: 0.9;
        background-color: #e6e7e9;
        width: 227px;
        background-color: #e6e7e9;
        max-width: 100%;
      }
    }

    .user-block {
      border: 1px solid #342bc2;
      padding: 10px 20px;
      background: #342bc2;
      color: #fff;
      margin-bottom: 20px;
      a {
        text-decoration: none;
      }

      .intials {
        width: 28px;
        height: 28px;
        background: #ecd348;
        border-radius: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
      }

      .name {
        font-size: 14px;
        color: #fff;
        line-height: 1.4;
        margin: 3px 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .position {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 1.4;
        margin: 3px 3px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .more {
        display: flex;
        justify-content: center;
        margin: 3px 0 0 5px;

        .btn-group {
          margin: -6px -20px 0 0;
        }

        .settings-dropdown {
          background-color: transparent;
          font-size: 20px;
          border: none;
          text-align: center;
          height: 38px;

          &:hover {
            color: #ffffff;
            background-color: none;
            border: none;
          }

          &[aria-expanded="true"] {
            background-color: none;
            color: #ffffff;
            border: none;
          }
        }

        .dropdown-toggle::after {
          margin-left: 0;
        }

        .menu {
          .dropdown-header {
            color: rgba(24, 24, 25, 0.7);
            font-size: 14px;
            line-height: 20px;

            a {
              color: rgba(24, 24, 25, 0.7);
            }
          }
        }
      }
    }

    .menu-block {
      padding: 0px;

      .project-screen-limits {
        background-color: #ff9e2a;
        border-radius: 11px;
        padding: 4px 10px;
        font-size: 12px;
        color: #ffffff;
        position: absolute;
        right: 0;
        top: 27%;
        line-height: 1;
        font-weight: 100;
        &.limit {
          padding: 4px 12px !important;
        }
      }

      ul {
        padding-inline-start: 0px;
        list-style: none;
        line-height: 45px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);

        a {
          text-decoration: none;
          color: rgba(0, 0, 0, 0.5);
        }

        li {
          position: relative;
          display: flex;
          align-items: center;

          span {
            display: flex;
            align-items: center;
            .menu-icon {
              padding: 0 15px 0 10px;
            }
          }

          &.modal-link {
            &:hover {
              color: #342bc2;
              cursor: pointer;
            }
          }
        }

        a.active {
          color: #342bc2;

          li {
            background-color: rgba(52, 43, 194, 0.1) !important;
          }

          li::after {
            content: "\f0da";
            text-align: right;
            color: #342bc2;
            font-family: FontAwesome;
            float: right;
            padding: 0 15px;
            margin-left: auto;
          }
        }

        li:hover {
          background-color: rgba(93, 93, 93, 0.08) !important;
        }
      }
    }

    .soon {
      pointer-events: none;

      span {
        opacity: 0.6;
      }

      .learn-view {
        color: #9ca0a9;

        &::after {
          content: "Soon";
          background-color: #342bc2;
          border: 1px solid #342bc2;
          padding: 2px 8px;
          color: #ffffff;
          border-radius: 10px;
          margin-left: 10px;
          font-size: 10px;
          letter-spacing: 0.5px;
        }
      }
    }

    .announce {
      border: 1px solid #e6e7e9;
      background: #fff;
      text-align: center;
      padding: 12px 10px 10px;

      img.confetti {
        width: 75%;
      }

      img {
        margin-bottom: 10px;
        margin-top: 5px;
      }

      h5 {
        font-size: 14px;
        margin: 0.45rem 0.45rem 0.35rem;
        text-align: left;
        color: #000;
        padding-inline-start: 5px;
      }

      p {
        font-size: 12px;
        color: #000000;
      }

      ul {
        list-style: none;
        text-align: left;
        margin: 0;

        li {
          color: #5e5e5e;
          font-size: 13px;
          padding: 3px 0;

          .icon {
            margin: 0px 0px 0px -27px;
            padding: 0 5px;
          }
        }

        li::marker {
          margin-top: 2px;
        }
      }

      button {
        height: 40px !important;
        font-size: 14px !important;
      }

      .btn-register {
        min-width: 148px;
        border-radius: 0px;
        border: 1px solid #e6e7e9;
        background: #fff;
        color: #000;
        font-size: 12px;
        padding: 10px;
      }
    }

    .hr-line {
      padding: 12px 0;

      hr {
        color: #e6e7e9;
        opacity: 0.9;
      }
    }

    .footer {
      border: 1px solid #eeeeee;
      padding: 5px 20px;
      background: #fbfbfb;

      .intials-footer {
        width: 28px;
        height: 28px;
        background: #342bc2;
        border-radius: 50%;
        margin: 7px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
      }

      .company {
        font-size: 13px;
        color: #5d5d5d;
        line-height: 1.4;
        text-align: center;
        padding: 13px 0 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .more-footer {
        margin: 8px 0px;

        img {
          margin-left: 6px;
        }
      }
    }
  }
}

.error {
  background-color: #fef5d9;
  border: 1px solid #fad151;
  color: #5e5e5e;
  padding: 8px 10px;
  font-size: 12px;

  > i {
    display: inline-block;
    padding: 4px 7px 4px 5px;
  }

  > span {
    padding: 4px 8px 2px 1px;
  }
}

.main {
  width: 100%;
}

.row-margin {
  margin-right: 0px;
}

.bottom-footer {
  position: fixed;
  bottom: 10px;
  width: 221px;
}

.tutorial-popup {
  padding: 0;
  border: none;

  .header-image {
    background-color: #e9e9e9;
    margin-bottom: -7px;
    text-align: center;

    video {
      max-width: 100%;
      width: 100%;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .info-blocks {
    background-color: #ffffff;
    padding: 25px 45px 60px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 7px;
      color: #000000;

      .hello-wave {
        margin-top: -2px;
      }
    }

    p {
      padding-bottom: 18px;
      font-weight: normal;
      font-size: 15px;
      color: #5e5e5e;
      max-width: 350px;
    }
  }

  .btn-section {
    button {
      margin: 0 15px 0 0px;
      height: 45px !important;
      font-size: 14px;

      &.notification-btn {
        min-width: 35%;
      }
    }
  }
}

@media screen and (max-height: 870px) {
  .tutorial-popup {
    .info-blocks {
      background-color: #ffffff;
      padding: 20px 45px 40px;

      h3 {
        font-weight: 500;
        font-size: 18px;
        font-weight: 500;
        font-size: 20px;
        padding-bottom: 7px;

        .hello-wave {
          margin-top: -2px;
        }
      }

      p {
        padding-bottom: 18px;
        font-weight: normal;
        font-size: 15px;
        color: #5e5e5e;
        max-width: 350px;
      }
    }

    .btn-section {
      button {
        margin: 0 15px 0 0px;
        height: 45px !important;
        font-size: 14px;

        &.notification-btn {
          min-width: 35%;
        }
      }
    }
  }
}

.steps-style {
  margin-bottom: -10px;

  h2 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding-bottom: 12px;
    font-family: "Rubik";
    color: #ffffff;
  }

  p {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    font-family: "Rubik";
    color: #ffffffbf;
  }

  span {
    position: absolute;
    bottom: 28px;
    font-size: 12px;
    font-weight: normal;
    color: white;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    max-width: 150px;

    img {
      width: 70%;
    }
  }
}

polygon {
  fill: #352cc2;
}

.skip-steps {
  color: #ffffff;
  font-weight: normal;
}

.common-layout {
  display: flex;
}

.home-wrapper {
  display: flex;

  .sidebar {
    flex-basis: 270px;
    background-color: #ffffff;
  }

  .component-wrapper {
    flex-basis: calc(100% - 270px);
  }
}

@media screen and (min-height: 0px) and (max-height: 640px) {
  .announce {
    border: 1px solid #e6e7e9;
    background: #fff;
    text-align: center;
    padding: 12px 10px 10px;

    img.confetti {
      width: 50% !important;
    }

    img {
      margin-bottom: 10px;
      margin-top: 5px;
      width: 28% !important;
    }

    p {
      font-size: 10px;
    }

    button {
      height: 40px !important;
    }

    h5 {
      font-size: 13px !important;
      margin: 0.35rem 0.35rem 0.25rem !important;
      text-align: left;
      padding-inline-start: 5px;
    }

    ul {
      list-style: none;
      text-align: left;
      margin: 0;

      li {
        color: #5d5d5d;
        font-size: 12px !important;
        padding: 3px 0 !important;

        .icon {
          margin: 0px 0px 0px -27px;
          padding: 0 5px;
        }
      }

      li::marker {
        margin-top: 2px;
      }
    }
  }
}
