.elements-collapsible {
  padding: 0px 20px 0 15px;
  // padding-bottom: 0.1px;

  .collapsible-icon {
    background: #efefef;
    border-radius: 2px;
    padding: 3px;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .active-collapse {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .inActive-collapse {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inactive-collapse-customize {
  color: var(--text-secondary-color) !important;
  font-size: 13px !important;
}

.details-wrapper .sticky-heading {
  padding: 10px 5px 0px 0px !important;

  .inside-padding {
    padding: 0px 15px;
  }

  .data-customise-tab {
    padding-bottom: 20px;
    margin-bottom: 0px;

    .new-detail-tabs {
      margin-bottom: 0px;
    }
  }
}

.details-wrapper .select-input .form-control {
  text-align: center;
}

.px-10py-0 {
  padding: 0px 10px;
}

.details-wrapper {
  padding: 0px 0px 60px 0px !important;
}

.details-wrapper .form-group .label-details {
  font-weight: 400;
  font-size: 13px;
  // padding: 10px 0px;
  padding: 0px;
  color: #5e5e5e;
}

.info-circle-icon {
  display: flex;
  align-items: center;
  gap: 1px;
}

.details-wrapper .form-group {
  // margin-bottom: 12px;
  margin-bottom: 20px;
  .disabled-col {
    pointer-events: none;
    opacity: 0.5;
  }
}

.details-wrapper .scatter-form {
  margin-bottom: 0 !important;
}

.details-wrapper .bestfit-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.details-wrapper .dataset-custom-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.details-wrapper .bestfit-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.details-wrapper .dataset-custom-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.details-wrapper .bestfit-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}

.details-wrapper .dataset-custom-style {
  margin-bottom: 0 !important;
  margin-top: 15px !important;
}
.details-wrapper .toggles {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  width: 70px;
  height: 26px;
  background: #f1efef;
  border-radius: 3px;

  .active {
    background: #ffffff;
    border-radius: 2px;
    height: 17px;
    width: 17px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .inactive {
    height: 17px;
    width: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.details-wrapper .upload-logo-label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #342bc2;
  color: white;
  font-size: 12px;
  padding: fit-content;
  height: 36px;
  border-radius: 2px;
  padding: 7px;
  width: 100%;
  text-align: center;
}

.details-wrapper .upload-logo-details {
  height: 44px;
  cursor: pointer;

  background: #f6f6f6;
  justify-content: space-between;
  padding: 11px;
  display: flex;
  /* Grey/Line */
  align-items: center;
  border: 1px solid #e6e7e9;
  border-radius: 2px;

  .upload-icon-image {
    width: 30px;
    height: 30px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actions {
    display: none;
    width: auto !important;
    padding: 5px;
  }

  .file-icon-name-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 1.2;
  }

  .image-name {
    padding-left: 7px;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    display: block;
    display: -webkit-box;
    max-width: 160px;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.details-wrapper .upload-logo-details:hover {
  .actions {
    display: inline-flex;
  }
}

.sidebar-wrapper .overflow-div {
  padding: 0px !important;
}

.details-wrapper.overflow-details {
  padding: 0px 0px 60px 0px !important;
}

.custom-canvas-sidebar {
  .search-block {
    padding: 0px 15px 16px 15px;
    background-color: #ffffff;

    .form-group {
      .search-bar {
        height: 45px !important;
        font-size: 14px !important;
        background-color: var(--background-color);
        border-radius: 6px;
        background-image: url("../assests/images/search.svg") !important;
        background-repeat: no-repeat !important;
        background-position: 14px !important;
        padding: 5px 46px !important;
        background-size: 7%;
        color: var(--text-primary-color);
        border-color: transparent;

        &::placeholder {
          font-size: 14px !important;
          color: var(--text-secondary-color);
        }
      }

      .search-close {
        width: 45px !important;
        top: 4px !important;

        img {
          width: 18px;
        }
      }
    }
  }
}

.no-element-found {
  img {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  h5 {
    font-size: 14px !important;
  }

  p {
    color: var(--text-secondary-color) !important;
    font-size: 13px !important;
    max-width: 220px !important;
    margin: auto !important;
  }
}

.with-element-info {
  &:hover {
    .element-info {
      position: absolute;
      border-radius: 50%;
      font-size: 8px;
      top: 5px;
      right: 5px;
      background-color: var(--text-active-color);
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../assests/icons/element-info.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .element-info-container {
    position: relative;

    .__react_component_tooltip {
      width: auto !important;
      height: auto !important;
    }

    .custom-tooltip-style {
      // left: 286px !important;
      z-index: 999 !important;
      text-align: left;
      padding: 10px 14px;
      opacity: 1 !important;

      h4 {
        font-size: 13px;
        font-weight: 600;
        color: var(--text-active-color);
        margin-bottom: 0px;
        padding-bottom: 8px;
        max-width: 180px !important;
      }

      p {
        font-size: 13px;
        color: rgba(255, 255, 255, 0.75) !important;
        margin-bottom: 0px;
        font-weight: 400 !important;
        max-width: 180px !important;
      }
    }
  }
}

.dashboard-builder-sidebar-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.border-bottom-0-input {
  .form-control {
    border-bottom: none !important;
  }
}

.add-ons-collapse {
  .collapse-icon {
    float: left;
    padding: 2px;
    display: block;
    width: 17px;
    font-size: 9px;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
    }
  }
}

.collapse-icon {
  float: left;
  padding: 2px;
  display: block;
  width: 17px;
  font-size: 9px;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }
}

.edit-title-and-value-field {
  display: flex;
}

.kpi-input-field {
  text-align: center;
}

.dropdown-info-setting {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  align-items: baseline;

  .info-setting-icons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.variant-theme-container {
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 15px;

  .variant-theme-title {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
  }

  .theme-popup-icon-container {
    margin-left: auto;
    background-color: #efefef;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-bottom: 20px;

    .theme-popup-icon {
      padding: 4px;
    }
  }
}

.manage-popup {
  padding: 30px 30px 35px;

  .header {
    align-items: center;

    h4 {
      margin-bottom: 0px;
    }
  }
}

.custom-dropdown-modal-width {
  max-width: 420px !important;
}

.column-ordering-drop {
  .theme-list {
    padding-bottom: 8px !important;
  }

  .sub-heading {
    padding: 2px 0px 10px 0px !important;
  }

  .heading-dnd {
    color: var(--text-secondary-color);
    font-weight: 100;
    padding-bottom: 5px;
    font-size: 14px;
  }

  ul {
    padding-inline-start: 0px;

    li {
      list-style: none;
      margin-bottom: 10px;
      align-items: center;
      gap: 10px;
    }

    .list-div {
      display: flex;
      align-items: center;
      padding: 5px 5px;
      width: 85%;
      height: 38px;
      border-radius: 2px;
      border: 1px solid #e6e7e9;
      background-color: #f6f6f6;

      .characters-thumb {
        margin-right: 5px;

        &.first-item {
          opacity: 0.5 !important;
          cursor: not-allowed !important;
        }
      }

      p {
        margin: 0;
        font-size: 13px;
        color: #000000;
        font-weight: 400;
        margin-bottom: -4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 150px;

        span {
          color: #a9a9a9;
          font-weight: 100;
        }
      }

      span {
        font-size: 13px;
        color: #9ca0a9;
      }
    }

    .icons {
      cursor: pointer !important;

      img {
        padding: 0 3px;
        cursor: pointer !important;
      }
    }
  }
}

.edit-date-range-section {
  position: relative;

  .arrow-icon-black-bg {
    position: absolute;
    z-index: 5;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.date-format-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 6px;
    cursor: pointer;
  }

  .date-format {
    padding: 0px 5px !important;
  }
}

.date-filter {
  .date-edit {
    position: relative;
    display: flex;

    span {
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .form-group {
      margin-bottom: 0px;
    }
  }

  .start-range {
    .form-group {
      input {
        &:focus-within {
          border-bottom: none !important;
        }
      }
    }
  }
}

.select-arrow {
  .css-319lph-ValueContainer {
    padding-right: 0px;
  }
}

.no-margin-for-form-group-as-a-child {
  .form-group {
    margin-bottom: 0px !important;
  }
  &.disabled-col {
    pointer-events: none;
    opacity: 0.6;
  }
}

.details-wrapper {
  .new-reset {
    top: 54%;
    right: 2%;
    background-color: var(--text-active-color);
    cursor: pointer;
  }
}

.text-labels-style {
  font-family: Rubik;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
}

.info-tooltip {
  position: relative;
  left: 0.1rem;
}

.upload-image-container {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.new-button-container {
  .new-button-List-Input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #f6f6f6;
    width: 240px;
    height: 40px;
    gap: 10px;
    border: 1px solid #e6e7e9;
    border-radius: 2px;

    .buttonListInputText {
      font-size: 13px;
    }

    .buttonListDragIcon {
      width: 20px;
      margin-left: 10px;
    }

    .form-group {
      margin-bottom: 0rem !important;
    }
  }

  .buttonListInputDelete {
    justify-content: flex-start;
    gap: 14px;
  }

  .buttonListAddBtn {
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
}

.edit-percentage-change-label {
  font-family: Rubik;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
  color: #5e5e5e;
}

.new-add-kpi-container {
  .nav-tabs {
    border: none;

    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: unset;
      border-top-right-radius: 0.25rem;
      position: relative;
      color: var(--text-tertiary-color);
      font-size: 16px;
      padding-bottom: 12px;
      cursor: pointer;

      .bar {
        display: flex !important;
        position: relative;
        height: 4px;
        top: 12px;
        border: none;
        border-radius: 10px;
      }

      &:hover {
        .bar {
          background: #e9ecef;
        }
      }

      &.active {
        background-color: transparent;
        border-color: transparent;
        color: var(--text-primary-color);

        .bar {
          background: var(--active-blue-color);
        }
      }
    }
  }
}

.add-ons-navbar {
  display: flex;
  align-items: baseline;
  border-bottom: 1px solid #e6e7e9;
  margin-bottom: 1.25rem;

  .add-ons-nav-titles {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-right: auto;

    span {
      font-family: Rubik;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      width: 50px;
      height: 11px;
      cursor: pointer;
    }
  }

  .add-ons-nav-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 8px;
  }
}

.new-addkpi-container .nav-link .bar {
  display: flex !important;
  // position: absolute;
  position: relative;
  height: 4px;
  top: 12px;
  border: none;
  border-radius: 10px;

  &:hover {
    .bar {
      background: #e9ecef;
    }
  }

  &.active {
    background-color: transparent;
    border-color: transparent;
    color: var(--text-primary-color);

    .bar {
      background: var(--active-blue-color);
    }
  }
}

.new-stacked {
  margin-top: 0px !important;
  margin-bottom: 20px;
  border-color: var(--border-color) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.new-add-button-container {
  .new-add-button-List-Input-delete {
    justify-content: flex-start !important;
    gap: 8px;

    .new-add-button-List-Input {
      width: 255px !important;

      .buttonListInputText {
        font-size: 13px;
      }
    }
  }
}

.range-slider {
  background: #f6f6f6;
  margin: 5px 0px;
  padding: 0px 10px;
}

.svg-icon-span {
  svg {
    path {
      fill: #5e5e5e;
      opacity: 85%;
    }
  }

  .svg-enabled-icon {
    cursor: pointer !important;
  }

  .svg-disabled-icon {
    opacity: 40%;
    cursor: default;
    pointer-events: none !important;
  }
}

.new-error-msg {
  margin-top: 0px !important;
}

.new-add-dropdown-container {
  .list-values {
    padding: 0px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    span {
      padding: 4px 10px;
      display: inline-flex;
      width: fit-content;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      margin: 0px;
      font-size: 13px;

      img {
        margin: 0px;
        padding: 0px;
        cursor: pointer;
      }
    }
  }

  .add-ons-nav-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 8px;

    .svg-enabled-icon {
      cursor: pointer;
    }
  }
}

// .new-main-tabs{
//   width : auto !important;
//   .new-nav-tabs{
//     // justify-content: space-between !important;
//     .nav-item {
//       width: auto !important;
//       flex-grow: 1;
//     }
//   }
// }

.padding-to-label-with-input {
  padding-bottom: 10px !important;
}

.gauge-error {
  position: fixed;
  bottom: 0px;
  background-color: var(--error-red);
  left: 0;
  padding: 10px 17px 10px 17px;
  color: #ffffff;
  font-size: 14px;
  display: flex !important;
  align-items: center;
  gap: 10px;
  width: 305px !important;
  left: 0;
}

.toolTipFontGauge {
  font-size: 12px !important;
}

.new-filter-dropdown-container {
  .list-values {
    padding: 0px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    span {
      padding: 4px 10px;
      display: inline-flex;
      width: fit-content;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      margin: 0px;
      font-size: 13px;

      img {
        margin: 0px;
        padding: 0px;
        cursor: pointer;
      }
    }
  }
}

.details-wrapper .form-group .input-details {
  font-size: 13px;
}

.column-ordering ul li p {
  font-size: 12px;
}

.column-ordering ul li span {
  font-size: 10px;
}

.details-wrapper hr {
  /* background-color: #dfdfdf; */
  opacity: 1;
  margin: 20px 0px;
  border-top-width: 2px;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  height: 0px;
}

//new-themes

.new-themes-container {
  padding-left: 15px !important;
  padding-right: 15px !important;

  hr {
    margin-bottom: 20px;
    margin-top: 5px;
    border-top-width: 2px;
    border-top-color: var(--border-color);
    border-top-style: solid;
    opacity: 1 !important;
    color: unset;
    background-color: unset;
  }

  .elements-collapsible {
    .collapse {
      padding-bottom: 5px;
    }

    .inActive-collapse,
    .active-collapse {
      padding: 0px;
      margin-bottom: 20px !important;
    }
  }

  .go-back-how-it-works {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    width: 276px;

    .go-back {
      font-size: 14px;
      cursor: pointer;

      img {
        padding-right: 8px;
      }
    }

    .how-it-works {
      font-size: 14px;
      color: var(--active-blue-color);
    }
  }

  .themes-tabs {
    background-color: #eeee;
    border-bottom: 1px solid #e6e7e9 !important;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    border-radius: 2px;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-right: 5px;

    .nav {
      width: 100%;
      justify-content: space-evenly;
      gap: 3px;
      padding-left: 3px;
      padding-right: 3px;
      border: 0px;

      .nav-item {
        flex: 1;

        .nav-link {
          font-size: 13px;
          border-radius: 0px;
          border: 0px;
          cursor: pointer;
          color: var(--text-inactive-color);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;

          .crownicon {
            width: 13px;
          }

          &:hover {
            border-color: unset;
            color: var(--text-primary-color);
          }
        }

        .active {
          cursor: default;
          color: var(--text-primary-color);
        }
      }
    }
  }

  .theme-box-container {
    border: 1px solid #e6e7e9;
    margin-bottom: 10px;

    .new-element-tag-container {
      position: relative;

      .new-element-tag {
        position: absolute;
        border-radius: 2px;
        font-size: 8px;
        top: -8px;
        left: 14px;
        padding: 2px 8px;
        color: var(--text-active-color);
        background-color: var(--warning-orange);
      }
    }

    .new-active-theme {
      border: 1px solid #342bc2;
      // pointer-events: none;
      position: relative;

      &::after {
        content: "\f058";
        font-family: "FontAwesome", "Rubik";
        position: absolute;
        color: #342bc2;
        top: -12px;
        right: -6px;
        height: 14px;
        width: 14px;
        border: none;
        background-color: var(--text-active-color);
        border-radius: 50%;
      }
      &.deleted-theme {
        border-color: #ef4444;
        &::after {
          color: #ef4444;
        }
      }
    }

    .theme-box {
      border-radius: 2px;
      height: 100px;
      padding: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      cursor: pointer;

      .pallete-box {
        border-radius: 2px;
        border: 1px solid #e6e7e9;
        height: 50px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &.fade-theme {
          opacity: 0.5;
        }

        .on-hover-box {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          color: var(--text-primary-color);
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: flex;
          opacity: 0;
          transition: opacity 0.5s ease;
          border-radius: 2px;

          span {
            background-color: var(--text-active-color);
            font-size: 12px;
            border-radius: 2px;
            padding: 4px 16px;
          }
        }

        &:hover {
          .on-hover-box {
            display: flex;
            opacity: 1;
          }
        }

        .color-palette {
          border-radius: 2px;
          width: 100%;
          display: flex;
          height: inherit;
          background-color: var(--text-active-color);

          .colors {
            flex: 1;
          }
        }

        .pro-tag {
          position: absolute;
          display: flex;
          align-items: center;
          color: #ffffff;
          border: 1px solid transparent;
          background-color: rgba(62, 62, 62, 0.7);
          padding: 1px 3px;
          border-radius: 2px;
          top: 5px;
          right: 5px;

          &::before {
            content: " ";
            background-image: url("../assests/icons/crownicon.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 16px;
            height: 16px;
          }
        }
      }

      .tag-name-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .tag-left-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 60%;
        > img {
          width: 12px;
          height: 12px;
        }
        .deleted-theme-info {
          text-align: left;
          color: var(--text-active-color);
          margin: 0;
          margin-block-end: 0;
          padding: 0;
          font-size: 12px;
        }
      }

      .tag-name-left {
        color: var(--text-secondary-color);
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        line-height: 16px;
      }

      .custom-theme-name {
        display: flex;
        align-items: flex-end;
        gap: 4px;

        .edit-custom-theme-name {
          cursor: pointer;
        }
      }

      .tag-name-right {
        color: var(--text-tertiary-color);
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.custom-themes-wrapper {
  padding-bottom: 180px;
  width: 312px;

  .tips-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #dff5ef;
    padding: 8px 14px;
    border-radius: 2px;
    margin-bottom: 20px;
    gap: 20px;

    .tip-close {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .green-stars-tip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      img {
        width: 18px;
        height: 18px;
      }

      .tip-content {
        font-size: 12px;
        color: var(--text-secondary-color);
        text-align: left;
      }
    }
  }

  .color-picker {
    max-width: 260px;

    .color-picker-input-boxes {
      display: flex;
      justify-content: space-between;

      .input-hex-code {
        width: 60%;

        .form-group {
          position: relative;

          &::after {
            content: "HEX";
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            color: var(--text-tertiary-color);
            font-size: 13px;
          }
        }
      }

      .input-percent {
        width: 30%;

        .form-group {
          position: relative;

          &::after {
            content: "%";
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            color: var(--text-primary-color);
            font-size: 13px;
          }

          input {
            // text-align: center;
            padding-right: 15px;
            padding-left: 15px;
          }
        }
      }
    }

    .form-group {
      input {
        padding: 5px 10px;
        font-size: 13px;
        height: 35px;
      }

      input:not(:placeholder-shown) {
        background-color: var(--text-active-color);
        border: 1px solid var(--border-color);
      }

      input:focus-within {
        border: 1px solid var(--border-color) !important;
      }
    }

    .sketch-picker {
      .flexbox-fix {
        padding: 15px 0px 12px !important;

        > div:nth-child(1) {
          padding-right: 0px !important;
        }

        > div:nth-child(2) {
          display: none;
        }

        > div:nth-child(1) > div:nth-child(1) {
          border-radius: 100px;
        }

        > div:nth-child(1) > div:nth-child(2) {
          border-radius: 100px;
        }
      }
    }

    hr {
      margin-top: 15px !important;
      margin-bottom: 0px !important;
    }

    .color-picker-heading {
      margin-bottom: 0px;
      font-size: 14px;
      text-align: left;
      padding: 15px 15px 0px !important;

      .head-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .create-new-theme-btn {
    position: fixed;
    bottom: 0px;
    background-color: white;
    left: 0;
    padding: 10px 0px 10px 0px;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 305px !important;

    hr {
      width: 100%;
      margin: 10px 0px !important;
    }

    left: 0;

    .pad-left-right {
      width: 100%;

      .success-btn {
        height: 40px;
        color: #ffffff;
        font-size: 14px;
        background-color: #342bc2;
        border: 1px solid #342bc2;
        border-radius: 2px;
        width: 100%;
      }
    }
  }

  .pad-left-right {
    padding-left: 15px !important;
    padding-right: 20px !important;
  }

  hr {
    margin-bottom: 20px;
    margin-top: 5px !important;
    border-top-width: 2px;
    border-top-color: var(--border-color);
    border-top-style: solid;
    opacity: 1 !important;
    color: unset;
    background-color: unset;
  }

  .elements-collapsible {
    .collapsible-icon {
      font-size: 12px;
    }

    .inActive-collapse,
    .active-collapse {
      padding: 0px;
      margin-bottom: 20px !important;
    }
  }

  .go-back-how-it-works {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 20px;

    .go-back {
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        padding-right: 8px;
      }
    }

    .how-it-works {
      font-size: 14px;
      color: var(--active-blue-color);
      cursor: pointer;
    }
  }

  .themes-tabs {
    background-color: #eeee;
    border-bottom: 1px solid #e6e7e9 !important;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    border-radius: 2px;
    justify-content: space-evenly;
    margin-bottom: 20px;

    .nav {
      width: 100%;
      justify-content: space-evenly;
      gap: 3px;
      padding-left: 3px;
      padding-right: 3px;
      border: 0px;

      .nav-item {
        flex: 1;

        .nav-link {
          font-size: 13px;
          border-radius: 0px;
          border: 0px;
          cursor: pointer;
          color: var(--text-inactive-color);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2px;

          .crownicon {
            width: 13px;
          }

          &:hover {
            border-color: unset;
            color: var(--text-primary-color);
          }
        }

        .active {
          cursor: default;
          color: var(--text-primary-color);
        }
      }
    }
  }

  .theme-box-container {
    border: 1px solid #e6e7e9;
    margin-bottom: 20px;

    .new-element-tag-container {
      position: relative;

      .new-element-tag {
        position: absolute;
        border-radius: 2px;
        font-size: 8px;
        top: -8px;
        left: 14px;
        padding: 2px 8px;
        color: var(--text-active-color);
        background-color: var(--warning-orange);
      }
    }

    .new-active-theme {
      border: 1px solid #342bc2;
      // pointer-events: none;
      position: relative;

      &::after {
        content: "\f058";
        font-family: "FontAwesome", "Rubik";
        position: absolute;
        color: #342bc2;
        top: -12px;
        right: -6px;
        height: 14px;
        width: 14px;
        border: none;
        background-color: var(--text-active-color);
        border-radius: 50%;
      }
    }

    .theme-box {
      border-radius: 2px;
      height: 100px;
      padding: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      // cursor: pointer;

      .pallete-box {
        border-radius: 2px;
        border: 1px solid #e6e7e9;
        height: 50px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .on-hover-box {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.5);
          color: var(--text-primary-color);
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: flex;
          opacity: 0;
          transition: opacity 0.5s ease;
          border-radius: 2px;

          span {
            background-color: var(--text-active-color);
            font-size: 12px;
            border-radius: 2px;
            padding: 4px 16px;
          }
        }

        &:hover {
          .on-hover-box {
            display: flex;
            opacity: 1;
          }
        }

        .color-palette {
          border-radius: 2px;
          width: 100%;
          display: flex;
          height: inherit;
          background-color: var(--text-active-color);

          .colors {
            flex: 1;
          }
        }

        .pro-tag {
          position: absolute;
          display: flex;
          align-items: center;
          color: #ffffff;
          border: 1px solid transparent;
          background-color: rgba(62, 62, 62, 0.7);
          padding: 1px 3px;
          border-radius: 2px;
          top: 5px;
          right: 5px;

          &::before {
            content: " ";
            background-image: url("../assests/icons/crownicon.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 16px;
            height: 16px;
          }
        }
      }

      .tag-name-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .tag-name-left {
        color: var(--text-secondary-color);
        font-size: 12px;
      }

      .custom-theme-name {
        display: flex;
        align-items: flex-end;
        gap: 4px;

        .custom-name {
          max-width: 60%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
          border-radius: 4px;

          input {
            color: var(--text-secondary-color);
            font-size: 12px;
            border-radius: 4px;
            padding: 0px 5px;

            &:not(:placeholder-shown) {
              background-color: var(--menu-slider-color);
              border: none !important;
              outline: none;
            }
          }

          &:focus-visible {
            border: none !important;
            outline: none;
          }

          &:focus-within {
            border: none !important;
            outline: none;
            cursor: text;
          }
        }

        .edit-custom-theme-name {
          cursor: pointer;
          width: 8%;
        }

        .edit-custom-theme-name-submit {
          cursor: pointer;
          width: 10%;
          height: 18px;
          background-color: white;
          border: none;
        }
      }

      .tag-name-right {
        color: var(--text-tertiary-color);
        font-size: 12px;
      }
    }
  }

  .color-picker {
    position: fixed;
    right: unset;
    left: 314px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    background-color: var(--text-active-color);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.15) 0px 8px 16px;
    padding: 0px 0px 15px;
  }

  .delete-btn {
    color: var(--error-red);
    font-size: 13px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    svg {
      margin-bottom: 1px;

      path {
        fill: var(--error-red);
      }
    }
  }

  .color-info-icon {
    width: 15px;
    height: 15px;
  }

  .sample-color-border {
    border: 1px solid var(--border-color);
    padding: 3px;
    border-radius: 2px;

    .left-shade {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    .right-shade {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }

  .active-box {
    border: 1px solid var(--active-blue-color);
  }

  .hex-code {
    position: relative;

    input {
      padding: 5px 10px;
      font-size: 13px;
    }

    input:not(:placeholder-shown) {
      background-color: var(--text-active-color);
      border: 1px solid var(--border-color);
    }

    input:focus-within {
      border: 1px solid var(--border-color) !important;
    }

    input.input-error-border {
      border: 1px solid var(--error-red) !important;

      &:focus-within {
        border: 1px solid var(--error-red) !important;
      }
    }

    input.input-no-error-border {
      border: 1px solid var(--border-color) !important;

      &:focus-within {
        border: 1px solid var(--border-color) !important;
      }
    }
  }
}
input.input-error-border {
  border: 1px solid var(--error-red) !important;

  &:focus-within {
    border: 1px solid var(--error-red) !important;
  }
}

input.input-no-error-border {
  border: 1px solid var(--border-color) !important;

  &:focus-within {
    border: 1px solid var(--border-color) !important;
  }
}

// .kpis.variant-nine .value-block .value{
//   flex: 1;
// }

// .kpis.kpiBar{
//   padding-top: 0px;
// }

.title-logo.header-1 h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.kpis.kpiBar {
  padding-top: 4px;
}

.kpis.kpiBar .row-bar {
  line-height: unset !important;
  align-items: center;
  gap: 4px;

  .label {
    margin-bottom: 0px;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}

.kpis.kpiBar .row-bar .values {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// .new-title-wrap{
//   word-wrap: break-word;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-inline-box !important;
//   -webkit-line-clamp: 2;
//   -webkit-box-orient: vertical;
//   word-break: break-all;
// }

.variant-four {
  .block-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-inline-box !important;
    // display: inline;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span.prev {
    margin-bottom: -4px !important;
    margin-top: 0px !important;
  }

  span.prev-value,
  span.unit-value {
    margin-top: 0px;
    margin-bottom: -4px;
  }
}

.variant-five {
  .block-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-inline-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span.prev {
    margin-bottom: -4px !important;
    margin-top: 0px !important;
  }

  span.prev-value,
  span.unit-value {
    margin-top: 0px;
    margin-bottom: -4px;
  }
}

.variant-six {
  .block-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-inline-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span.prev {
    margin-bottom: -4px !important;
    margin-top: 0px !important;
  }

  span.prev-value,
  span.unit-value {
    margin-top: 0px;
    margin-bottom: -4px;
  }
}

.variant-seven {
  .block-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-inline-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span.prev {
    margin-bottom: -4px !important;
    margin-top: 0px !important;
  }

  span.prev-value,
  span.unit-value {
    margin-top: 0px;
    margin-bottom: -4px;
  }

  .value-measure {
    gap: 10px;

    span {
      display: contents;
    }
  }
}

.variant-eight {
  .block-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    // display: -webkit-inline-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  span.prev {
    margin-bottom: -4px !important;
    margin-top: 0px !important;
  }

  span.prev-value,
  span.unit-value {
    margin-top: 0px;
    margin-bottom: -4px;
  }

  p span {
    margin-left: 10px;
    margin-bottom: -5px;
  }
}

.variant-nine {
  .new-title-wrap {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }

  .value-block .value {
    flex: 1;
  }
}

.kpi-addons-percentage-value-wrapping {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-inline-box;
  padding-right: 2px;
}

.kpi-addons-small-container {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  bottom: 2px;
}

////header
.new-navi-block {
  display: flex;

  button.navigation {
    flex: 1;
    max-width: 150px;
    overflow: hidden;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.tooltip-container {
  position: absolute;
  right: -15px;
}

.title-logo.header-1 .title-logo-block {
  // max-width: 60%;
  max-width: max-content;

  &:only-child {
    max-width: 100%;
  }
}

.title-logo.header-1 .info {
  max-width: 38%;
  width: max-content;
  display: flex;

  .child-info {
    width: max-content;
    max-width: 100%;
    font-size: 0.8rem;
    padding: 3.5px 10px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    line-height: 18.5px;
    color: #ffffff;
  }
}

.info-tooltip-for-disabled {
  display: flex;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
  }

  padding-left: 5px;
}

.histo-form-submit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: -20px;

  .discard {
    border: 1px solid #e6e7e9;
    background: #ffffff;
    border-radius: 2px;
    color: #000;

    &:hover {
      background-color: #ededed;
      color: #000;
      border: 1px solid #ededed;
    }
  }

  .save {
    background-color: #342bc2;
    border-radius: 2px;
    border: 1px solid #342bc2;

    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }
  }

  button.custom-size {
    height: 40px !important;
    min-width: 80px !important;
    font-size: 14px !important;
  }
}

.design-main-container {
  .design-heading-container {
    // padding: 0 15px;

    p {
      text-align: left;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .design-change-theme-button-container {
    padding: 5px 15px;

    .change-theme-button {
      width: 100%;
      height: 38px;
      background-color: transparent;
      border: 1px solid #e6e7e9;
      outline: none;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
    }
  }

  .hr-design {
    color: #e6e7e9;
    opacity: 1 !important;
  }

  .design-customisations-container {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 20px;
    align-items: center;

    .customisations-container {
      display: flex;
      align-items: center;
      gap: 2px;

      .customisations {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0;
      }

      .mokkup-pro-tag {
        position: relative;
        top: 3px;
        right: 5px;
      }
    }

    .how-it-works {
      font-size: 13px;
      font-weight: 400;
      color: #342bc2;
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  .design-properties-container {
    .design-rounded-corners-collapsible {
      padding: 0 15px;

      .elements-collapsible {
        padding: 0;

        .inActive-collapse {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 12px;
        }
        .collapsible-icon {
          display: flex;
        }
      }
      .design-rounded-corners-details-container {
        display: flex;
        align-items: center;
        padding: 10px 0px 10px 0px;
        justify-content: space-between;

        .design-rounded-corners-details-text {
          font-size: 13px;
          color: var(--text-secondary-color);
          margin-bottom: 0;

          .pixels-text {
            color: var(--text-tertiary-color);
          }
        }

        .design-rounded-corners-buttons-container {
          display: flex;
          gap: 5px;

          .design-rounded-corners-input {
            width: 40px;
            text-align: center;
            font-size: 12px;
            border-radius: 2px;
            background-color: var(--background-light-color);
            border: 1px solid transparent !important;
            &:focus-within {
              border: 1px solid transparent !important;
            }
            &.input-error-border {
              border: 1px solid var(--error-red) !important;

              &:focus-within {
                border: 1px solid var(--error-red) !important;
              }
            }
          }

          .design-rounded-corners-button {
            padding: 2px 5px 2px 5px;

            font-size: 12px;
            border-radius: 2px;
            background-color: var(--background-light-color);

            .action-button {
              margin: 0px 3px;
              padding: 0px 2px 2px 2px;
              cursor: pointer;
              &.active {
                background-color: #ffffff;
              }
            }
          }
        }
      }
    }
    .design-image-as-background-toggle {
      padding: 0px 15px;
      label {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-wrap: nowrap;

        &.toggle-label-customize {
          font-size: 14px !important;
          font-weight: 400 !important;
          color: #000000 !important;
        }
      }

      .image-functionality-container {
        margin-top: 20px;
        .upload-logo-label {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #342bc2;
          color: white !important;
          font-size: 12px;
          padding: fit-content;
          height: 36px;
          border-radius: 2px;
          padding: 7px;
          width: 100%;
          text-align: center;
        }
        .upload-logo-details {
          height: 44px;
          cursor: pointer;

          background: #f6f6f6;
          justify-content: space-between;
          padding: 11px;
          display: flex;
          /* Grey/Line */
          align-items: center;
          border: 1px solid #e6e7e9;
          border-radius: 2px;

          .upload-icon-image {
            width: 30px;
            height: 30px;
            object-fit: contain;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .actions {
            display: none;
            width: auto !important;
            padding: 5px;
          }

          .file-icon-name-container {
            display: flex;
            align-items: center;
            overflow: hidden;
            line-height: 1.2;
          }

          .image-name {
            padding-left: 7px;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
            display: block;
            display: -webkit-box;
            max-width: 160px;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .upload-logo-details:hover {
          .actions {
            display: inline-flex;
          }
        }
      }
      .switch {
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;
      }
    }
  }
}

.design-icons {
  margin-right: 14px;
  &.collapsible {
    order: -1;
  }
}

.design-properties-container .form-group {
  text-align: left;

  .label-details {
    font-weight: 400 !important;
    font-size: 12px !important;
    padding: 0px !important;
    color: #5e5e5e !important;
  }
  .toggles {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
    width: 100%;
    height: 36px;
    background: #f1efef;
    border-radius: 3px;
    img {
      width: 16px;
      height: 16px;
    }

    .active {
      background: #ffffff;
      border-radius: 2px;
      height: 22px;
      width: 32px;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .inactive {
      height: 22px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .input-details {
    font-size: 12px;
    background-color: #ffffff !important;
    padding: 4px;
    border: 1px solid #e6e7e9;
    color: #000000;
    text-align: center;
    &::placeholder {
      font-size: 12px !important;
      color: #000000;
    }
  }
  // .input-error-border {
  //   border: 1px solid var(--error-red) !important;

  //   &:focus-within {
  //     border: 1px solid var(--error-red) !important;
  //   }
  // }

  .corner-radius-input-details {
    font-size: 12px;
    background-color: #eeeeee !important;
    padding: 4px;
    border: 1px solid #e6e7e9;
    color: #000000;
    text-align: center;
  }
  .corner-radius-input-details:disabled {
    opacity: 0.4;
  }

  .sample-color-border {
    border: 1px solid var(--border-color);
    padding: 3px;
    border-radius: 2px;

    .left-shade {
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    }

    .right-shade {
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
  }
}
.padding-top {
  padding-top: 10px;
}
.padding-top-customize {
  padding-top: 18px;
}
.color-picker {
  position: fixed;
  right: unset;
  left: 314px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background-color: var(--text-active-color);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
  padding: 0px 0px 15px;
}
.color-picker {
  max-width: 260px;

  .color-picker-input-boxes {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;

    .input-hex-code {
      width: 60%;

      .form-group {
        position: relative;

        &::after {
          content: "HEX";
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          color: var(--text-tertiary-color);
          font-size: 13px;
        }
      }
    }

    .input-percent {
      width: 30%;

      .form-group {
        position: relative;

        &::after {
          content: "%";
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          color: var(--text-primary-color);
          font-size: 13px;
        }

        input {
          // text-align: center;
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }

  .form-group {
    input {
      padding: 5px 10px;
      font-size: 13px;
      height: 35px;
    }

    input:not(:placeholder-shown) {
      background-color: var(--text-active-color);
      border: 1px solid var(--border-color);
    }

    input:focus-within {
      border: 1px solid var(--border-color) !important;
    }
  }

  .sketch-picker {
    .flexbox-fix {
      padding: 15px 0px 12px !important;

      > div:nth-child(1) {
        padding-right: 0px !important;
      }

      > div:nth-child(2) {
        display: none;
      }

      > div:nth-child(1) > div:nth-child(1) {
        border-radius: 100px;
      }

      > div:nth-child(1) > div:nth-child(2) {
        border-radius: 100px;
      }
    }
  }

  hr {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }

  .color-picker-heading {
    margin-bottom: 0px;
    font-size: 14px;
    text-align: left;
    padding: 15px 15px 0px !important;

    .head-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.sketch-picker {
  .flexbox-fix {
    padding: 15px 0px 12px !important;

    > div:nth-child(1) {
      padding-right: 0px !important;
    }

    > div:nth-child(2) {
      display: none;
    }

    > div:nth-child(1) > div:nth-child(1) {
      border-radius: 100px;
    }

    > div:nth-child(1) > div:nth-child(2) {
      border-radius: 100px;
    }
  }
}

.custom-themess-wrapper {
  .color-picker {
    .color-picker-input-boxes {
      .input-details {
        padding: 5px 15px !important;
        text-align: left !important;
        font-size: 13px !important;
      }
    }
  }
}

.custom-themes-wrapper {
  .color-picker {
    .color-picker-input-boxes {
      .input-percent {
        .input-details {
          padding: 5px 15px !important;
          text-align: left !important;
          font-size: 13px !important;
        }
      }
    }
  }
}

.active-theme-container {
  position: relative;
  width: 350px;
  padding: 3px 0px 24px 0px;
  left: -25px;
  .active-theme-div {
    padding-left: 0px;
    padding-right: 0px;

    .active-theme-border {
      background-color: var(--border-color);
      height: 1px;
      width: 100%;
      margin: auto;
      margin-top: 10px;

      span {
        color: var(--text-tertiary-color);
        font-size: 12px;
        border-radius: 6px;
        left: -107px;
        background-color: var(--text-active-color);
        padding: 0px 3px;
        position: relative;
        font-size: 12px;
        top: -13px;
        border-radius: 6px;
        display: inline-block;
      }
    }
  }
} 

.corner-radius-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .corner-radius-text {
    font-size: 13px;
    color: var(--text-secondary-color);
    margin-bottom: 0;
  }

  .pixels-text {
    color: var(--text-tertiary-color);
  }

  .form-group {
    margin-bottom: 0;
  }

  .corner-radius-input {
    width: 40px;
    text-align: center;
    font-size: 12px;
    border-radius: 2px;
    background-color: var(--background-light-color);
  }
}

.shapes-sidebar-container {

  .range-slider {
    margin-top: 12px;
  }

  .dropdown-container {
    .form-group {
      margin-bottom: 0;
    }
  }
}