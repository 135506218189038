.commenting-wrappper {
    padding: 0 0 15px;
    background-color: var(--text-active-color);
    width: 312px;
    min-width: 312px;
    max-width: 312px;
    height: 88vh;
    // overflow: auto;
    overflow: hidden;
    overflow-x: hidden;
    box-shadow: -2px 0px 5px 5px rgba(0, 0, 0, 0.02);
    z-index: 999;
    margin-left: auto;

    hr {
        border-top-width: 1px;
        border-top-color: var(--border-color);
        border-top-style: solid;
        opacity: 1 !important;
        color: unset;
        background-color: unset;
        margin: 16px 0 0;
    }

    .sidebar-header {
        background: var(--text-active-color);
        margin: 0px 0px 15px 0px;
        padding: 15px 0 15px 0;
        position: sticky;
        top: 0px;
        z-index: 999;
        border-bottom: 1px solid var(--border-color);

        h4 {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--text-primary-color);
            margin-bottom: 0;
            padding: 0 15px;
            .beta-tag{
                    margin-left: 3px;
                    position: fixed;
                    font-weight: 400;
                    border-radius: 6px;
                    font-size: 8px;
                    padding: 3px 5px;
                    background: #22946B;
                    color: #ffffff;
            }
        }

        .go-back {
            cursor: pointer;
            font-weight: 400;
        }
    }

    .commenting-tabs {
        margin: 0 15px;
    }

    .commenting-messages {
        background-color: var(--text-active-color);
        padding: 15px 0 15px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .comment-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        // gap: 10px;
        gap: 5px;

        &.unread-comment::before {
            content: '';
            width: 6px;
            height: 6px;
            background: var(--error-red);
            position: absolute;
            border-radius: 50%;
            top: 12px;
            left: -10px;
        }

        &.thread-actions {
            padding: 0 15px;

            .info-comment-block {
                font-size: 14px;
                color: var(--text-primary-color);
            }

            .actions-block {
                font-size: 14px;
                color: var(--text-primary-color);
            }
        }

        .info-comment-block {
            display: flex;
            justify-content: flex-start;
            // align-items: center;
            // gap: 10px;
            align-items: flex-start;
            gap: 10px;
            width: 190px;
        }

        .actions-block {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                display: block;
                padding: 0 5px;
                cursor: pointer;
            }


            .reaction-block {
                // width: 50px;
                // height: 10px;
                position: absolute;
                padding: 5px;
                top: 75%;
                right: 15px;
                z-index: 99 !important;
                justify-content: space-between;
                display: flex;
                align-items: center;
                gap: 5px;
                max-width: 200px;
                border-radius: 2px;

                div {
                    > p:first-child,
                    > div:nth-child(2) {
                      display: none;
                    }
                    > div {
                        padding-top: 0 !important;
                    }
                }

                img {
                    cursor: pointer;
                    padding: 5px;

                    &:hover {
                        background: var(--background-color);
                    }
                }
            }


            .btn-group {

                >button {
                    background: transparent;
                    border: 1px solid transparent;
                    color: var(--text-primary-color);
                    padding: 0;
                }
            }
        }

        .tag {
            display: block;
            border-radius: 50%;
            top: 0;
            color: var(--text-active-color);
            left: 0;
            width: 30px;
            height: 30px;
            font-size: 11px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 50%;
            }
        }

        .element-screen-block {
            line-height: 15px;
        }

        .person-name {
            font-size: 13px;
            line-height: 14.4px;
            margin-bottom: 0;
            font-weight: 400;
        }

        .element-name {
            font-size: 10px;
            line-height: 12px;
            color: var(--text-tertiary-color);
            padding-right: 5px;
        }

        .screen-name {
            font-size: 10px;
            line-height: 12px;
            color: var(--text-tertiary-color);
        }
    }

    .comment {
        text-align: left;
        padding: 16px 16px 0px;

        &:hover {
            background-color: var(--background-color) !important;
            .actions-block {
                display: flex;
                align-items: center;
                position: relative;
                bottom: 6px;
                visibility: visible;
            }
        }

        .actions-block {
            visibility: hidden;
        }

        &.deleted {
            opacity: 0.5;
        }
    }

    .element-comment {
        cursor: pointer;

        .element-comments-count {
            padding-top: 12px;
            font-size: 12px;
            padding-left: 40px;

            .comment-time {
                color: var(--text-tertiary-color);
                padding-left: 2px;
            }

            span {
                padding: 0 5px 0 0;
            }
        }

        .element-count-names {
            width: 30px;
            height: 27px;
            position: relative;

            .tag {
                border: 1px solid var(--text-active-color);
                position: absolute;

                &.two-first {
                    width: 25px;
                    height: 25px;
                    font-size: 9px;
                }

                &.two-second {
                    width: 20px;
                    height: 20px;
                    font-size: 8px;
                    top: 15px;
                    left: 10px;
                    background-color: #5851DB;
                    ;
                }

                &.three-third {
                    background-color: #E1306C;
                    width: 18px;
                    height: 18px;
                    font-size: 7px;
                    top: 18px;
                    left: 10px;
                }

                &.three-second {
                    width: 15px;
                    height: 15px;
                    font-size: 6px;
                    top: 0px;
                    left: 13px;
                    background-color: #5851DB;
                }

                &.three-first {
                    width: 20px;
                    height: 20px;
                    font-size: 8px;
                    top: 5px;
                }
            }
        }

    }

    // .comment-lists-wrapper,
    // .thread-wrapper {
    //     // padding-bottom: 120px;
    // }

    .thread-wrapper {
        padding-bottom: 15px;
    }

    .comment-lists-wrapper {
        padding-bottom: 0;
    }

    .comment-text {
        padding-top: 12px;
        font-size: 12px;
        line-height: 15px;

        .mentioned-name-comment {
            color: var(--brand-color);
        }
        .deleted{
            color: var(--text-tertiary-color) !important;
        }

        p {
            margin: 0;
            display: inline;
            word-wrap: break-word;
        }

        .comment-time {
            color: var(--text-tertiary-color);
            padding-left: 2px;
        }
    }

    .comment-image {
        margin: 10px 0 12px 0;
        width: 20%;
        position: relative;
        cursor: pointer;

        &:hover {
            .hover-block {
                display: block;
            }

        }

        .hover-block {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
            transition: opacity 0.5s ease;
            height: 100%;
            display: none;
            border-radius: 2px;

            span.view {
                position: absolute;
                top: 50%;
                left: 50%;
                -moz-transform: translateX(-50%) translateY(-50%);
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
                color: var(--text-active-color);
            }
        }

        &.uploaded-image {
            .hover-block {
                span.close {
                    position: absolute;
                    top: 0px;
                    right: -10px;
                    transform: translateX(-50%) translateY(-50%);
                    color: var(--text-primary-color);
                    cursor: pointer;
                }
            }
            &.comment-image {
                margin: 10px 0 12px 6px;
            }
        }
    }

    .reactions-count {
        display: flex;
        padding: 10px 0 0;
        gap: 5px;

        .emoji {
            border: 1px solid var(--border-color);
            background-color: var(--text-active-color);
            font-size: 12px;
            display: flex;
            padding: 2px 7px;
            border-radius: 10px;
            cursor: pointer;
            color: var(--text-primary-color);

            &.user-selected {
                color: var(--text-active-color);
                background-color: var(--brand-color);
                border: 1px solid var(--brand-color);
            }

            span {
                display: block;
            }

            img {
                width: 15px;
                margin: -3px 5px 0 -1px
            }
        }
    }


    .replies-count {
        padding: 10px 0 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .name-tag {
            display: block;
            border-radius: 50%;
            background: #F178B6;
            top: 0;
            color: var(--text-active-color);
            left: 0;
            width: 25px;
            height: 25px;
            font-size: 9px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: -2px;
            border: 1px solid var(--text-active-color);

            &.more-replies {
                background-color: #DEDDF5;
                color: var(--text-primary-color);
            }
            .reply-image {
                border-radius: 100%;
                height: 25px;
                width: 25px;
                max-width: 25px;
                border: 1px solid var(--text-active-color);
            }
        }

        .count {
            color: var(--text-primary-color);
            font-size: 12px;
            padding-left: 10px;
        }
    }


    .search-filter-block {
        position: relative;

        .search-block {
            padding: 0px 15px 16px 15px;
            background-color: #ffffff;

            .form-group {
                .search-bar {
                    height: 45px !important;
                    font-size: 14px !important;
                    background-color: var(--background-color);
                    border-radius: 6px;
                    background-image: url("../assests/images/search.svg") !important;
                    background-repeat: no-repeat !important;
                    background-position: 14px !important;
                    padding: 5px 46px !important;
                    background-size: 7%;
                    color: var(--text-primary-color);
                    border-color: transparent;

                    &::placeholder {
                        font-size: 14px !important;
                        color: var(--text-secondary-color);
                    }
                }

                .search-close {
                    width: 45px !important;
                    top: 4px !important;
                    right: 20px;

                    img {
                        width: 18px;
                    }
                }
            }
        }

        .filter-block {
            .icon-block {
                position: absolute;
                right: 30px;
                top: 10px;
                cursor: pointer;
            }

            .filter-values-block {

                position: absolute;
                background-color: var(--text-active-color);
                font-size: 12px;
                width: 220px;
                padding: 10px 15px;
                box-shadow: 8px 10px 32px 8px rgba(52, 43, 194, 0.06);
                border-radius: 2px;
                right: 15px;
                top: 50px;
                text-align: left;
                z-index: 9;
                height: 340px; //responsiveness needed
                max-height: calc(100vh - 346px); // quick fix for responsiveness. Look for better solution.
                overflow: auto;

                h5 {
                    font-size: 12px;
                    padding: 10px 0;
                    font-weight: 500;
                }

                .sections {
                    p {
                        width: 170px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        margin: 0;
                    }

                    hr {
                        margin: 0.5rem 0 !important;
                    }

                    .value-options {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        justify-content: flex-start;
                        padding-bottom: 0.7rem;
                        cursor: pointer;
                        .radio-button-commenting {
                            accent-color: var(--brand-color);
                            transform: scale(1.2);
                            cursor: pointer;
                        }
                    }
                }

            }
        }
    }

}

.image-comment-modal {
    padding: 0px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1.5rem;
        border-bottom: 1px solid var(--border-color);
    }

    .image-block {
        background-color: var(--background-color);
        padding: 30px;
        text-align: center;

        img {
            width: 60%;
        }
    }

    .comment-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        // gap: 10px;
        gap: 5px;

        .info-comment-block {
            display: flex;
            justify-content: flex-start;
            // align-items: center;
            // gap: 10px;
            align-items: flex-start;
            gap: 10px;
            width: 190px;
        }

        .tag {
            display: block;
            border-radius: 50%;
            background: var(--warning-orange);
            top: 0;
            color: var(--text-active-color);
            left: 0;
            width: 30px;
            height: 30px;
            font-size: 11px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .element-screen-block {
            line-height: 15px;
        }

        .person-name {
            font-size: 13px;
            line-height: 14.4px;
            margin-bottom: 0;
            font-weight: 400;
        }

        .element-name {
            font-size: 10px;
            line-height: 12px;
            color: var(--text-tertiary-color);
            padding-right: 5px;
        }

        .screen-name {
            font-size: 10px;
            line-height: 12px;
            color: var(--text-tertiary-color);
        }
    }
}


.comment-message {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 312px;
    padding-bottom: 56px;
    background-color: var(--text-active-color);
    border-top: 1px solid var(--border-color);
    padding-top: 16px;
    z-index: 999;

    .comment-error {
        position: absolute;
        top: -51px;
        left: 15%;
        &.image-type-fail {
            position: absolute;
            top: -51px;
            left: 10% !important;
        }
    }

    .mention-container {
        position: absolute;
        bottom: 180px;
        left: 5%;
        right: 0;
        background-color: var(--text-active-color);
        border-radius: 2px;
        border: 1px solid #E6E7E9 !important;
        box-shadow: 8px 10px 32px 8px #342BC20F;
        width: 281px;

        .mention-list {
            list-style-type: none;
            padding-left: 0;
            padding-inline-start: 0;
            padding: 5px;
            margin: 0;
            margin-block-end: 0;
            max-height: 180px;
            overflow: auto;

            .mention-item {
                text-align: left;
                padding: 5px;

                &:hover {
                    background-color: var(--brand-light-color);
                }

                .block-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    padding: 2px 0;

                    .email {
                        font-size: 10px;
                        color: var(--text-tertiary-color);
                        display: block;
                    }

                    .name {
                        font-size: 12px;
                        color: var(--text-primary-color);
                        display: block;
                    }
                }
            }
        }

        .tag {
            display: block;
            border-radius: 50%;
            background: #F77737;
            top: 0;
            color: var(--text-active-color);
            left: 0;
            width: 30px;
            height: 30px;
            font-size: 11px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 50%;
            }
        }

        .info-comment {
            color: var(--text-tertiary-color);
            font-size: 10px;
            border-top: 1px solid var(--border-color);

            p {
                margin-bottom: 0;
                padding: 1rem 10px;
                text-align: left;
            }
        }

        .no-mentions {
            padding: 20px;

            h4 {
                font-size: 11px;
                color: var(--error-red);
            }

            p {
                font-size: 12px;
                color: var(--text-secondary-color);
            }

            button {
                height: 32px !important;
                line-height: 10px;
                font-size: 12px;
                min-width: 20% !important;
                width: 35% !important;
            }
        }
    }

}

.message-box {
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    margin: 0 16px;
    font-size: 12px;
    padding: 12px;
    padding-bottom: 30px;
    text-align: left;
    color: var(--text-primary-color);
    position: relative;
    border-radius: 2px;

    .message {
        // height: 60px;
        margin-bottom: 10px;
        height: 45px;
    }

    .actions-block {
        display: flex;
        gap: 10px;
        color: var(--text-tertiary-color);
        font-size: 17px;
        justify-content: end;
        bottom: 7px !important;
        position: absolute !important;
        right: 10px;

        .action {
            cursor: pointer;
            display: flex;
            align-items: center;

            &.emoji-button-block {
                width: 20px;
            }

            label {
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }
    }
}




.comment-message {
    .react-emoji {
        position: static !important;
        z-index: 999;
    }

    .react-input-emoji--container {
        border: none;
        margin: 0;
    }

    .react-emoji-picker--wrapper {
        height: 285px;
        width: 275px !important;

        .react-emoji-picker {
            em-emoji-picker {
                width: 250px !important;
                height: auto !important;
            }

        }
    }

    .react-input-emoji--input {
        background-color: var(--background-color);
        color: var(--text-primary-color);
        border: 1px solid transparent;
        padding: 0px 5px 0px;
        max-height: 36px;
        min-height: 30px;
        cursor: auto;
        line-height: 16.08px;
        font{
            color: #000000 !important;
        }
    }

    .react-input-emoji--wrapper{
        max-height: 56px;
        min-height: 30px;
    }

    .react-input-emoji--button__show svg{
        fill: var(--brand-color)  !important;
    }
    .react-input-emoji--placeholder {
        left: 10px !important;
        line-height: 16.08px;
    }

    .react-input-emoji--button {
        position: absolute !important;
        bottom: 8px !important;
        right: 70px;
        z-index: 9999 !important;

        svg {
            width: 18px;
            margin: 0 5px 0 0;
            fill: #9CA0A9;
        }
    }

    .react-input-emoji--mention--container {
        display: none;
    }

    .mention-names {
        color: var(--brand-color);
    }
    .deleted{
        color:  var(--text-tertiary-color) !important;
    }
}

.comment-edit-message {
    .react-emoji {
        position: static !important;
        z-index: 999;
    }

    .react-input-emoji--container {
        border: none;
        margin: 0;
    }

    .react-emoji-picker--wrapper {
        height: 285px;
        width: 250px !important;

        .react-emoji-picker {
            em-emoji-picker {
                width: 240px !important;
                height: auto !important;
            }

        }
    }

    .react-input-emoji--input {
        background-color: var(--background-color);
        color: var(--text-primary-color);
        border: 1px solid transparent;
        padding: 0px 5px 18px;
        max-height: 36px;
        min-height: 20px;
        cursor: auto;
        line-height: 16.08px;
    }

    .react-input-emoji--placeholder {
        left: 10px !important;
        line-height: 16.08px;
    }

    .react-input-emoji--button {
        position: absolute !important;
        bottom: 5px;
        right: 79px;
        z-index: 9999 !important;

        svg {
            width: 20px;
            margin: 0 5px 0 0;
            fill: #9CA0A9;
        }
    }

    .react-input-emoji--mention--container {
        display: none;
    }

    .mention-names {
        color: var(--brand-color);
    }
    .deleted{
        color: var(--text-tertiary-color) !important
    }
    .actions-block {
        display: flex !important;
    }
}

.comment-edit-message {
    .react-input-emoji--button {
        z-index: 9 !important;
        svg {
            width: 17px !important;
            margin: 0 5px 0 0 !important;
            fill: #9CA0A9 !important;
        }
    }
}

.notification-box {
    position: absolute;
    width: 418px;
    height: 480px;
    background-color: var(--text-active-color);
    border: 1px solid var(--border-color);
    right: 5px;
    top: 45px;
    border-radius: 2px;
    box-shadow: 0px 5px 15px 25px #00000005;
    // overflow: auto;
}

#scrollableDiv{
    .no-notification-to-show{
        color: gray;
        font-size: 15px;
    }
}

#id-commenting-wrappper{
    .no-notification-to-show{
        color: gray;
        font-size: 15px;
        padding: 10px;
    }
}

.notification-main-container {
    .notification-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 0 20px;

        .notification-count-container {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .notification-more-dropdown {
            display: flex !important;
            flex-direction: column !important;

            .btn-secondary {
                background-color: #ffffff;
                border-color: #ffffff;
                margin: 0;
                padding: 0;
            }
        }

        .notification-count {
            background-color: var(--bs-danger);
            min-width: 25px;
            text-align: center;
            width: 24px;
            height: 16px;
            vertical-align: middle;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 8px;
            color: var(--text-active-color);
            border-radius: 8px;
        }

        .more-vertical-icon {
            height: 16px;
            width: auto;
            cursor: pointer;
        }

        h4 {
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            color: var(--text-primary-color);
        }

        p {
            margin-bottom: 0rem;
            font-size: 12px;
            font-weight: 400;
            color: var(--active-blue-color);
            cursor: pointer;
            background-color: transparent;
        }
    }

    .notifications-mapped-container {
        .invitees-details-container {
            display: flex;
            padding: 0 20px;
            gap: 8px;
            align-items: flex-start;
            position: relative;

            p {
                margin-bottom: 0;
                text-align: left;
            }

            .spherical-initials {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 30px !important;
                height: 30px !important;
                background-color: var(--active-blue-color);
                border-radius: 50%;
                color: var(--text-active-color);
                font-size: 10px;

                img {
                    border-radius: 50%;
                    width: 30px !important;
                    height: 30px !important;
                    max-width: 30px !important;
                }
            }

            .invitees-details {

                .invitees-details-line-1 {
                    // display: flex;
                    // align-items: baseline;
                    // gap: 4px;
                    line-height: 14px;

                    .group-name {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                .invitees-details-line-1 span:nth-child(1) {
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--text-primary-color);
                    padding-right: 3px;
                    line-height: normal;
                }

                .invitees-details-line-1 span:nth-child(2) {
                    font-size: 10px;
                    font-weight: 400;
                    color: var(--text-secondary-color);
                    line-height: normal;
                }

                .invitees-details-line-2 {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding-top: 2px;
                }

                .invitees-details-line-2 span:nth-child(1) {
                    font-size: 10px;
                    font-weight: 400;
                    color: var(--text-tertiary-color);
                }

                .invitees-details-line-2 span:nth-child(2) {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 2px;
                    height: 2px;
                    background-color: #D9D9D9;
                    border-radius: 50%;
                }

                .invitees-details-line-2 span:nth-child(3) {
                    font-size: 10px;
                    font-weight: 400;
                    color: var(--text-tertiary-color);
                }
            }
        }
    }

    .comment-card {
        background-color: var(--brand-light-color);
        border-left: 2px solid var(--active-blue-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px 12px 12px;
        margin: 12px 21px 15px 58px;

        .comment-card-title-with-photo {
            display: flex;
            align-items: center;
            gap: 10px;

            div {
                width: 39px;
                height: 28px;
                border: 1.5px solid var(--border-color);
                background-color: var(--text-active-color);
            }

            p {
                color: var(--text-primary-color) !important;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 10px;
            font-weight: 400;
            color: var(--text-tertiary-color);
        }
    }

    .reply-comment-card {
        background-color: var(--brand-light-color);
        border-left: 2px solid var(--active-blue-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 10px 12px 15px;
        margin: 12px 21px 15px 58px;

        p {
            margin-bottom: 0;
            line-height: 14.4px;

            span {
                color: var(--active-blue-color);
                cursor: pointer;
            }
        }
    }

    .reply-comment-card p:nth-child(1) {
        font-size: 12px;
        font-weight: 400;
        color: var(--text-secondary-color);
        text-align: left;
        padding-right: 50px !important;
    }

    .notification-button-container {
        display: flex;
        gap: 5px;
        align-items: center;
        margin: 15px 21px 20px 58px;
    }

    .notification-button-container {
        button.small-btn.btn.btn-primary {
            min-width: 71px !important;
            height: 30px !important;
            font-size: 10px !important;
        }
        button.small-btn.btn.btn-light {
            min-width: 71px !important;
            height: 30px !important;
            margin-left: 5px;
            font-size: 10px !important;
        }

        button.notification-btn {
            min-width: 71px !important;
            height: 30px !important;
            font-size: 10px !important;
            color: var(--text-secondary-color) !important;
        }
    }

    .reply-notification-button-container {
        display: flex;
        gap: 5px;
        align-items: center;
        margin: 15px 21px 20px 58px;
    }

    .reply-notification-button-container {
        button.notification-btn {
            min-width: 71px !important;
            height: 30px !important;
            font-size: 10px !important;
            color: var(--text-primary-color) !important;
        }
    }
}

.notifications-red-dot {
    width: 6px;
    height: 6px;
    background-color: var(--bs-danger);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 12px;
}

.nav-notifications-red-dot {
    width: 6px;
    height: 6px;
    background-color: var(--bs-danger);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.notification-tabs {
    .nav-tabs {
        border-bottom: 1px solid var(--border-color);
        padding: 0 27.5px;
        margin-bottom: 1rem;

        .nav-item {
            padding-bottom: 5px;

            .nav-link {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding-left: 0px;
                padding-right: 0px;
                margin-right: 1.2rem;
                color: var(--text-tertiary-color);
                border: none;
                cursor: pointer;
                font-size: 12px;
                transition: color 0.15s ease-in-out;
                background-color: 0.15s ease-in-out;
                border-color: 0.15s ease-in-out !important;
                padding-top: 0;
                position: relative;

                .notification-title-with-red-dot {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    justify-content: center;
                }

                .plans-icon {
                    width: 21px;
                }

                img {
                    opacity: 0.4;
                    padding-bottom: 4.8px;
                    padding-right: 5px;
                    width: 22px;
                }

                .bar {
                    display: none;
                }

                &.active {
                    .bar {
                        display: flex !important;
                        background: var(--active-blue-color);
                        position: absolute;
                        height: 4px;
                        top: 26px;
                        border: none;
                        border-radius: 10px;
                    }

                    font-weight: 400;
                    color: var(--text-primary-color);
                    border: none;
                    border-radius: 2px;

                    img {
                        opacity: 1;
                    }
                }

                &:not(.active):hover {
                    .bar {
                        display: flex !important;
                        background: #e9ecef;
                        position: absolute;
                        height: 4px;
                        top: 26px;
                        border: none;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

.empty-state-container {
    &.mentions-comment-state {
        padding: 70px 0;
    }

    &.invites-comment-state {
        padding: 70px 0;
    }

    &.notification-comment-state {
        padding: 70px 0;
    }
    .illustration-image {
        height: 110px;
        width: auto;
        margin-bottom: 20px;
    }
    .illustration-image-invite {
        height: 84px;
        width: auto;
        margin-bottom: 20px;
    }
}

// The Lazy loading SCSS is starting from here -

.lazy-notification-main-container {
    display: flex;
    flex-direction: column;
    gap: 35px;

    .shimmer-title--secondary .shimmer-title-line {
        height: 14px !important;
    }

    .border-rounded {
        border-radius: 6px !important;
    }

    .shimmer-thumbnail {
        margin-bottom: 0 !important;
    }

    .shimmer-avatar {
        margin-bottom: 0 !important;
        vertical-align: baseline;
    }

    .shimmer-title--secondary {
        margin-bottom: 0 !important;
    }

    .shimmer-button {
        margin-bottom: 0 !important;
    }

    .shimmer-button--sm {
        border-radius: 6px !important;
        height: 30px !important;
        width: 71px !important;
    }

    .lazy-photo-plus-title {
        display: flex;
        gap: 5px;
        padding: 0 20px 5px 20px;
        align-items: center;

        .lazy-title-container {

            .lazy-title-set-one {
                display: flex;
                gap: 5px;
                height: 22px;

                .lazy-title-one {
                    width: 89px;
                    height: 14px;
                }

                .lazy-title-two {
                    width: 187px;
                    height: 14px;
                }

                .second-lazy-title-one {
                    width: 65px;
                    height: 14px;
                }

                .second-lazy-title-two {
                    width: 100px;
                    height: 14px;
                }

                .second-lazy-title-five {
                    width: 105px;
                    height: 14px;
                }
            }

            .lazy-title-set-two {
                display: flex;
                gap: 5px;
                height: 22px;

                .lazy-title-three {
                    width: 63px;
                    height: 14px;
                }

                .lazy-title-four {
                    width: 143px;
                    height: 14px;
                }

                .second-lazy-title-three {
                    width: 51px;
                    height: 14px;
                }

                .second-lazy-title-four {
                    width: 42px;
                    height: 14px;
                }
            }
        }
    }

    .lazy-thumbnail {
        margin: 0px 21px 0px 58px;
    }

    .lazy-buttons {
        display: flex;
        margin: 8px 21px 0px 58px;
        gap: 5px;
        height: 31px;
    }
}

.lazy-commenting-sidebar {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .border-rounded {
        border-radius: 6px !important;
    }

    .commenting-lazy-thumbnail {
        margin: 0px 15px;
    }

    .commenting-lazy-footer-container {
        display: flex;
        gap: 50px;
        align-items: center;
        margin: 0px 0px 10px 0px;

        .commenting-lazy-multi-profiles {
            display: flex;
            margin: 0px 15px;
            width: 20px;

            .multi-profile-two {
                position: relative;
                right: 5px;
            }

            .multi-profile-three {
                position: relative;
                right: 10px;
            }

            .multi-profile-four {
                position: relative;
                right: 15px;
            }
        }

        .commenting-lazy-footer-title {
            width: 62px;
            height: 14px;
        }
    }

    .shimmer-thumbnail {
        margin-bottom: 0 !important;
    }

    .shimmer-avatar {
        margin-bottom: 0 !important;
        vertical-align: text-top;
    }

    .shimmer-title--secondary {
        margin-bottom: 0 !important;
    }

    .lazy-commenting-photo-plus-title {
        display: flex;
        gap: 10px;
        padding: 13px 15px 15px 15px;
        align-items: center;

        .lazy-commenting-title-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .commenting-lazy-title-one {
                width: 86px;
                height: 14px;
            }

            .commenting-lazy-title-two {
                width: 40px;
                height: 14px;
            }
        }
    }
}

.lazy-users-listing-box-main-container {
    .lazy-users-listing-box-container {
        display: flex;
        gap: 5px;
        align-items: center;
        padding: 15px;

        .shimmer-title--secondary .shimmer-title-line {
            height: 14px !important;
        }

        .shimmer-button {
            margin-bottom: 0 !important;
            height: 32px !important;
        }

        .shimmer-avatar {
            margin-bottom: 0 !important;
            vertical-align: middle;
        }

        .shimmer-title--secondary {
            margin-bottom: 0 !important;
        }

        .border-rounded {
            border-radius: 6px !important;
        }

        .lazy-users-listing-box-photo-plus-title {
            display: flex;
            gap: 15px;
            align-items: center;

            .lazy-users-listing-box-title-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .users-listing-box-lazy-title-one {
                    width: 65px;
                    height: 14px;
                }

                .users-listing-box-lazy-title-two {
                    width: 145px;
                    height: 14px;
                }
            }
        }

        .users-listing-box-lazy-buttons {
            margin-left: auto;
        }
    }
}

.comment-count {
    position: relative;

    span.count-red {
        position: absolute;
        color: var(--text-active-color);
        background-color: var(--error-red);
        border-radius: 50%;
        font-size: 10px;
        top: -4px;
        right: -6px;
        min-width: 16px;
        max-width: 16px;

    }
}

.empty-comment-state-container {
    margin-top: 50%;
    .empty-comment-state-description {
        color: #5E5E5E;
        font-family: "Rubik", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
        padding: 0 30px;
    }
    .comment-empty-state-illustration {
        width: 140px;
        height: auto;
        margin-bottom: 10px;
    }
}

.comment-error-container {
    display: flex;
    align-items: center;
    gap: 6px;

    p {
        margin-bottom: 0;
    }
}

.delete-page-not-found-main-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        padding: 0 155px;

        h3 {
            color: #000000;
            font-size: 28px;
            font-weight: 500;
            line-height: 34px;
        }

        p {
            color: #5E5E5E;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            margin-top: 24px;
            width: 600px;
        }

        .prime-btn {
            margin-top: 30px;

            &.page-not-found {
                min-width: auto !important;
                padding: 0 30px;
            }
        }
    }
}


.comment-edit-message {
    padding-top: 15px;
    position: relative;

    .comment-error {
        position: absolute;
        top: -51px;
        left: 15%;
    }

    .mention-container {
        position: absolute;
        top: 140px;
        left: 0;
        right: 0;
        background-color: var(--text-active-color);
        border-radius: 2px;
        border: 1px solid #E6E7E9 !important;
        box-shadow: 8px 10px 32px 8px #342BC20F;
        width: 281px;
        z-index: 999999999999 !important;

        .mention-list {
            list-style-type: none;
            padding-left: 0;
            padding-inline-start: 0;
            padding: 5px;
            margin: 0;
            margin-block-end: 0;
            max-height: 180px;
            overflow: auto;

            .mention-item {
                text-align: left;
                padding: 5px;

                &:hover {
                    background-color: var(--brand-light-color);
                }

                .block-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    padding: 2px 0;

                    .email {
                        font-size: 10px;
                        color: var(--text-tertiary-color);
                        display: block;
                    }

                    .name {
                        font-size: 12px;
                        color: var(--text-primary-color);
                        display: block;
                    }
                }
            }
        }

        .tag {
            display: block;
            border-radius: 50%;
            background: #F77737;
            top: 0;
            color: var(--text-active-color);
            left: 0;
            width: 30px;
            height: 30px;
            font-size: 11px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                border-radius: 50%;
            }
        }

        .info-comment {
            color: var(--text-tertiary-color);
            font-size: 10px;
            border-top: 1px solid var(--border-color);

            p {
                margin-bottom: 0;
                padding: 1rem 10px;
                text-align: left;
            }
        }

        .no-mentions {
            padding: 20px;

            h4 {
                font-size: 11px;
                color: var(--error-red);
            }

            p {
                font-size: 12px;
                color: var(--text-secondary-color);
            }

            button {
                height: 32px !important;
                line-height: 10px;
                font-size: 12px;
                min-width: 20% !important;
                width: 35% !important;
            }
        }
    }
}


.accepting-invite{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.delete-screen-page-not-found {
    height: 300px;
    width: auto;
}

.more-icon {
    padding: 0 !important;
}

.comment-edit-message {
    .message-box {
        .actions-block {
            display: flex !important;
            gap: 10px;
            color: var(--text-tertiary-color);
            font-size: 17px;
            justify-content: end;
            bottom: 7px !important;
            position: absolute !important;
            right: 10px;
            visibility: visible !important;

            .action {
                cursor: pointer;
                display: flex;
                align-items: center;

                &.emoji-button-block {
                    width: 20px;
                }

                label {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.comment-edit-message {
    .message-box {
        margin: 0 !important;
    }
}

.comment-image {
    margin: 10px 0 12px 0;
    width: 20%;
    position: relative;
    cursor: pointer;

    &:hover {
        .hover-block {
            display: block;
        }

    }

    .hover-block {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        transition: opacity 0.5s ease;
        height: 100%;
        display: none;
        border-radius: 2px;

        span.view {
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            color: var(--text-active-color);
        }
    }

    &.uploaded-image {
        .hover-block {
            span.close {
                position: absolute;
                top: 0px;
                right: -10px;
                transform: translateX(-50%) translateY(-50%);
                color: var(--text-primary-color);
                cursor: pointer;
            }
        }
        &.comment-image {
            margin: 10px 0 12px 6px;
        }
    }
}