.onboarding-wrapper {
    padding: 0;

    .onboarding-wrapper-innerchild {
        height: auto;
        position: relative;
    }
}


.dark-mode-btn {
    position: fixed;
    top: 22px;
    max-width: 60px;
    right: 23px;
    z-index: 9999;
    cursor: pointer;
}

.login-div {
    background: #f4f5f6;
}

.login-container-width {
    // max-width: 450px;
    background: #ffffff !important;
}

.form-wrapper {
    text-align: left;
    padding: 40px 40px 40px 40px;

    &.forgot-pass-form-wrap {
        margin-bottom: 10%;
    }

    .logo-header {
        width: 124px;
        height: 40px;
    }

    .css-tj5bde-Svg {
        fill: #323232;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000;
    }

    p {
        color: #5e5e5e;
    }

    .header-block {
        h3 {
            font-size: 24px;
            padding-bottom: 7px;
        }

        button {
            width: 100%;
        }

        p {
            padding-bottom: 10px;
            font-size: 15px;
        }
    }

    .form-block-wrapper {
        position: relative;

        .link {
            padding-bottom: 55px;
        }

        .form-group {
            margin-bottom: 20px;
        }

        .code-label {
            padding: 5px 0 2px;
            font-size: 14px;
            color: #000000;
        }

        .enterCode-pull-right {
            text-align: left;
        }

        .form-group-transition {
            opacity: 1;
            z-index: 111111;
            transition: opacity 0.3s ease-in-out;
        }

        .form-group-transition.delayed-visibility {
            opacity: 0;
            animation: fadeIn 0.3s ease-in-out forwards;
        }

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
                height: auto;
            }
        }


        .step-block {
            height: auto;

            .about-lit {
                position: relative;
                top: 1rem;
                font-size: 12px;
                color: var(--text-tertiary-color);
            }

            .progress-bar-style-wrapper {
                width: 100%;
                height: auto;

            }

            .progress-bar-style-main {
                flex-wrap: nowrap;
                width: 100%;
            }

            .progress-bar-style {
                width: 100%;
                height: 100%;
                position: relative;

                &.progress-bar-style-step2 img {
                position: relative;
                width: 12rem;
             }
            }

            .progress-bar-style img {
                max-width: 746px;
                position: relative;
                width: 11rem;
            }

            

            .progress-bar-style:nth-child(2) {
                right: 0.2rem;
            }

            .progress-bar-style:nth-child(3) {
                right: 0.5rem;
            }

        }
    }

    &.reset-password {
        min-height: 500px;
        max-width: 500px;
        background: #ffffff;
        padding: 60px 60px 90px 100px !important;

        z-index: 9999;


        &.verify-email {
            z-index: 99 !important;
        }

        .onboarding-steps {
            min-height: inherit;
        }

        &.password-flow {
            width: 100%;

            .blue-curve {
                width: 161px !important;
            }

            .polygon {
                position: absolute !important;
                margin: 25px 0 0 480px !important;
                z-index: -1 !important;
                width: 29px !important;
            }
        }
    }

    .input-wrap.text-center input {
        border: 1px solid #efeefb;
        width: 48px;
        height: 48px;
        text-align: center;
        outline: none !important;
        background: #ffffff;

        &:not(:placeholder-shown) {
            background-color: rgba(52, 43, 194, 0.05);
        }

        &::placeholder {
            color: #ffffff;
            opacity: 1;
        }

        &:focus {
            border: 1px solid #342bc2 !important;
        }
    }

    .otpInput {
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;

        ~p {
            text-align: left;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 35px;

            .resend-code {
                color: rgb(52, 43, 194);
                text-decoration: none;
                cursor: pointer;
            }

            ~button {
                min-width: 160px !important;
            }
        }
    }

    .text-center {
        padding-bottom: 20px;
    }

    .signup-header-block {
        padding-bottom: 15px;
    }

    .password-format {
        padding-bottom: 45px;

        label {
            font-size: 13px;
            color: #9ca0a9;
        }
    }

    .footer-text {
        font-size: 12px;
        color: #9ca0a9;
        text-align: center;
        padding: 25px 0 0;
        margin: 0;
    }
}

.btn-size {
    button {
        min-width: 25% !important;
        height: 45px !important;
    }
}

.highlight-text a {
    color: #342bc2;
    text-decoration: none;
    font-weight: 500;
}

.hello-wave {
    margin-top: -9px;
}

.or-option {
    text-align: center;
    padding: 16px 0 0;
    color: #9ca0a9 !important;
    letter-spacing: -2px;
    font-size: 0.9rem;

    span {
        letter-spacing: 1px;
    }
}

.forgot-password {
    text-align: right;
    color: var(--Brand-Main, #342BC2);
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    padding-top: 3px;

    a {
        color: #342bc2;
        text-decoration: none;
    }
}

.checkbox-forgot-pass {
    display: flex;
    align-items: center;
    padding-bottom: 35px !important;
}

    .checkbox {
        position: relative;
        margin-top: -19px;

        .input-style {
            border: 2px solid #323232 !important;
            &:focus {
                border: 2px solid #323232 !important;
            }
            &:focus-within {
                border: 2px solid #323232 !important;
            }
        }

        input {
            position: absolute;
            border-radius: 2px !important;
            background-repeat: no-repeat !important;
            height: 18px !important;
            width: 18px !important;
            padding: 7px !important;
            box-shadow: none !important;
            outline: 0 !important;
            border: 1px solid #d7d7d7;
            cursor: pointer;
            
            &:focus-within {
                box-shadow: none !important;
                outline: 0 !important;
            }
        }

        .form-check-input:checked {
            background-color: #342bc2;
            border-color: #342bc2;
            border: 1px solid #342bc2 !important;
        }

        input:not(:placeholder-shown) {
            background-color: #ffffff;
        }
    }

.logged-in {
    padding: 2px 0px 0px 26px !important;
    font-size: 14px !important;
    color: var(--text-secondary-color) !important;
}

.checkbox-info-icon {
    margin-left: 6px;
    cursor: pointer;
}

.logo-header {
    padding: 0 0 25px;
    color: #342bc2;
    width: 38%;
}

.reset-header-block {
    text-align: left;
    padding: 15px 0 20px;
    position: relative;

    p {
        padding: 0px 0 10px;
        color: #5e5e5e;
        font-size: 14px;
        max-width: 400px;
        display: block;
        margin: 0 auto;
    }

    &.renter-password-block {

        p {
            margin: 0;
        }
    }

    .emoji-party {
        padding: 5px;
    }

    h2 {
        font-size: 18px;
    }

    .rect-block {
        z-index: -1;
        position: absolute;
        width: 120px;
        height: 121px;
        left: -27%;
        top: -106%;
    }

    .circle-block {
        position: absolute;
        left: -30%;
        top: -120px;
        z-index: -1;
        width: 190px;
        height: 190px;
    }

    &.step4-header-block {
        text-align: center;
        margin-top: 3%;
    }
}

.btn-pull-right {
    
    text-align: center;
    padding: 30px 0;
    font-size: 14px; 
}


.multi-btn{
    position:absolute;
    min-width: 100%;
    font-size:14px;
    height: 48px;
    margin: 20px 0;
    text-align: center;
    border-radius: 2px;
    background-color: #342bc2;
    border: 1px solid #342bc2;
    margin-top: 10px;
    z-index:-111111; 
    &:hover {
      background-color: #272092;
      border: 1px solid #272092;
    }

    &.btn-primary:disabled {
        background-color: #342bc2 !important;
        border-color:#342bc2;
    }
}


.logo-reset-header {
    position: absolute;
    max-width: 100%;
    height: 40px;
    font-size: 32px;
    text-align: left;
    padding: 5px 0px 40px 26px;
    color: #342bc2;
    z-index: 999999;
    font-weight: 900;

    &.logo-verify {
        position: relative;
        max-width: 50%;
        height: 40px;
        font-size: 32px;
        text-align: left;
        padding: 0px 0px 40px 2px;
        color: #342bc2;
        z-index: 999999;
        font-weight: 900;
        bottom: 20px;
    }

    &.logo-renter-verify {
        max-width: 30% !important;
    }
}

.logo-reset-header-comp {
    text-align: left;
    width: 80rem;
    padding: 10px 0px 40px 26px;
}

.grey-block {
    position: absolute;
    width: 120px;
    height: 148px;
    margin: -69px 0px 0 -98px;
    z-index: -1;
}

.grey-pwd-block {
    position: absolute;
    width: 120px;
    height: 148px;
    margin: -99px 0px 0 -312px;
    z-index: -1;
}

.grey-otp-block {
    position: absolute;
    width: 120px;
    height: 148px;
    margin: -91px 0px 0 -93px;
    z-index: -1;
}

.polygon {
    position: absolute;
    margin: 6px 0 0 523px;
    z-index: -1;
    width: 35px;
}

.types-wrapper .types-block .row-padding .categories.active {
    border: 3px solid #342bc1;
    background: #342bc140;
}

.blue-curve {
    position: absolute;
    margin: 155px 0 0 649px;
    z-index: -1;
    width: 175px;
}

.login-btn-google {
    background-color: #F6F6F6 !important;
    display: inline-flex;
    align-items: center;
    color: var(--text-secondary-color) !important;
    padding: 0px;
    outline: none;
    border-radius: 2px;
    border: 1px solid #E6E7E9 !important;
    font-size: 14px;
    font-weight: 500;
    justify-content: center;
    outline: none !important;

    &:hover {
        background-color: #ededed !important;
        border: 1px solid #ededed !important;
        outline: none !important;
        outline: none;
        color: #000 !important;
    }
}

.image-slider {
    margin: auto;
}

.go2441762052 {
    display: none;
}

.copy-link {
    text-align: right;
    margin: -63px 0 0 0;

    button {
        min-width: 30% !important;
        font-size: 14px;
        height: 45px !important;
    }
}

.send-invites {
    text-align: right;
    width: 30% !important;
}

.submit-skip-btn-block {
    text-align: right;
    padding-top: 40px;
    padding-bottom: 40px;

    .send-invites {
        text-align: center;
    }

    .skip-btn {
        border: 1px solid transparent !important;
        background-color: #ffffff !important;
        color: #342bc2 !important;
    }
}

.btn-block {
    padding-top: 20px;

    button.small-btn {
        text-align: center;
    }

    .pull-right {
        float: right;
    }
}

.onboarding-slider {
    height: 100%;

    .slider-container {
        .slick-dots {
            display: flex !important;
            align-items: center;
            justify-content: center;
            bottom: -4%;

            li {
                margin: 0px;
                height: 16px;
                width: 16px;

                button {
                    height: 16px;
                    width: 16px;

                    &::before {
                        height: 16px;
                        width: 16px;
                        font-size: 9px;
                        color: rgba(124, 92, 250, 0.15);
                        opacity: 1;
                        outline-color: rgba(124, 92, 250, 1);
                        outline-width: 1px
                    }
                }
            }

            .slick-active {
                button {

                    &::before {
                        color: rgba(124, 92, 250, 1);
                        opacity: 1;
                    }
                }


            }

        }
    }

    h3 {
        color: var(--text-active-color);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        position: relative;

        span {
            background: linear-gradient(90deg, #8872F3 0%, #744EFD 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000000;
    }

    p {
        color: #5e5e5e;
    }


    .slideshowDots {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 8vh;
    }

    .slideshowDot {
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        cursor: pointer;
        margin: 15px 7px 0px;
        background-color: #c4c4c4;
    }

    .slideshowDot.active {
        height: 10px;
        width: 10px;
        background-color: #342bc2;

    }

    .text-div {
        padding: 40px 0 0;

        h3 {
            font-size: 22px;
            padding-bottom: 5px;
        }

        p {
            font-size: 15px;
            display: block;
            margin: 0 auto;
        }
    }
}



.container-image {
    background: #f4f5f6;
    min-height: calc(100vh - 0px);
    padding-left: 0;
    padding-right: 0;
}

.circle-block {
    position: absolute;
    z-index: -1;
    width: 190px;
    height: 190px;
}

.three-curves {
    position: absolute;
    margin: -62px 0 0 630px;
    z-index: -1;
    width: 175px;
}

.arrow-curve {
    z-index: -1;
    position: absolute;
    width: 157px;
    margin: -131px 0 0 675px;
}

.error-block {
    background-color: #fef5d9;
    border: 1px solid #fad151;
    color: #5e5e5e;
    padding: 8px 10px;
    font-size: 12px;
    margin-bottom: 20px;

    >i {
        display: inline-block;
        padding: 4px 7px 4px 5px;
    }

    >span {
        padding: 4px 8px 2px 1px;
    }

    &.incorrect {
        background-color: rgba(255, 19, 19, 0.08);
        border: 1px solid #ff1313;

        ::after {
            content: "";
            background-image: url("../assests/images/login-slider-bg.svg");
            opacity: 0.8;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1;
        }

        >i {
            color: #ff1313;
            font-size: 13px;
        }
    }
}

.rect-block {
    z-index: -1;
    position: absolute;
    width: 120px;
    height: 121px;
    left: -27%;
    top: -25%;
}

@media screen and (min-height : 1080px) {
    .form-wrapper {
        padding: 45px 38px 45px 55px;
    }
}

@media screen and (max-width: 1270px){
  

    .form-wrapper {

        .form-block-wrapper {

            .step-block {

                .progress-bar-style img {
                    max-width: 748px;
                    position: relative;
                    width: 11rem;
                }
            }
        }

    }
    
}
@media screen and (min-width: 1279px) {
    .form-wrapper {

        &.reset-password {
            padding: 60px 0px 60px 0px !important;
        }

        .form-block-wrapper {

            .step-block {

                .progress-bar-style img {
                    max-width: 746px;
                    position: relative;
                    width: 11rem;
                    
                }
            }
        }

    }


    .reset-header-block {

        .verify-reset-header-block {

            font-size: 0.9rem;
            max-width: 500px;
        }
    }

}


@media screen and (min-width: 1365px) {

    .form-wrapper {

        &.reset-password {
            padding: 0px 0px 0px 10px !important;
        }

        .form-block-wrapper {

            .step-block {

                .progress-bar-style img {
                    max-width: 746px;
                    position: relative;
                    width: 11rem;
                }
            }
        }

    }


    .reset-header-block {

        .verify-reset-header-block {
            font-size: 14px !important;
        }
    }

}


@media screen and (min-width: 1380px) {

    .form-wrapper {

        &.reset-password {
            min-height: 450px;
            min-width: 450px;
            background: #ffffff;
            padding: 0px 0px 0px 0px !important;

            z-index: 9999;

            &.reset-password-helper{    
            max-width: 400px !important;
        }
            &.verify-email {
                z-index: 99 !important;
            }
        }

      


        .forgot-password {
            font-size: 16px;
        }

        .password-check-wrapper {

            .title {
                font-size: 16px;
            }
        }


        .input-wrap.text-center input {
            width: 55px;
            height: 55px;
        }

        .otpInput {

            p {
                font-size: 14px !important;
            }
        }


    }


    .step-block {

        .progress-bar-style img {
            max-width: 746px;
            position: relative;
            width: 13rem !important;
        }

        .progress-bar-style-step2 img {
            max-width: 100%;
            width: 14rem !important;
        }
    }


    .reset-header-block {
        padding: 25px 0 25px;

        p {
            font-size: 16px;
            margin: 0;
        }

        .verify-reset-header-block {
            font-size: 16px !important;
        }

        h2 {
            font-size: 20px !important;
        }

        &.onboarding-reset-header-block {
            p {
                margin: 0;
                font-size: 16px;
            }

        }

        &.step4-header-block {

            p {
                font-size: 16px !important;
                margin: auto;
            }
        }
    }

    .multi-btn{
        font-size: 16px;
        height: 45px;
    }

    .logo-verify-email {
        padding: 3% 0% 5% 3%;

        img {
            width: 50% !important;
        }
    }

    .logo-reset-header {
        &.logo-verify {
            max-width: 35%;
        }
    }

    .btn-pull-right {
        font-size: 16px;
    }

    .label-input {
        font-size: 16px;
    }


}


@media screen and (max-width: 1379px) {

    .form-wrapper {

        &.multistep-reset-password {
            padding: 60px 60px 60px 60px !important;
        }


        &.update-password {
            padding: 50px 60px 50px 60px !important;
        }
    }
}

.plans-page {
    position: relative;
    max-height: 100vh;

    .polygon {
        margin: unset;
        z-index: 100;
        width: 30px;
        right: 25%;
        top: 6%;
        transform: rotate(135deg);
    }

    .blue-curve {
        margin: unset;
        z-index: 99;
        width: 150px;
        right: 0px;
        top: 25%;
    }

    .grey-blocks {
        z-index: 999;
        position: absolute;
        width: 150px;
        height: 121px;
        left: -50px;
        top: 40%;
    }

    .logo-reset-header {
        background-color: var(--text-active-color);
        width: 100%;
        padding: 10px 0px 10px 26px;
        z-index: 99;
    }

    .plans-modal {
        max-height: 100vh;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);

        //   z-index: 9999;
        .plans-body {
            padding: 70px 0px;

            .plans-wrapper {
                max-height: unset;
                padding: 0px;

                .tab-header {
                    text-align: left;

                    h4 {
                        font-size: 24px;
                        margin-bottom: 0.5rem;
                    }

                    p {
                        font-size: 14px;
                        color: var(--text-secondary-color);
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }
}

.new-login {
    .logo-header {
        padding: 0 0 1rem !important;
    }

    .highlight-text-container {
        margin-bottom: 1rem !important;
    }
    
    .login-signup {
        &.elements-tabs {
        background-color: #d9d9d9;
        background-color: var(--menu-slider-color);
        border-bottom: 1px solid var(--menu-slider-color) !important;
        margin-bottom: 20px;
        padding-top: 3px;
        padding-bottom: 3px;
        display: flex;
        border-radius: 4px;
        justify-content: space-evenly;
            
            .nav-tabs {
                border-bottom: 1px solid var(--menu-slider-color);
                background-color: var(--menu-slider-color);
                
                width: 100%;
            
                .nav-item {
                    padding: 1px 4px 0px;
                    border-radius: 2px;
                    width: 50%;
            
                    .nav-link {
                        color: #979797;
                        border: none;
                        cursor: pointer;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                        border-color 0.15s ease-in-out !important;
                        text-align: center;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        font-family: Rubik;
                        font-size: 13px;
                        font-weight: 400;
                
                        &.active {
                            font-weight: 400;
                            color: #000000;
                            border: none;
                            border-radius: 4px;
                            padding-left: 25px;
                            padding-right: 26px;
                            text-align: center;
                            box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
                        }
                
                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }
    }
    .linking-platforms-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;
        gap: 12px;

        .linking-platform {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            flex: 1;
            border-radius: 2px;
            border: 1px solid var(--Grey-Line, #E6E7E9);
            background: #FFF;
            cursor: pointer;
        }
    }
}

.login-slider-image {
    padding: 2% 18%;
}

.slider-bg {
    position: relative;

    &::before {
        content: "";
        background-image: url("../assests/images/login-slider-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.75;
    }

}

.text-size-14{
    font-size: 14px !important;
}